export var ERROR_CODE = {
  999: "Invalid token.",
  501: "Registration has been filed.",
  502: "Email id is already register.",
  503: "You are already registered with this mobile number.",
  511: "Login attempt failed.",
  512: "Either your Email or password seems to be incorrect.",
  513: "Either your Email or password seems to be incorrect.",
  521: "User profile upload has been failed.",
  522: "Device token updating failed.",
  523: "Logout attempt failed.",
  531: "Invalid referral code.",
  532: "Already apply referral code.",
  533: "Referral apply failed.",
  534: "User data not found.",
  535: "Order invoice failed.",
  536: "Add money in wallet failed.",
  537: "Wallet status change failed.",
  538: "Payment failed.",
  539: "Order history not found.",
  540: "Order detail not found.",
  651: "Order filed.",
  652: "Order not found.",
  656: "Cancel order failed.",
  659: "Order complete failed.",
  961: "Add in cart failed.",
  962: "Cart not found.",
  963: "Update cart failed.",
  964: "Clear cart failed.",
  1000: "You are not a registered user. Please register first.",
  1001: "Document data add failed.",
  1002: "Document not found.",
  1004: "Document upload failed.",
  1005: "Update failed.",
  1014: "Card data add failed.",
  1015: "Invalid payment token.",
  1016: "Card data not found.",
  1017: "Card delete failed.",
  1018: "Card selection failed.",
  1019: "Card deselect failed.",
  542: "OTP Verification failed.",
  547: "Referral code not valid for your country.",
  541: "Referral code not valid.",
  549: "Insufficient Balance in your card",
  548: "Invoice not found.",
  892: "No item found.",
  550: "Referral code is out of uses limit in your country.",
  634: "Store not found.",
  635: "Store business off.",
  505: "You was already registered with social.",
  506: "You are not register with social.",
  813: "Business or service not available in this city.",
  546: "Invalid or expired promo code.",
  801: "Country detail not found.",
  811: "City detail not found.",
  804: "Business not in your country.",
  965: "Your cart store not available in this region.",
  843: "Business or service not available in this city.",
  551: "Promo code already used.",
  552: "Promo code not for city.",
  553: "Your delivery charge free you can not apply promo.",
  554: "Add favourite store failed.",
  555: "Delete favourite store failed.",
  556: "Favourite store list not found.",
  557: "Your order price less then store minimum order price.",
  807: "Store review list not found.",
  808: "Store review data not found.",
  809: "Store review like failed.",
  558: "Delivery service not available in your city.",
  643: "Mis match store id.",
  563: "Business Not in Selected Area",
};
