export var button = {
    confirm: 'Confirmer',
    signup: "Inscription",
    signin: "Se connecter",
    ok: "OK",
    as_soon_as_possible: "Dès que possible",
    login_b: "Connexion",
    japenese: "Japonais",
    sushi: "Sushi",
    valider: "Valider",
    order: "Commander",
    order_now: 'Commander',
    apply: "Appliquer",
    add_to_cart: "Ajouter au panier",
    other: "Autre",
    payment: "Paiement",
    track_your_order: "Suivre ma commande",
    login: 'S\'identifier',
    reorder: 'recommander cette commande',
    modify: 'modifier',
    remove: 'supprimer',
    privacy_policy: 'Politique de confidentialité',
    terms_and_condition: 'Conditions générales d\'utilisation',
    agree: 'J\'accepte les',
    add_an_adress: "Ajouter une adresse",
    add_a_card: "Ajouter une carte de crédit",
    reset : "Réinitialiser",
    save_b: "Sauvegarder",
    submit: "Envoyer",
    search_b: "Chercher",
    ajouter_des_produits:'Ajouter des produits',
    
}

export var status = {

    order_accepted: "Order accepted",
    order_prepared: "Order prepared",
    order_on_way: "Order on the way",
    order_doorstep: "Order on doorstep",
    cancelled_by_admin: 'Cancelled By Admin',

    created:"Created",
    waiting_for_accept:"Waiting for Accept",
    accepted:"Accepted",
    start_preparing_order:"Start Preparing order",
    order_being_prepared: 'Your order is being prepared',
    order_ready:"Order Ready",
    waiting_for_delivery_man:"Waiting for Delivery Man",
    delivery_man_accepted:"Delivery Man Accepted",
    delivery_man_coming:"Delivery Man Coming",
    delivery_man_arrived:"Delivery Man Arrived",
    picked_order:"Delivery Man Picked Order ",
    started_for_delivery:"Started For Delivery",
    arrived_at_destination:"Delivery Man Arrived at Destination",
    delivered_ordered:"Delivered Order",
    assign_order_to_Delivery_man:"Order Assign To Delivery Man",
    rejected:"Rejected",
    cancelled:"Cancelled",
    store_cancelled: "Cancel By store",
    no_delivery_man_found:"No Delivery Man Found",
    delivery_man_rejected:"Delivery Man Rejected",
    delivery_man_cancelled:"Cancle By Delivery Man",
    user_cancelled:"Cancle By User",
    oredr_complete:"Order Complete",
    order_delivered:"Order Delivered",
    order_completed: 'Order Completed',
    completed:"Completed",
    not_completed:"Not Completed",
    cancelled_request:"Request Cancelled"
   
}

export var language = {

}

export var placeholder = {
    first_name: 'Prénom',
    last_name: 'Nom',
    email_address: "Adresse e-mail",
    enter_an_address: "Entrez votre adresse",
    search: "Rechercher",
    name: "Nom",
    surame: "Prénom",
    email: "E-mail",
    password: "Mot de passe",
    select_country: "Séléctionnez votre pays",
    number: "Téléphone",
    email_phone: "E-mail ou numéro de téléphone",
    write_something: "Commentaire",
    company_name: "Nom de la société",
    postal_code: "Code postal",
    surname: "Nom",
    name_it: "Donne un nom à cette adresse",
    delivery_address: "Adresse de livraison",
    floor: "Etage",
    entry_code: "Code d'entrée",
    comment_for_the_hopper: "Commentaire pour le HOPPER",
    promo_code: "Code promo",
    geneva_switzerland: "Ville",
    alexandre: "Alexandre",
    vincent: "Vincent",
    moret: "Moret",
    vincent_email: "vincent@hop.delivery",
    phone: "+ 41 22 940 22 88",
    gra_address: "36 Rue du Rhône, 1204 Genève",
    la_address: "36 Rue du Rhône, 1204 Genève" ,
    from: "De",
    to: "Pour"


}

export var menu_title = {
    order_f : "Commander",
    partner: "Devenir PARTENAIRE",
    hopper: "Devenir HOPPER",
    team : "HOP Team",
    help : "Aide",
    terms_conditions : "Conditions générales d\'utilisation",
    partners: "Partenaire",
    hoppers: "HOPPER",
    hop: "let's hop(e) together",
    order: "commander !",
    profile: "Profil",
    orders: "Commandes",
    favorites: "Favoris",
    logout: "Déconnexion",
    salads_soup: "Salades et soupes",
    nigiri: "Nigiri",
    futomaki: "Futomaki",
    sashimi: "Sashimi",
    gunkan: "Gunkan",
    chirashi: "Chirashi",
    uramaki: "Uramaki",
    assortments: "assortiments",
    restaurants: "Restaurants",
    pharmacies: "Pharmacies",
    florists: "Fleuristes",
    grocery_stores: "Epiceries",
    breakfast: "Petit déjeuner",
    addresses: 'Adresses',
    payments: 'Paiements'

}

export var title = {
    busy_text: "Vous êtes nombreux ! Il est possible qu'un léger délai survienne dans votre livraison, nous nous en excusons :)",
    close_text: 'choisissez PRÉCOMMANDER pour passer commande',
    select_delivery_type: 'Sélectionnez le type de livraison',
    go_to_basket: 'Voir le panier',
    store_time: 'Horaires',
    sunday: 'Dimanche',
    monday: 'Lundi',
    tuesday: 'Mardi',
    wednesday: 'Mercredi',
    thursday: 'Jeudi',
    firday: 'Vendredi',
    saturday: 'Samedi',
    closed: 'PRÉCOMMANDER',
    closed_popup: 'FERMÉ',
    time_for_preparation: 'Temps de préparation',
    cancel_order: "Annuler la commande",
    missing_item: 'Il manque un produit', 
    delivery_address_incorrect: 'L\'adresse de livraison n\'est pas correct',
    cancel_completely: 'Autre',
    cancel_order_confirmation: 'Es-tu sûr de vouloir annuler ta commande ?',
    
    please_add_card_first: 'Vous devez enregistrer une carte de crédit',
    new_password: "Nouveau mot de passe",
    your_card_will_be_charged: 'Ta carte de crédit sera débitée',
    no_registered_credit_card: 'Tu n\'as pas encore enregistré de carte',
    delivery_time: 'Heure de livraison',
    confirm_location: 'Confirme l\'adresse',
    how_would_like_pay: 'Comment souhaites-tu payer?',
    use_my_hop_credit: 'Utiliser mes crédits HOP',
    hop_credit: 'Crédits HOP',
    other:"Autre",
    add_payment: 'Ajouter un moyen de paiement',
    you_are_paying: 'Tu paies',
    amount_from_wallet_balance: 'Crédits HOP disponible',
    partner_message: "Merci, nous reviendrons vers toi très prochainement",
    hopper_message: "Merci, nous reviendrons vers toi très prochainement",
    other_store_item_in_cart: 'Oups, pour l\'instant une commande par partenaire est possible, souhaites-tu vider le panier pour continuer ?',
    attention: 'Attention',
    busy: 'très occupé',
    no_delivery_found_this_area: 'Oh, HOP livrera bientôt à cette adresse :)',
    schedule_time_error: 'Oups, le partenaire n’est pas ouvert à l’heure et à la date sélectionnée, veuillez changer d’horaire',
    // schedule_time_error: 'Oups, le partenaire n\'est pas disponible à ce moment',
    store_not_provide_schedule_order: 'Oups, le partenaire n\'accepte pas les précommandes',
    please_accept_terms_cond_signup: 'Accepte les conditions générales pour continuer',
    alert: 'Alerte',
    invalid_amount: 'Le partenaire sélectionné requiert un montant minimum :',
    no_article_selected: "Aucun produit sélectionné",
    check_your_email: 'Un email a été envoyé à ton adresse',
    forgot_password_success_message: 'Un mail contenant un nouveau mot de passe t\'a été envoyé. Vérifie tes spams !',
    basket_empty: 'Ton panier est vide!',
    go_to_shop: 'Choisis un de nos partenaires et commande ce que tu veux :)',
    successfully_subscribe: 'Merci pour ton inscrption',
    clear_all: 'Réinitialiser',
    filter_applied: 'Filtre appliqué',
    newsletter: "S\'inscrire à la newsletter",
    as_soon_as_possible: "Dès que possible",
    schedule_a_delivery: "Précommander",
    sign_up: "S'inscrire",
    acount : "Déjà un compte ?",
    login: "Se connecter",
    sushi_shop : "Sushi shop",
    soupe_miso: "Soupe miso",
    customize_your_soupe: "Personnalise ta commande",
    add_notes: "Ajouter un commentaire",
    quantity: "Quantité",
    avantages: "Avantages",
    hop_a_votre_service: "HOP à votre service",
    partner_title: "Deviens <br />partenaire HOP",
    Join_the_communauty: "Rejoins <br />la communauté",
    bienvenue_alexandre: "Bienvenue",
    future_partner: "Futur PARTENAIRE ?",
    future_hopper: "Futur HOPPER ?",
    team: "LA TEAM",
    your_basket: "Ton panier",
    date: "Date",
    delivery_details: "Détails de livraison",
    use_an_address: "Utiliser une adresse enregistrée",
    use_your_position : "Utiliser une position",
    order_details: "Détails de la commande",
    subtotal: "Sous-total",
    delivery_fee: "Frais du Hopper",
    total: "Montant total",
    help_t: "HOP ?",
    required: "Requis",
    add_an_ingredient: "Ajouter un ingrédient",
    how_do_you_feel_today: "Comment te sens-tu aujourd\'hui?",
    hop_got_your_back: "Hop Delivery, à votre service",
    search_label: "Où souhaiteriez-vous être livrés ? ",
    payment: "Paiement",
    credit_card: "Carte de crédit",
    schedule_an_order: "Précommander",
    thank_you: "Merci",
    order_accepted: "Commande acceptée",
    hopper_selected: "HOPPER sélectionné",
    order_prepared: "Commande prête",
    hopper_on_the_way: "Commande en route",
    order_received: "Commande reçue",
    denise_burger: "Denise\’s burger",
    vincent_moret: "VINCENT MORET",
    profile_t: "Profil",
    addresses: "Addresses",
    payments: "Paiements",
    credit_cards: "Carte de crédit sélectionnée",
    wallet: "Crédit HOP",
    current_balance: "Crédit HOP disponible",
    currents_orders: "Commandes en cours",
    burgers_co: "BURGER\’S CO",
    quick_restaurant: "QUICK RESTAURANT",
    lentrecote: "L\’ENTRECÔTE",
    wok_25: "WOK 25",
    denises_burger: "Denise\’s burger",
    chicken_dominator: "Chicken Dominator",
    choose_your_crust: "Choisis ta pâte",
    veg_toppings: "Veg Toppings",
    order_history: "Historique",
    yesterday: "Hier",
    macdonalds: "MCDONALD\’S",
    chezz_witt: "CHEZ WITT",
    favourites: "Favoris",
    coucou: "COUCOU",

    service_tax: 'TVA',
    service_price: 'Frais de service',
    promo_bonus: 'Code promo',
    promocode_validated: 'code promo validé',
    total_service_cost: 'Frais totaux du service',
    item_price: 'Prix des produits',
    item_tax: 'TVA des produits',
    total_item_cost: 'Prix total des produits',
    promocode : 'Code promo',
    manual: 'Manuel',
    cash: 'Cash',
    card: 'Carte de crédit',
    fr: "FR",
    en: "EN",
    show_filter: 'Montrer les filtres',
    hide_filter: 'Cacher les filtres',

    this_field_is_required: 'Ce champ est obligatoire',
    please_enter_valid_email: 'Oups, l\'adresse e-mail ne semble pas valable ',
    password_doesnt_seem_right: 'Le mot de passe doit contenir plus de 6 caractères.',
    please_enter_valid_email_phone: 'oups, l\'adresse e-mail ou le téléphone ne semblent pas valable',
    forgot_password: 'Mot de passe oublié',
    invalid_date_time: 'Date et/ou heure invalide',

    address_name: 'Nom de l\'adresse',
    address: 'Adresse',
    comment: 'Commentaire',
    entry_code: 'Code d\'entrée',
    floor: 'Etage',
    amount: 'Prix',
    card_holder_name: 'Nom du détenteur de la carte',
    card_number: 'Numéro de la carte',
    expiry_month: 'Mois d\'exp.',
    expiry_year: 'Année d\'exp.',
    cvv: 'CVV',
    add_Card: 'Ajouter une carte',
    min: 'min.',
    order_no: 'Commande ',
    reopen_at: 'Réouvre ',
    order_at: 'Commande à partir de',
    store_closed_today: 'Partenaire fermé',

    sub_total: 'Sous-total',
    add_a_credit_card: 'Ajouter une carte de crédit',
    promo_error: 'Le code promo n\'est pas valable', 
    promo_min_amount_error: "Le code promo est valable pour les commandes d'au moins",

    profile_menu: "PROFIL",
    addresses_menu: "ADRESSES",
    payment_menu: "PAIEMENTS",
    orders_menu: "COMMANDES",
    favourite_menu: "FAVORIS",
    and: 'ainsi que la',
    please_select_arrdess: 'Please Select Address'

}

export var label = {
    date: "Date",
    hour: "Heure",
    pickle_slices: "Pickle slices",
    grandma: "grand maman",
    leane: "LÉANE",
    command_carried_out_on_the: "Commande effectuée le :",
    planned_order_on_the: "Commande planifiée pour le : ",
    delivery_performed_on_the: "Livraison effectuée le : ",
    populaire:'populaire',
    offer_speci:'⭐️️ Offres spéciales : ',
    offer_speciale:'⭐️ Offre spéciale',
}

export var price = {
}

export var option = {
    japenese: "japonais"
}

export var service = {
    performance: "performance",
    dynamisme: "dynamisme",
    flexibilite: "flexibilité"
}

export var description = {
    service_dis : "Nous proposons la plateforme la plus performante à nos partenaires pour qu\'ils puissent se concentrer sur leurs activités.",
    contact_us_dis : "<span>Un projet de livraison ?</span>Partagez votre idée <br />et réalisons-la ensemble",
    shop_dis: "Temps de préparation - 30 mins jusqu\'à 8:30 PM",
    pop_p: "Lorem ipsum dolor sit amet, consectetur <br />dipisicing elit, sed do eiusmod tempor",
    welcome_dis: "Effectue ta première commande maintenant",
    wel_hop_des: "La HOPTeam te recontactera très prochainement <br />au sujet de ta demande",
    soc_des: "En attendant, suis-nous sur les réseaux sociaux",
    wel_part_des: "<span>Un responsable Partenaire </span> te recontactera très prochainement. <br / >Au plaisir de vous rencontrer!",
    copyright: "COPYRIGHT © ",
    faq_des: "Hop Delivery est",
    faq_span: "ton nouveau service de livraison ",
    faq_span2: "",
    faq_des2: "Fondé par des jeunes de Genève, notre équipe travaille constamment afin d'améliorer notre service et te remercie de ton soutien.",
    faq_des3: "N'hésite pas à nous contacter en cas de question",
    faq_des4: "ou directement",
    au: "au",
    thakyou_p: "Ta commande a été envoyée et sera bientôt livrée :)",
    payment_des: "Depuis ta carte de crédit, tu peux directement <br />acheter des crédits HOP et les utiliser comme <br />moyen de paiement ",
    bc: "bc",
    qr: "qr",
    en: "en",
    wo: "wo",
    wheat_thin_crust: "Wheat thin crust",
    veg_toppings: "Tomate fraiche<br /> Golden Corn <br />Onion",
    del_des: "Rue des Charmilles 1, 1203 Genève <br />Etage : 2<span>Code d'entrée: 1234</span>Commentaire pour le HOPPER : <br />porte à gauche",
    payment_des2: "Les cartes VISA et MASTERCARD sont acceptées",

    future_partner: "FUTUR PARTENAIRE HOP ?",
    future_partner_des1: "NOTRE RESPONSABLE PARTENAIRE",
    future_partner_des2: "PRENDRA CONTACT AVEC VOUS TRÈS PROCHAINEMENT.",
    future_partner_des3: "NOUS NOUS RÉJOUISSONS DE VOUS RENCONTRER !",
    menu_offer_text: "⭐️  Des offres spéciales sont disponibles chez ce partenaire ⭐️",
    offer_speci_text1: 'Frais de livraisons offerts dès 80CHF',
    offer_speci_text2: '-10 % dès 100 CHF',
}

export var links = {
    hop: "Hop delivery",
    promo_code: "Code promo",
    view_details_of_your_order: "Voir les détails de ta commande",
    call_the_hopper: "Appeler le HOPPER",
    edit: "Modifier",
    add: "+ Ajouter",
    order_accepted: "Commande acceptée",
    cancel_order: "Commande annulée",
    hopper_selected: "HOPPER sélectionné",
    hopper_on_the_way: "HOPPER en route",
    order_received: "Commande reçue",
    canceled: "Annulé",
    save: "Sauvegarder",
    delete_address: "Supprimer l\'adresse",
    delete_a_card: "Supprimer une carte de crédit",
    delete_section: "Supprimer la sélection"

}

export var slider_conList = [{
    "slide_img":"../assets/images/slide-1.png",
    "app_icon":"../assets/images/app_icon.png",
    "slide_title":"hop delivery",
    "slide_des": "Hop Delivery te livre <br /><span>ce que tu veux, quand tu veux</span> et <br /><span>où que tu sois à Genève!</span>",
    "dwn_app":"Téléchargez l\'app!",
    "app_btn":"../assets/images/apple_btn.png",
    "play_btn":"../assets/images/play_btn.png"
}
]

export var tabList = [{
    title: "Faim !",
    tabimg: "../assets/images/hungry.png",
    buttons: [{"button": "Burger"},{"button": "Pizza"},{"button": "Pâtes"},{"button": "Kebab"},{"button": "Sandwich"},{"button": "japonais"},{"button": "Libanais"},{"button": "Italien"},{"button": "Indien"},{"button": "Viande"},{"button": "Poisson"},{"button": "Salade"},{"button": "Dessert"}]
},
    {
        title: "Seek !",
        tabimg: "../assets/images/seek.png",
        buttons: [{"button": "Allergie"},{"button": "Douleurs et fièvre"},{"button": "Refroidissement"},{"button": "Brûlure d\'estomac"},{"button": "Contraception"},{"button": "Pansements & brûlures"},{"button": "Vitamines"},{"button": "Relaxation & sommeil"},{"button": "Bas âge"}]
    },
    {
        title: "la vie en rose !",
        tabimg: "../assets/images/rose.png",
        buttons: [{"button": "roses rouges"},{"button": "roses jaunes"},{"button": "roses blanches"},{"button": "roses roses"},{"button": "bouquets"},{"button": "arrangements floraux"},{"button": "plantes"}]
    },
    {
        title: "Mon frigo est vide !",
        tabimg: "../assets/images/foodimg1.png",
        buttons: [{"button": "Bières & cidres"},{"button": "Boissons"},{"button": "Alcool"},{"button": "Tabac"},{"button": "Douceurs"},{"button": "Pâte & riz"},{"button": "Sauces"},{"button": "Produits laitiers"},{"button": "Aliments pour animaux"},{"button": "Glaces"},{"button": "Nettoyage"},{"button": "Snacks"},{"button": "Petit-déjeuner"}]
    }

]

export var hopperSlideList = [{
    "hopper_title": "Deviens HOPPER" ,
    "hopper_slide_img": "../assets/images/hopper.png",
    "hopper_dis": "Rejoins la communauté",
    "dbtn": "Deviens un HOPPER"
}]

export var list_info = [{
    "name": "Job facile",
    "name_dis": "Travaille quand tu le souhaites"
},
    {
        "name": "communauté",
        "name_dis": "Intègre une communauté dynamique <br />et partage tes passions"
    },
    {
        "name": "avantages",
        "name_dis": "Des offres et événements<br />rien que pour toi"

    }]

export var slide_conList = [{
    "bag_img": "../assets/images/partner.png",
    "title": "Partenaire HOP",
    "slide_dis": "Livrez partout à Genève",
    "dwnl_btn": "Devenez un Partenaire HOP"
}]

export var partnerList = [{"data": "Augmentez votre clientèle"},{"data": "Gagnez en visibilité"},{"data": "Vendez différement"},{"data": "Donnez entière satisfaction "},{"data": "Plongez dans l\'air numérique"}]

export var teamList = [{
    "mem_img": "../assets/images/team_img1.jpg",
    "mem_name": "ALEXANDRE-MARTIN SIMON",
    "post": "Fondateur CEO"
},
    {
        "mem_img": "../assets/images/team_img2.jpg",
        "mem_name": "VINCENT MORET",
        "post": "Co-fondateur COO"
    },
    {
        "mem_img": "../assets/images/deep.png",
        "mem_name": "DEEP MOTERIA",
        "post": "Partner & CTO"
    },
    {
        "mem_img": "../assets/images/till.jpg",
        "mem_name": "Till Bohbot",
        "post": "VP of Special Projects"
    }]

export var ordList = [{
    "ord_title": "SUSHI",
    "ord_des": "Soupe miso - supplement onion, spicy, french fries, moustard & 1 drink",
    "ord_price": "CHF 25.-",
    "remove": "Remove",
    "modify": "Modify"

},
    {
        "ord_title": "SUSHI",
        "ord_des": "Soupe miso - supplement onion<br /> Comment : lactose allergy",
        "ord_price": "CHF 25.-",
        "remove": "Remove",
        "modify": "Modify"
    }
]

export var faqList = [{
    "que": "Comment puis-je installer l\'app ?",
    "ans": "Télécharge l\'application directement depuis ton smartphone sur le Play Store (Android) et l\'App Store (iOS)."
},
    {
        "que": "Combien coûte le service ?",
        "ans": "Tu ne paies que les produits choisis ainsi que les frais de livraison permettant de rémunérer ton HOPPER."
    },
    {
        "que": "Comment s\'effectue le paiement ?",
        "ans": "Le paiement s\'effectue de manière sécurisée par carte de crédit (MasterCard ou Visa). "
    }]

export var faqList2 = [{
    "que": "Que puis-je commander?",
    "ans": "Tu peux commander tout l'assortiment des restaurants, des supermarchés, des pharmacies et des fleuristes qui ont rejoint l'aventure hop."
},
    {
        "que": "Où puis-je être livré ?",
        "ans": "Hop est actuellement disponible n\'importe où à Genève ! Que tu sois au bord du lac, à la maison ou au bureau, tu seras toujours livré !"
    },
    {
        "que": "Comment sont calculés les frais de livraison ?",
        "ans": "Les frais de livraison sont calculés en fonction de la distance entre le point de retrait et le point de livraison."
    }]

export var orderTable = [{
    "leftCon": "Sous-total",
    "o_price": "CHF 50.-"
},
    {
        "leftCon": "frais du HOPPER",
        "o_price": "CHF 5.-"
    },
    {
        "leftCon": "frais de service",
        "o_price": "CHF 2.75.-"
    }]

