export var MESSAGE_CODE = {

    101: "You are successfully registered.",
    102: "You are successfully logged in.",
    103: "Your profile has been updated successfully.",
    104: "Email send successfully.",
    105: "OTP send successfully.",
    106: "Update device token successfully.",
    107: "You are successfully logged out.",
    114: "Wallet amount added successfully",
    115: "Wallet status change successfully.",
    116: "Order payment successfully.",
    117: "History get successfully.",
    120: "Get order detail successfully.",
    191: "Now you Approved.",
    192: "Now you declined",
    193: "Feedback submit successfully.",
    241: "Create order successfully.",
    246: "Order cancel successfully.",
    253: "Order completed successfully.",
    913: "Document upload successfully.",
    914: "Document upload successfully.",
    951: "Update item in cart successfully.",
    952: "Add item in cart successfully.",
    953: "Get cart successfully.",
    954: "Clear cart successfully.",
    113: "Order invoice generate successfully.",
    196: "OTP Verification successfully.",
    197: "Promo code apply successfully.",
    955: "Delivery address changed successfully.",
    924: "Card Add Successfully",
    926: "Card Delete Successfully"

}
