import { Component, Inject, ElementRef } from '@angular/core';
import {Helper} from "./user_helper";
declare var $;
import {Title} from "@angular/platform-browser";
import { Router, ActivatedRoute} from '@angular/router';
import { DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Helper]
})
export class AppComponent {



	constructor(@Inject(DOCUMENT) private _documentRef: any, private elementRef:ElementRef, public user_helper: Helper, private titleService:Title,public router: Router) {
		
	}
  
  onActivate(event) {
    	window.scroll(0,0);
	}

	ngOnInit(){
    	// var map = this._documentRef.createElement("script");
     //    map.type = "text/javascript";
     //    map.src = "https://maps.googleapis.com/maps/api/js?key=AIzaSyDpoojmKjDJu7pahF9KPtfoSKyIQTPzj2Q&libraries=places";
     //    map.async = true;
     //    map.defer = true;
     //    map.innerHTML = 'var stripe = Stripe("' + res_data.payment_key_id + '");var elements = stripe.elements();';
    	// this.elementRef.nativeElement.appendChild(map);

		// var stripe = this._documentRef.createElement("script");
	 //        stripe.type = "text/javascript";
	 //        stripe.src = "https://js.stripe.com/v3/";
	 //        stripe.async = true;
	 //        stripe.defer = true;
	 //    this.elementRef.nativeElement.appendChild(stripe);

		this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CHECK_AND_GET_DOMAIN_DATA, {}, (res_data) => {
			// setTimeout(()=>{

            var s = this._documentRef.createElement("script");
	        s.type = "text/javascript";
	        s.async = true;
	        s.defer = true;
	        s.innerHTML = 'var stripe = Stripe("' + res_data.payment_key_id + '");var elements = stripe.elements();';
        	this.elementRef.nativeElement.appendChild(s);




				// var link: any = document.querySelector("link[rel~='icon']");

				// if (!link) {
				//     link = document.createElement('link');
				//     link.rel = 'icon';
				//     document.getElementsByTagName('head')[0].appendChild(link);
				// }
				if(res_data.success){
					this.user_helper.user_cart.website_detail = res_data.website_detail;
					this.user_helper.user_cart.website_detail.site_logo = this.user_helper.CONSTANT.IMAGE_BASE_URL + res_data.website_detail.site_logo;

					this.user_helper.user_cart.website_detail.store_id = res_data.website_detail._id;
					this.user_helper.user_cart.is_main_website = false;
					if(this.user_helper.router.url == '/hopper' || this.user_helper.router.url == '/partner' || this.user_helper.router.url == '/lets-hope-together'){
						// window.location.href="/"
						this.user_helper.router.navigate(['/']);
				        // window.open('/')
					}
					
					// link.href = this.user_helper.user_cart.website_detail.site_logo;
					this.user_helper.user_cart.observable.next({});
				} else {
					this.user_helper.user_cart.website_detail ={
						site_color: '#82CFFB',
						site_desc: '',
						site_logo: '../assets/images/monogram-blue.svg',
						store_id: '',
						name: 'Hop',
				        address: '',
				        phone: '',
				        country_phone_code: ''

					};
					this.user_helper.user_cart.is_main_website = true;
					if(this.user_helper.router.url == '/'){
						// setTimeout(()=>{
						// 	this.user_helper.router.navigate(['/home']);
						// })
					}
					// link.href = this.user_helper.CONSTANT.IMAGE_BASE_URL + 'web_images/title_image.png';
					this.user_helper.user_cart.observable.next({});
				}
				
				// setInterval(()=>{
				// 	this.user_helper.user_cart.observable.next({});
				// }, 1000)
				document.documentElement.style.setProperty('--theme-color', this.user_helper.user_cart.website_detail.site_color || '#9bd1ef');

				// this.titleService.setTitle(this.user_helper.user_cart.website_detail.name);
			// })
			
		});

		var gtag = this._documentRef.createElement("script");
	        gtag.type = "text/javascript";
	        gtag.src = "https://www.googletagmanager.com/gtag/js?id=UA-130662174-1";
	        gtag.async = true;
	        gtag.defer = true;
	    this.elementRef.nativeElement.appendChild(gtag);

	    var gtag1 = this._documentRef.createElement("script");
	        gtag1.type = "text/javascript";
	        gtag1.async = true;
	        gtag1.defer = true;
	        gtag1.innerHTML = "window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', 'UA-130662174-1');";
		this.elementRef.nativeElement.appendChild(gtag1);

		var gtag2 = this._documentRef.createElement("script");
	        gtag2.type = "text/javascript";
	        gtag2.async = true;
	        gtag2.defer = true;
	        gtag2.innerHTML = "(function (w,i,d,g,e,t,s) {w[d] = w[d]||[];t= i.createElement(g);t.async=1;t.src=e;s=i.getElementsByTagName(g)[0];s.parentNode.insertBefore(t, s);})(window, document, '_gscq','script','//widgets.getsitecontrol.com/167717/script.js');";
	        this.elementRef.nativeElement.appendChild(gtag2);

	    var gtag3 = this._documentRef.createElement("script");
	        gtag3.type = "text/javascript";
	        gtag3.async = true;
	        gtag3.defer = true;
	        gtag3.innerHTML = "_gscq = window._gscq || [];_gscq.push(['trackPage','basket']);_gscq.push(['trackPage','home']);_gscq.push(['trackPage','']);";
	        this.elementRef.nativeElement.appendChild(gtag3);

	    var hotjar = this._documentRef.createElement("script");
	        hotjar.type = "text/javascript";
	        hotjar.async = true;
	        hotjar.defer = true;
	        hotjar.innerHTML = "(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:1121668,hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');";this.elementRef.nativeElement.appendChild(hotjar);

	     var hotjar2 = this._documentRef.createElement("script");
	        hotjar2.type = "text/javascript";
	        hotjar2.async = true;
	        hotjar2.defer = true;
	        hotjar2.innerHTML = "window.hj('identify', '1121668', {});"



	    // var jquery = this._documentRef.createElement("script");
	    //     jquery.type = "text/javascript";
	    //     jquery.src = "https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js";
	    //     jquery.async = true;
	    //     jquery.defer = true;
	    // this.elementRef.nativeElement.appendChild(jquery);

	}
}


