import { BrowserModule } from '@angular/platform-browser';
import { NgModule , OnInit, ViewChild, Injectable} from '@angular/core';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { RouterModule, Routes, Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlModule } from 'ngx-owl-carousel';
import { FormsModule, Validators, ReactiveFormsModule } from '@angular/forms';
import {TranslateModule, TranslateService, TranslateLoader} from '@ngx-translate/core';

import {HttpClientModule, HttpClient} from '@angular/common/http';

import {Cart} from "./cart";
import {Router_id} from "./routing_hidden_id";
import {Data} from "./data";
import {Helper} from './user_helper';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { NgbdModalBasic } from './header/modal-basic';
import { FooterComponent } from './footer/footer.component';
import { OrderComponent } from './order/order.component';
import { BooleanService } from './boolean.service';
import { ShopComponent } from './shop/shop.component';
import { PartnerComponent } from './partner/partner.component';
import { HopperComponent } from './hopper/hopper.component';
import { FaqComponent } from './faq/faq.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { WelcomePartnerComponent } from './welcome-partner/welcome-partner.component';
import { WelcomeHopperComponent } from './welcome-hopper/welcome-hopper.component';
import { TeamComponent } from './team/team.component';
import { BasketComponent } from './basket/basket.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { DeliveryComingComponent } from './delivery-coming/delivery-coming.component';
import { ProfileComponent } from './profile/profile.component';
import {HttpModule, JsonpModule} from '@angular/http';
import { ProfileCommonComponent } from './profile-common/profile-common.component';
import { PaymentsComponent } from './payments/payments.component';
import { OrdersComponent } from './orders/orders.component';
import { FavouriteStoresComponent } from './favourite-stores/favourite-stores.component';
import { AddressesComponent } from './addresses/addresses.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { UiSwitchModule } from 'ngx-ui-switch';
import { NgSelectModule } from '@ng-select/ng-select';

import { LazyLoadImageModule } from 'ng-lazyload-image';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

export const ROUTES: Routes = [
  // { path: '', component: HomeComponent },       
  { path: 'home', component: HomeComponent },
  { path: '', component: HomeComponent },
  { path: 'stores', component: OrderComponent },
  { path: 'stores/:delivery_name', component: OrderComponent },
  { path: 'stores/:delivery_name/:tags', component: OrderComponent },
  {path: ':city_name/:delivery_name/:store_name/:store_id', component: ShopComponent},
  { path: 'partner', component: PartnerComponent },
  { path: 'hopper', component: HopperComponent },
  { path: 'lets-hope-together', component: FaqComponent },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'welcome-partner', component: WelcomePartnerComponent },
  { path: 'welcome-hopper', component: WelcomeHopperComponent },
  { path: 'team', component: TeamComponent },
  { path: 'basket', component: BasketComponent },
  { path: 'thankyou', component: ThankyouComponent },

  { path: '', component: ProfileCommonComponent,
      children: [
          { path: 'profile', component: ProfileComponent},
          { path: 'addresses', component: AddressesComponent},
          { path: 'payments', component: PaymentsComponent},
          { path: 'orders', component: OrdersComponent},
          { path: 'order_detail', component: OrderDetailComponent},
          { path: 'delivery-coming', component: DeliveryComingComponent },
          { path: 'favourite_stores', component: FavouriteStoresComponent}
      ]
  },
  {path: '**', redirectTo: '',  pathMatch: 'full' , component: HomeComponent}

];

import {TranslateHttpLoader} from '@ngx-translate/http-loader';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider("94334734421-s9r1bvm2lm98ua61qqf0sls6e4kmej62.apps.googleusercontent.com")
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider("658120322070559")
  }
]);
 
export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [

    AppComponent,
    HeaderComponent,
    HomeComponent,
    NgbdModalBasic,
    FooterComponent,
    OrderComponent,
    ShopComponent,
    PartnerComponent,
    HopperComponent,
    FaqComponent,
    WelcomeComponent,
    WelcomePartnerComponent,
    WelcomeHopperComponent,
    TeamComponent,
    BasketComponent,
    ThankyouComponent,
    DeliveryComingComponent,
    ProfileComponent,
    ProfileCommonComponent,
    PaymentsComponent,
    OrdersComponent,
    FavouriteStoresComponent,
    AddressesComponent,
    OrderDetailComponent

  ],
  imports: [
      HttpModule,
      BrowserModule,
      UiSwitchModule,
      AngularFontAwesomeModule,
      FormsModule,
      OwlModule,
      NgSelectModule,
      HttpClientModule,
      TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
      SocialLoginModule,
      RouterModule.forRoot(ROUTES),
      LazyLoadImageModule,
      InfiniteScrollModule,
      NgbModule.forRoot(),
      ProgressbarModule.forRoot()
    
  ],
  providers: [
      Cart, Data, Router_id, BooleanService, Validators, Helper, TranslateService, 
      {
        provide: AuthServiceConfig,
        useFactory: provideConfig
      }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
