import { Component, NgZone, OnInit ,ElementRef, ViewChild, Injectable, } from '@angular/core';
import { Data } from './data';
import { Router, ActivatedRoute} from '@angular/router';
import { price_validation } from './constant';
import {Router_id} from './routing_hidden_id';
import {Cart} from './cart';
import {ERROR_CODE_CONSTANT, BASE_URL, country_list, ORDER_STATE , TIMEOUT , ORDER_STATUS_ID, PAYMENT_GATEWAY, WEEK_DAY, CONSTANT} from './constant';
import {title, button, heading_title , status , message , menu_title} from './user_panel_string';
import {ERROR_CODE} from './user_panel_error_message ';
import {MESSAGE_CODE} from './user_panel_success_message';
import {validation_message} from './user_panel_validation_message';
import {GET_METHOD , POST_METHOD} from './user_http_methods';
// import {Response, Http} from '@angular/http';
import { map } from "rxjs/operators";

import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {Http, Response, RequestOptions, Headers} from '@angular/http';
import * as CryptoJS from 'crypto-js';

export interface CartProductItems{
    item_id: Object,
    unique_id: Number,
    item_name: string,
    quantity: Number,
    image_url: any,
    details: string,
    specifications: any[],
    item_price: Number,
    formule_list: any[],
    offer_price: number,
    is_buy_one_get_one: boolean,
    total_specification_price: Number,
    total_item_and_specification_price: Number,
    offer_tags: any[],
    note_for_item: string
}

export interface cartProducts{
    items: any[],
    product_id: Object,
    product_name: string,
    unique_id: number,
    total_item_price: number
}

export interface cartSpecificationGroups{
    list: any[],
    name: string,
    price: number,
    type: number,
    formule_id: string,
    min_selection: number,
    max_selection: number,
    is_required: boolean,
    unique_id: number
}

@Injectable()
export class Helper{

    ///// for constant ////////////
    public error_code : any = ERROR_CODE_CONSTANT;
    public PAYMENT_GATEWAY_CONSTANT :any = PAYMENT_GATEWAY
    public ORDER_STATE : any = ORDER_STATE;
    public TIMEOUT : any = TIMEOUT;
    public ORDER_STATUS_ID:any = ORDER_STATUS_ID;
    public title: any = title;
    public button: any = button;
    public heading_title: any = heading_title;
    public ERROR_CODE: any = ERROR_CODE;
    public MESSAGE_CODE: any = MESSAGE_CODE;
    public status: any = status;
    public WEEK_DAY: any = WEEK_DAY;
    public messages:any = message;
    public menu_title:any = menu_title;
    public validation_message:any = validation_message;

    public GET_METHOD: any = GET_METHOD;
    public POST_METHOD: any = POST_METHOD;
    public myLoading:boolean = false;
    public BASE_URL:any =  BASE_URL;
    public CONSTANT:any =  CONSTANT;
    private log_boolean: Boolean = false;
    declined:Boolean = false;
    interval:any;
    public cart_unique_token: string = localStorage.getItem('cart_unique_token');
    public user_id: string = '';
    private user_type: number;
    public server_token: string = '';

    private cartProductItems : CartProductItems;
    private cartProducts : cartProducts;
    private cartSpecificationGroups : cartSpecificationGroups;
    public user_name: string = '';
    public country_list: any = country_list;

    width: number;
    height: number;

    // public is_main_website: boolean = false;
    // public website_detail: any = {
    //     site_color: '',
    //     site_desc: '',
    //     site_logo: ''
    // };

    constructor(public http: Http , public trans: TranslateService, public ngZone:NgZone, public route: ActivatedRoute , public user_cart: Cart, public  router_id: Router_id, public router: Router,  public elementRef: ElementRef, public data: Data) {}

    ngOnInit() {

    }
    message() {
        this.data.storage = {
            "code": '',
            "message": '',
            "class": ''
        }
    }

    set_dynamic_height_width(class_name = '.dynamic_image'){
        this.width = document.querySelector(class_name).clientWidth;
        this.height = document.querySelector(class_name).clientHeight;
    }

    clear_height_width(){
        this.height = this.width = undefined;
    }

    get_image(image_url, image_type = 'jpg') : string{
        return (this.height && this.width)  ? this.BASE_URL+'resize_image?width='+this.width+'&height='+this.height+'&format='+image_type+'&image='+this.CONSTANT.IMAGE_BASE_URL + image_url : null;
    }

    get_delivery_type_image(image_url) : string{
        return this.BASE_URL+'resize_image?&format=jpg&image='+this.CONSTANT.IMAGE_BASE_URL + image_url;
    }

    check_detail(){
      let user = JSON.parse(localStorage.getItem('user'));

        this.ngZone.run(() => {
          if(user && user._id){
              this.user_cart.user_id = user._id;
              this.user_cart.server_token = user.server_token;
          } else {
              this.user_cart.user_id = '';
              this.user_cart.server_token = '';
          }
        });
      this.cart_unique_token = localStorage.getItem('cart_unique_token');
      if(this.user_cart.user_id !== '') {
          this.get_user_detail()
      }
      this.get_cart();

      clearInterval(this.interval);
      if(this.user_cart.user_id !== '') {
          this.interval = setInterval(() => {
              // this.get_user_detail()
          }, 5000);
      }
  }

    get_user_detail(){
        this.http_post_method_requester(this.POST_METHOD.GET_DETAIL,{myLoading: false, user_id: this.user_cart.user_id, server_token: this.user_cart.server_token}, (res_data) => {

            // this.http.post(this.POST_METHOD.GET_DETAIL, {user_id: this.user_cart.user_id, server_token: this.user_cart.server_token}).map((res_data: Response) => res_data.json()) .subscribe(res_data => {
            if(res_data.success){
                this.user_cart.minimum_phone_number_length = res_data.minimum_phone_number_length;
                this.user_cart.maximum_phone_number_length = res_data.maximum_phone_number_length;


                this.data.storage = {
                    "message":  "",
                    "class": "alert-danger"
                }
                if(res_data.user.is_approved == false && this.declined == false)
                {
                    this.declined = true;
                    // this.modal.open();
                }
                else if(res_data.user.is_approved == true && this.declined == true)
                {
                    this.declined = false;
                    // this.modal.close();
                }

            } else {
                this.logout();
            }
        });
    }

    logout(){
        var user = JSON.parse(localStorage.getItem('user'));
        if(user!==null)
        {
            this.http_post_method_requester(this.POST_METHOD.LOGOUT, {myLoading: false, store_id:user._id}, (res_data) => {
                this.myLoading=false;
                localStorage.removeItem('user');
                this.check_detail();
            });
        }
        else
        {
            this.myLoading=false;
            localStorage.removeItem('user');
            this.check_detail();
        }
        if(this.user_cart.is_main_website){
            this.router.navigate(['/home']);
        } else {
            this.router.navigate([''])
        }
    }

    async http_post_method_requester(api_name, parameter, response) {
        // if(api_name !== 'api/user/get_detail' && api_name !== 'api/user/get_order_detail'){
            if(parameter.myLoading == undefined){
                parameter.myLoading = true;
            }
            this.user_cart.myLoading = parameter.myLoading;
        // }

        let header = new Headers();
        header.append("type", '7');
        if(this.user_cart.is_main_website !== null){
            header.append('is_main_website',this.user_cart.is_main_website.toString())
        }

        let user: any = localStorage.getItem('user');
        if(user){
            user = JSON.parse(user);
            header.append("id", user._id.toString());
            header.append("server_token", user.server_token.toString());
        }
        const httpOptions = new RequestOptions({headers: header});

        this.http.post(BASE_URL + api_name, parameter, httpOptions).pipe(map((res)=>res.json())).subscribe(async (res_data) => {

            if(res_data.data){
                res_data = await this.APIdecryptData(res_data.data)
            }
            
            if(api_name !== this.POST_METHOD.PAY_ORDER_PAYMENT && api_name !== this.POST_METHOD.GET_DETAIL && api_name !== this.POST_METHOD.GET_STRIPE_PAYMENT_INTENT){
                this.user_cart.myLoading = false;
            }
            if (res_data.success) {
                response(res_data);
            } else {
                // if (res_data.error_code === 999) {
                //     localStorage.removeItem('admin_detail');
                //     this.router.navigate(['admin/login']);
                // } else {
                response(res_data);
                // }
            }
        }, (error) => {
            console.log(error)
        });
    }

    async APIdecryptData(data): Promise<any> {
        try {
          
            let seceret_key = '49fe9399fd3667c8bba0bd6dfd7eed7873f9bb36d3f812467990e55a1a710368';
            const bytes = CryptoJS.AES.decrypt(data, seceret_key);
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return decryptedData;

        } catch (error) {
          return error;
        }
      }
    


    increase_qty(product_index, item_index){
        // if(this.user_cart.cart_data.cart[product_index].items[item_index].quantity < this.user_cart.cart_data.max_item_quantity_add_by_user) {
        this.myLoading = true;

        this.user_cart.cart_data.cart[product_index].items[item_index].quantity++;
        let qty = this.user_cart.cart_data.cart[product_index].items[item_index].quantity;
        let item_price = this.user_cart.cart_data.cart[product_index].items[item_index].item_price;
        let total_specification_price = this.user_cart.cart_data.cart[product_index].items[item_index].total_specification_price;

        let formule_list = this.user_cart.cart_data.cart[product_index].items[item_index].formule_list
        if(formule_list && formule_list.length>0){
          formule_list.forEach((formule:any)=>{
            if(formule.price){
            //   total_specification_price = total_specification_price + Number(formule.price);
            }
          })
        }


        this.user_cart.cart_data.cart[product_index].items[item_index].total_item_and_specification_price = ((item_price + total_specification_price) * qty);

        this.calculateTotalAmount()
        // }
    }

    decrease_qty(product_index, item_index){

        if(this.user_cart.cart_data.cart[product_index].items[item_index].quantity > 1){
            this.myLoading = true;
            this.user_cart.cart_data.cart[product_index].items[item_index].quantity--;

            let qty = this.user_cart.cart_data.cart[product_index].items[item_index].quantity;
            let item_price = this.user_cart.cart_data.cart[product_index].items[item_index].item_price;
            let total_specification_price = this.user_cart.cart_data.cart[product_index].items[item_index].total_specification_price;

            let formule_list = this.user_cart.cart_data.cart[product_index].items[item_index].formule_list
            if(formule_list && formule_list.length>0){
            formule_list.forEach((formule:any)=>{
                if(formule.price){
                // total_specification_price = total_specification_price + Number(formule.price);
                }
            })
            }

            this.user_cart.cart_data.cart[product_index].items[item_index].total_item_and_specification_price = ((item_price + total_specification_price) * qty);
            this.calculateTotalAmount();


        } else {
            this.remove_from_cart(product_index, item_index)
        }

    }

    checkout(){
        this.router.navigate(['basket']);
    }



    calculateTotalAmount(){
        let total = 0.;
        this.user_cart.cart_data.cart.forEach((product) => {
            product.items.forEach((item) => {
                this.check_item_offer(item)
                total = total + item.total_item_and_specification_price - item.offer_price;
            })
        });
        this.user_cart.total_cart_amount = total;

        if(this.user_cart.cart_data.total_item == 0){
            this.clear_cart();
        } else {
            this.add_to_cart();
        }

    }

    remove_from_cart(product_index, item_index){
        this.myLoading = true;
        this.user_cart.cart_data.cart[product_index].items.splice(item_index, 1);
        if(this.user_cart.cart_data.cart[product_index].items.length <= 0){
            this.user_cart.cart_data.cart.splice(product_index, 1);
        }
        this.user_cart.cart_data.total_item--;
        this.calculateTotalAmount();
    }

    add_to_cart(response = null){
        console.log(JSON.parse(JSON.stringify(this.user_cart.cart_data.cart)))
        let user = JSON.parse(localStorage.getItem('user'));
        if(user && user._id){
            this.user_id = user._id;
            this.user_type = user.user_type;
            this.server_token = user.server_token;
        }
        if(this.user_cart.cart_data.destination_addresses.length == 0){
            if(user && user._id){
                this.user_cart.destination_address.user_type = user.user_type;
                this.user_cart.destination_address.user_details = {
                    "name": user.first_name + ' ' + user.last_name,
                    "country_phone_code": user.country_phone_code,
                    "phone": user.phone,
                    "email": user.email
                };
            }
            this.user_cart.cart_data.destination_addresses.push(this.user_cart.destination_address);
        }
        let json ={
            user_id: this.user_id,
            server_token: this.server_token,
            user_type: this.user_type,
            cart_id: this.user_cart.cart_data.cart_id,
            store_id: this.user_cart.cart_data.selectedStoreId,
            cart_unique_token: this.cart_unique_token,
            order_details: this.user_cart.cart_data.cart,
            myLoading: false,
            destination_addresses: this.user_cart.cart_data.destination_addresses,
            pickup_addresses: this.user_cart.cart_data.pickup_addresses
        }
        this.http_post_method_requester(this.POST_METHOD.ADD_ITEM_IN_CART, json, (res_data) => {
            if(res_data.success){
                this.router_id.user.currency = this.router_id.user.delivery_currency;
                this.user_cart.cart_data.cart_id = res_data.cart_id;
                this.user_cart.cart_data.city_id = res_data.city_id;
                this.data.storage = {
                    "message": this.MESSAGE_CODE[res_data.message],
                    "class": "alert-info"
                }
                this.get_cart();
                if(response){
                    response({success: true})
                }
            } else {
                this.data.storage = {
                    "message": this.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.get_cart();
                if(response){
                    response({success: false})
                }

            }
            this.user_cart.is_add_cart_observable.next(true);
            this.message();
            this.myLoading = false;

        });
    }

    get_cart(){
        console.log(this.user_cart.is_schedule_order)
        console.log(this.user_cart.schedule_date)
        
        let user = JSON.parse(localStorage.getItem('user'));
        if(user && user._id){
            this.user_id = user._id;
            this.server_token = user.server_token;
        } else {
            this.user_id = '';
            this.server_token = '';
        }
        
        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            cart_unique_token: this.cart_unique_token,
            myLoading: false
        }
        if(this.user_cart.is_schedule_order){
            let selected_date:any = new Date(this.user_cart.schedule_date);
            selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.user_cart.timezone})
            json['selected_date'] = selected_date
        }
        this.http_post_method_requester(this.POST_METHOD.GET_CART, json, (res_data) => {

            this.cartProductItems = {
                item_id: null,
                unique_id: 0,
                item_name: '',
                quantity: 0,
                image_url: [],
                offer_tags: [],
                formule_list: [],
                details: '',
                specifications: [],
                item_price: 0,
                offer_price: 0,
                is_buy_one_get_one: false,
                total_specification_price: 0,
                total_item_and_specification_price: 0,
                note_for_item: ''
            }
            this.cartProducts = {
                items: [],
                product_id: null,
                product_name: '',
                unique_id: 0,
                total_item_price: 0
            }

            this.cartSpecificationGroups = {
                list: [],
                name: '',
                price: 0,
                formule_id: '',
                type: 0,
                is_required: false,
                min_selection: null,
                max_selection: null,
                unique_id: 0
        }
        
            this.user_cart.cart_data.cart = [];
            this.user_cart.cart_data.total_item = 0;
            this.user_cart.total_cart_amount = 0;
            this.user_cart.cart_main_item = [];
            this.user_cart.cart_data.is_get_card = true;

            if(res_data.success){

                this.user_cart.server_date = res_data.server_time;
                this.user_cart.timezone = res_data.timezone;

                this.router_id.user.currency = res_data.currency;
                this.user_cart.cart_data.cart_id = res_data.cart_id;
                this.user_cart.cart_data.city_id = res_data.city_id;

                this.user_cart.store_location = res_data.location;
                // this.user_cart.cart_data.deliveryAddress = res_data.destination_address;
                // this.user_cart.cart_data.deliveryLatLng = res_data.destination_location;
                this.user_cart.cart_data.destination_addresses = res_data.destination_addresses;
                this.user_cart.cart_data.pickup_addresses = res_data.pickup_addresses;
                this.user_cart.cart_data.selectedStoreId = res_data.store_id;
                let cart_data_res = res_data.cart.order_details;
                this.set_product_item_offer_tag(res_data.offers, cart_data_res)
                let you_want_to_add_price_in_menu = res_data.you_want_to_add_price_in_menu;
                let add_price_type = res_data.add_price_type;
                let add_price_value = res_data.add_price_value;

                cart_data_res.forEach((cart_product) => {
                    this.cartProducts = JSON.parse(JSON.stringify(this.cartProducts));

                    // this.cartProducts.items = cart_product.items;
                    this.cartProducts.product_id = cart_product.product_detail._id;
                    this.cartProducts.product_name = cart_product.product_detail.name;
                    this.cartProducts.unique_id = cart_product.product_detail.unique_id;

                    let item_array = [];
                    let itemPriceTotal = 0;
                    cart_product.items.forEach((cart_item) => {
                        let main_detail = JSON.parse(JSON.stringify(cart_item.item_details));
                        if(main_detail.formule_list){
                            main_detail.formule_list.forEach((formule_data)=>{
                                if(you_want_to_add_price_in_menu && add_price_value){
                                if(add_price_type==1){
                                    formule_data.price = +formule_data.price + +((formule_data.price * add_price_value)/100);
                                } else if(add_price_type==2){
                                    formule_data.price = formule_data.price + add_price_value;
                                }
                                }
                            })
                        }
                        main_detail.specifications.filter((specification_group) => {
                            specification_group.list.filter((specification) => {
                                if(you_want_to_add_price_in_menu && add_price_value){
                                    if(add_price_type==1){
                                      specification.price = +specification.price + +((specification.price * add_price_value)/100);
                                    }
                                }
                            });
                        });
                        if(you_want_to_add_price_in_menu && add_price_value){
                            if(add_price_type==1){
                                main_detail.price = main_detail.price + ((main_detail.price * add_price_value)/100);
                            } else if(add_price_type==2){
                                main_detail.price = main_detail.price + add_price_value;
                            }
                            // this.cartProductItems.item_price = cart_item.item_details.price;
                        }
                        
                        this.user_cart.cart_main_item.push(main_detail);

                        this.cartProductItems = JSON.parse(JSON.stringify(this.cartProductItems));

                        
                        this.cartProductItems.item_id = cart_item.item_details._id;
                        this.cartProductItems.formule_list = cart_item.formule_list;
                        // this.cartProductItems.formule_list = [...cart_item.formule_list];
                        // this.cartProductItems.formule_list.forEach((formule_data)=>{
                        //     if(you_want_to_add_price_in_menu && add_price_value){
                        //       if(add_price_type==1){
                        //         formule_data.price = formule_data.price + ((formule_data.price * add_price_value)/100);
                        //       }
                        //     }
                        // })
                        this.cartProductItems.unique_id = cart_item.item_details.unique_id;
                        this.cartProductItems.item_name = cart_item.item_details.name;
                        this.cartProductItems.quantity = cart_item.quantity;
                        this.cartProductItems.image_url = cart_item.item_details.image_url;
                        this.cartProductItems.details = cart_item.item_details.details;
                        // this.cartProductItems.specifications = specificationList;
                        this.cartProductItems.item_price = cart_item.item_details.price;

                        // this.cartProductItems.total_specification_price = specificationPriceTotal;
                        // this.cartProductItems.total_item_and_specification_price = this.total;
                        this.cartProductItems.note_for_item = cart_item.note_for_item;
                        let specificationPriceTotal =0;
                        let specification_group_array = [];
                        this.check_item_offer(cart_item)
                        this.cartProductItems.offer_price = cart_item.offer_price;
                        this.cartProductItems.is_buy_one_get_one = cart_item.is_buy_one_get_one;
                        this.cartProductItems.offer_tags = cart_item.offer_tags;
                        cart_item.specifications.forEach((specification_group, check_index) => {

                            this.cartSpecificationGroups = JSON.parse(JSON.stringify(this.cartSpecificationGroups));

                            this.cartSpecificationGroups.name = specification_group.name;
                            this.cartSpecificationGroups.type = specification_group.type;
                            this.cartSpecificationGroups.is_required = specification_group.is_required;
                            this.cartSpecificationGroups.min_selection = specification_group.min_selection;
                            this.cartSpecificationGroups.max_selection = specification_group.max_selection;
                            this.cartSpecificationGroups.unique_id = specification_group.unique_id;
                            this.cartSpecificationGroups.formule_id = specification_group.formule_id;
                            // this.cartSpecificationGroups.price = specification_group.price;
                            // this.cartSpecificationGroups.list = specification_group.list;
                            let specification_array = [];
                            let specification_price = 0;
                            specification_group.list.forEach((specification) => {



                                cart_item.item_details.specifications.forEach((new_specification_group) => {
                                    if(!new_specification_group.formule_id || new_specification_group.formule_id == specification_group.formule_id){
                                        if(specification_group.unique_id == new_specification_group.unique_id){

                                            new_specification_group.list.forEach((new_specification) => {
                                                if(specification.unique_id == new_specification.unique_id){
                                                    let add_price = 0;
                                                    if(you_want_to_add_price_in_menu && add_price_value){
                                                        if(add_price_type==1){
                                                            // new_specification.price = Number(new_specification.price) + Number((new_specification.price * add_price_value)/100);
                                                            add_price = ((new_specification.price * add_price_value)/100);
                                                        }
                                                    }
                                                    specification.price = Number(new_specification.price) + add_price;
                                                    specification_price = specification_price + Number(new_specification.price) + add_price;
                                                    specification_array.push(specification);
                                                    specificationPriceTotal = specificationPriceTotal + Number(new_specification.price) + add_price;
                                                }
                                            })
                                        }
                                    }
                                })

                            });
                            this.cartSpecificationGroups.price = specification_price;
                            this.cartSpecificationGroups.list = specification_array;
                            specification_group_array.push(this.cartSpecificationGroups);
                        });
                        this.cartProductItems.specifications = specification_group_array;
                        this.cartProductItems.total_specification_price = specificationPriceTotal;
                        if(you_want_to_add_price_in_menu && add_price_value){
                            if(add_price_type==1){
                                cart_item.item_details.price = cart_item.item_details.price + ((cart_item.item_details.price * add_price_value)/100);
                            } else if(add_price_type==2){
                                cart_item.item_details.price = cart_item.item_details.price + add_price_value;
                            }
                            console.log('cart_item.item_details.price: '+cart_item.item_details.price)
                            this.cartProductItems.item_price = cart_item.item_details.price;
                        }
                        let item_price = cart_item.item_details.price
                        this.cartProductItems.formule_list.forEach((formule:any)=>{
                          if(formule.price){
                            // specificationPriceTotal = specificationPriceTotal + Number(formule.price)
                            this.cartProductItems.item_price = Number(formule.price)
                            item_price = Number(formule.price)
                            this.cartProductItems.item_name = formule.name;
                          }
                        })
                        this.cartProductItems.total_item_and_specification_price = (specificationPriceTotal + item_price)* cart_item.quantity;

                        item_array.push(this.cartProductItems)
                        console.log('specificationPriceTotal: '+specificationPriceTotal)
                        itemPriceTotal = itemPriceTotal - (this.cartProductItems.offer_price) + ((specificationPriceTotal + item_price) * cart_item.quantity);
                        this.user_cart.cart_data.total_item++;
                    });
                    this.cartProducts.items = item_array;
                    this.cartProducts.total_item_price = itemPriceTotal;
                    this.user_cart.cart_data.cart.push(this.cartProducts);
                    this.user_cart.total_cart_amount = this.user_cart.total_cart_amount + itemPriceTotal;

                });
                console.log(this.user_cart.cart_data.cart)
                this.user_cart.is_get_cart_observable.next(true);
                if(this.router.url == '/order_detail'){
                    this.router.navigate(['basket']);
                }
            } else {
                this.user_cart.is_get_cart_observable.next(true);
            }

        });


    }

    check_item_offer(cart_item){
      if(cart_item.offer_tags){
        var index = cart_item.offer_tags.findIndex((offer)=>offer.offer_type==2);
        if(index !== -1){
          var offer_detail = cart_item.offer_tags[index];
          var discount_type = offer_detail.discount_type;
          var discount_value = offer_detail.discount_value;
          var item_price = cart_item.item_price + cart_item.total_specification_price
          var offer_price = 0;
          if(discount_type==0){
            offer_price = (item_price * discount_value)/100;
          } else{
            offer_price = discount_value;
            if(offer_price > cart_item.total_item_and_specification_price){
              offer_price = cart_item.total_item_and_specification_price;
            }
          }
          // this.total = this.total - offer_price;
          cart_item.offer_price = offer_price * cart_item.quantity;
          // cartProductItems.offer_price = offer_price;
        }
      }
    }

    check_buy_one_get_one(item_detail){
        var index = item_detail.offer_tags ? item_detail.offer_tags.findIndex((offer)=>offer.offer_type==1) : -1;
        if(index !== -1){
            return true;
        } else {
            return false;
        }
    }

    set_product_item_offer_tag(offers: any[], cart_data_res){
        cart_data_res.forEach((product_detail, product_index)=>{
            product_detail.product_detail.offer_tags = [];
            product_detail.items.forEach((item_detail:any, item_index:any)=>{
                item_detail.offer_price = 0;
                item_detail.offer_tags = [];
            });
        });
      if(offers){
        offers.forEach((offer)=>{
          if(offer.offer_type==1 || offer.offer_type==2){
            offer.product_item_ids.forEach((product_item_id: string)=>{
              this.check_product_item_offer(cart_data_res, product_item_id, offer)
            })
          }
        })
      }
      setTimeout(()=>{
          console.log(cart_data_res)
      },1000)
    }

    check_product_item_offer(cart_data_res, product_item_id:string, offer:any){
        cart_data_res.forEach((product_detail, product_index)=>{
            if(product_item_id.toString() == product_detail.product_detail._id.toString()){
              if(!product_detail.product_detail.offer_tags){
                product_detail.product_detail.offer_tags = []
              }
              product_detail.product_detail.offer_tags.push(offer)
              product_detail.items.forEach((item_detail:any, item_index:any)=>{
                if(!item_detail.offer_tags){
                  item_detail.offer_tags = []
                }
                item_detail.offer_tags.push(offer)
                if(offer.offer_type==2){
                  item_detail.offer_price = 0;
                  var discount_value = offer.discount_value;
                  if(offer.discount_type==0){
                    if(discount_value>100){
                      discount_value=100
                    }
                    item_detail.offer_price = item_detail.price - ((item_detail.price * discount_value)/100);
                  } else {
                    if(discount_value>item_detail.price){
                      discount_value=item_detail.price
                    }
                    item_detail.offer_price = item_detail.price - discount_value
                  }
                }
              });
            } else {
              product_detail.items.forEach((item_detail:any, item_index:any)=>{
                if(product_item_id.toString() == item_detail.item_id.toString()){
                  if(!item_detail.offer_tags){
                    item_detail.offer_tags = []
                  }
                    item_detail.offer_tags.push(offer);
                  if(offer.offer_type==2){
                    item_detail.offer_price = 0;
                    var discount_value = offer.discount_value;
                    if(offer.discount_type==0){
                      if(discount_value>100){
                        discount_value=100
                      }
                      item_detail.offer_price = item_detail.price - ((item_detail.price * discount_value)/100);
                    } else {
                      if(discount_value>item_detail.price){
                        discount_value=item_detail.price
                      }
                      item_detail.offer_price = item_detail.price - discount_value
                    }
                  }
                }
              })
            }
        })

    }

    clear_cart(){
        let user = JSON.parse(localStorage.getItem('user'));
        if(user && user._id){
            this.user_id = user._id;
            this.server_token = user.server_token;
        }
        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            cart_id: this.user_cart.cart_data.cart_id,
            myLoading: false, 
            cart_unique_token: this.cart_unique_token,
        }
        this.http_post_method_requester(this.POST_METHOD.CLEAR_CART, json, (res_data) => {
            this.myLoading = false;
            if(res_data.success){
                this.user_cart.cart_data = {
                    cart_id: null,
                    city_id: null,
                    destination_addresses: [],
                    pickup_addresses: [],
                    cart: [],
                    selectedStoreId: null,
                    total_item: 0
                }
                this.user_cart.total_cart_amount = 0;
                this.user_cart.order_payment_id = null;
            } else {
                this.data.storage = {
                    "message": this.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.get_cart()
            }
            this.message();
        });
    }

    go_to_deliveries(){
        if(this.user_cart.is_main_website){
            this.router.navigate(['/home']);
          } else {
            this.router.navigate(['']).then(() => {
            window.location.reload();
        });
          }
    }

    signin(){
        this.user_cart.after_login = "deliveries"
        this.router.navigate(['user/login']);
    }

    register(){
        this.user_cart.after_login = "deliveries"
        this.router.navigate(['user/register']);
    }

    signout(){
        this.router.navigate(['profile']);
    }

    string_log(key , value){
        if(this.log_boolean){
            console.log(key + ': ' + value);
        }
    }

    json_log(json){
        if(this.log_boolean){
            console.log(json);
        }
    }


    http_status(error)
    {
        if (error.status === 500) {
            console.log("Internal Server Error")
        }
        else if (error.status === 502) {
            console.log("Bad Gateway")
        }
        else if (error.status === 404) {
            console.log("Url Not Found")
        }
        else if (error.status === 503) {
            console.log("Service Unavailable")
        }
        else if (error.status === 504) {
            console.log("Gateway Timeout")
        }
        else if (error.status === 408) {
            console.log("Request Timeout")
        }
        else if (error.status === 413) {
            console.log("Request Entity To Large")
        }
    }


    ////

    number_validation(evt)
    {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57))
        {
            if( charCode == 46)
            {
                return true;
            }
            else
            {
                return false;
            }
        }
        return true;
    }

    price_validation(evt, price)
    {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        price = price.toString();
        price = price.split(".");
        if(price[0] <= price_validation.MAX_PRICE)
        {
            if(price[1] == undefined)
            {
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                {
                    if( charCode == 46 && price[0] > 1)
                    {
                        return true;
                    }
                    return false;
                }
                return true;
            }
            else
            {
                if(price[1] <= price_validation.MAX_PRICE_AFTER_POINT)
                {
                    if (charCode > 31 && (charCode < 48 || charCode > 57))
                    {
                        return false;
                    }
                    return true;
                }
                else
                {
                    if(charCode == 8)
                    {
                        return true;
                    }
                    return false;
                }
            }
        }
        else
        {
            if(price[1] != undefined)
            {
                if(price[1] <= price_validation.MAX_PRICE_AFTER_POINT)
                {
                    if (charCode > 31 && (charCode < 48 || charCode > 57))
                    {
                        return false;
                    }
                    return true;
                }
                else
                {
                    if(charCode == 8)
                    {
                        return true;
                    }
                    return false;
                }
            }
            else
            {
                if(charCode == 46 || charCode == 8)
                {
                    return true;
                }
                return false;
            }

        }
    }

    getIDFromEmailToken(email_token) {

        var server_token = "", id = "", milli = "";
        var milli_seconds = 0;
        var length = 30;

        var milli_token = email_token.substr(0, length);
        var id_token = email_token.substr(length);

        for (var i = 0; i < length; i++) {
            if (i % 2 == 0) {
                milli = milli + milli_token.charAt(i);
            } else {
                server_token = server_token + milli_token.charAt(i);
            }
        }

        milli = milli.split("").reverse().join("");
        milli_seconds = parseInt(milli);

        length = id_token.length;
        for (var i = 0; i < length; i++) {
            if (i % 2 == 0) {
                id = id + id_token.charAt(i);
            } else {
                server_token = server_token + id_token.charAt(i);
            }
        }
        return {id:id , server_token:server_token , milli_seconds : milli_seconds};

    }

    GetCardType(number)
    {
        // visa
        var re = new RegExp("^4");
        if (number.match(re) != null)
            return "Visa";

        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number))
            return "Mastercard";

        // AMEX
        re = new RegExp("^3[47]");
        if (number.match(re) != null)
            return "AMEX";

        // Discover
        re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
        if (number.match(re) != null)
            return "Discover";

        // Diners
        re = new RegExp("^36");
        if (number.match(re) != null)
            return "Diners";

        // Diners - Carte Blanche
        re = new RegExp("^30[0-5]");
        if (number.match(re) != null)
            return "Diners - Carte Blanche";

        // JCB
        re = new RegExp("^35(2[89]|[3-8][0-9])");
        if (number.match(re) != null)
            return "JCB";

        // Visa Electron
        re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
        if (number.match(re) != null)
            return "Visa Electron";

        return "";
    }
}
