import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {title, links, button} from '../helper';
import {Helper} from "../user_helper";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";
declare var jQuery;

export interface OrderDetail{
    _id: Object,
    currency: string,
    request_id: string,
    request_status: number,
    store_detail: Object,
    unique_id: number,
    order_status: number,
    order_status_id: number,
    total_time: string,
    confirmation_code_for_complete_delivery: string,
    provider_detail: any
}

@Component({
  selector: 'app-delivery-coming',
  templateUrl: './delivery-coming.component.html',
    providers: [Helper]
})
export class DeliveryComingComponent implements OnInit {

	public title:any = title;
	public links:any = links;
  button: any = button;

	order_id: string = '';
    public user_id: string = '';
    public server_token: string = '';
    OrderDetail: OrderDetail;
    order_interval: any;
    ORDER_STATE: any;
    closeResult: string;
    cancel_reason: string = '';
    modalReference: any;

  constructor( public bln: BooleanService, private modalService: NgbModal, public user_helper: Helper) { }

    ngOnDestroy(){
        clearInterval(this.order_interval);
    }

  ngOnInit() {
 	
    this.bln.showLogin = false;
    this.bln.showhedmid = true;
    this.bln.showcart = false;
    this.bln.isSticky = false;
    this.bln.showLink = false;
    this.bln.isHome = true;
    this.bln.cart = true;
    this.bln.showSignup = true;
    this.bln.isAdd_product = false;
    this.bln.isShop = true;
    this.bln.isInner_page = true;
    this.bln.isGreen = false;
    this.bln.isBod_btm_h = false;
      this.bln.address = true;
      this.bln.force_cart = false;

    this.order_id = this.user_helper.router_id.user.order_id;
    let user = JSON.parse(localStorage.getItem('user'));
    if(user && user._id){
        this.user_id = user._id;
        this.server_token = user.server_token;
    }
    this.ORDER_STATE = this.user_helper.ORDER_STATE;
      this.OrderDetail = {
          _id: null,
          request_id: null,
          request_status: 0,
          currency: '',
          store_detail: {},
          unique_id: 0,
          order_status: 0,
          order_status_id: 0,
          total_time: '',
          confirmation_code_for_complete_delivery: '',
          provider_detail: null
      }
      this.get_order_detail();
      this.order_interval=setInterval(()=>{
          this.get_order_detail();
      },3000)


  }

  set_cancel_reason(cancel_reason){
    this.cancel_reason = cancel_reason;
  }

    get_order_detail(){

        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            order_id: this.order_id,
            myLoading: false
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_ORDER_DETAIL, json, (res_data) => {

            this.user_helper.myLoading = false;
            if (res_data.success) {
                this.OrderDetail = res_data.order;
                this.OrderDetail.currency = res_data.order.country_detail.currency_sign;
                if(res_data.order.request_id){
                    this.OrderDetail.request_status = res_data.order.request_detail.delivery_status
                } else {
                    this.OrderDetail.request_status = 0;
                }

                if(this.OrderDetail.order_status_id == this.user_helper.ORDER_STATUS_ID.CANCELLED || this.OrderDetail.order_status_id == this.user_helper.ORDER_STATUS_ID.REJECTED){
                    this.user_helper.router.navigate(['orders']);
                } else {

                    let time = res_data.order.order_payment_detail.total_time * 60;
                    let hours = Math.floor(time / 3600)
                    let minute = Math.floor((time % 3600) / 60);

                    this.OrderDetail.total_time = hours + ' hr ' + ': ' + minute + ' min';

                }

            } else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                if(this.user_helper.user_cart.is_main_website){
                  this.user_helper.router.navigate(['/home']);
                } else {
                  this.user_helper.router.navigate(['']).then(() => {
                      window.location.reload();
                  });;
                }
            }
        });

    }

    go_to_full_detail(){
        this.user_helper.router_id.user.order_id = this.order_id;
        this.user_helper.router.navigate(['order_detail']);
    }

    cancel_order(cancel_order_modal){
      this.cancel_reason = '';
      this.open(cancel_order_modal);
    }

    cancel_order_confirmation(cancel_order_confirm_modal){
      this.modalReference.close();
      this.open(cancel_order_confirm_modal);
    }

    cancel_order_service(){
        if(this.cancel_reason !== '') {

            let json = {
                user_id: this.user_id,
                server_token: this.server_token,
                order_id: this.order_id,
                cancel_reason: this.cancel_reason
            }
            this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.USER_CANCEL_ORDER, json, (res_data) => {
                this.user_helper.myLoading = false;
                if (res_data.success) {
                    this.user_helper.data.storage = {
                        "message": this.user_helper.MESSAGE_CODE[res_data.message],
                        "class": "alert-info"
                    }
                    this.modalReference.close();
                    this.user_helper.router.navigate(['orders']);
                } else {
                    this.user_helper.data.storage = {
                        "message": this.user_helper.ERROR_CODE[res_data.error_code],
                        "class": "alert-danger"
                    }
                    this.modalReference.close();
                    this.user_helper.router.navigate(['orders']);
                }
            });
        }
    }

    open(content) {
        this.modalReference = this.modalService.open(content, { size: 'sm' });
        this.modalReference.result.then((result) => {
          this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }

}
