
import { Component, OnInit } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel';
import { button, placeholder, title, slider_conList, tabList, menu_title } from '../helper';
import { ViewEncapsulation } from '@angular/core';
import { BooleanService } from '../boolean.service';
import { Location } from '@angular/common';
import { Helper } from '../user_helper';
declare var google;
declare var jQuery;
import { NgbTabChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

export interface CurrentLocation {
  city1: string,
  city2: string,
  city3: string,
  country: string,
  country_code: string,
  country_code_2: string,
  city_code: string,
  latitude: any,
  longitude: any,
  address: string,
  postal_code: string,
  state: string
}

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [Helper]
})
export class HomeComponent implements OnInit {
  closeResult: string;
  public button: any = button;
  public placeholder: any = placeholder;
  public title: any = title;
  public slider_conList: any = slider_conList;
  private tabList: any = tabList;
  private menu_title: any = menu_title;
  selected_delivery_id: string = '';
  delivery_type_image: string = '../assets/images/sac-kraft.webp';

  public current_location: CurrentLocation;
  deliveries_in_city: any[] = [];

  map_address: string = '';
  map_latitude: number = 0;
  map_longitude: number = 0;
  is_submitted: boolean = false;
  map: any;
  city_id: Object = null;
  private cart_unique_token: string = '';
  private user_id: string = '';
  private server_token: string = '';
  public user_profile: string = '';
  public is_show_unavailable_delivery: boolean = false;
  private cart_data: any = {};

  device_type: string = '';
  app_url: string = '';

  city_list: any[]=[
    {city_name: 'Genève', location: [46.2043907, 6.14315769999996]},
    {city_name: 'Nyon', location: [46.3832683, 6.2347852]},
    {city_name: 'Gland', location: [46.42221886166283, 6.271559305083428]},
    {city_name: 'Lausanne', location: [46.5196535, 6.6322734]}
  ]

  public color_array: any[] = ['#EAD174', '#A1545E', '#A2D6F1', '#76C8A8', '#EAD174', '#A1545E', '#A2D6F1', '#76C8A8']
  color: string = '#EAD174';

  constructor(private modalService: NgbModal, public bln: BooleanService, private location: Location, public user_helper: Helper) { }

  ngOnInit() {
    window.scroll(0,0);
    // this.delivery_type_image = this.user_helper.get_image('https://hop.delivery/assets/images/sac-kraft.png', 'jpeg')
    this.user_helper.myLoading = false;
    this.current_location = {
      address: "",
      city1: "Geneva",
      city2: "Geneva",
      city3: "Geneva",
      city_code: "GE",
      country: "Switzerland",
      country_code: "CH",
      country_code_2: "CH",
      latitude: 46.2043907,
      longitude: 6.143157699999961,
      postal_code: '',
      state: ''
    }
    var detail = this.user_helper.user_cart.observable.subscribe((data) => {

      if (data) {
        this.user_helper.check_detail();
        if (detail) {
          detail.unsubscribe();
        }
        if (this.user_helper.user_cart.is_main_website) {
          if (localStorage.getItem('current_location')) {
            this.user_helper.router_id.user_current_location = JSON.parse(localStorage.getItem('current_location'));
            this.current_location = JSON.parse(localStorage.getItem('current_location'));
            jQuery('#address1').val(this.current_location.address)
          }

          let user = JSON.parse(localStorage.getItem('user'));
          if (user && user._id) {
            this.user_id = user._id;
            this.server_token = user.server_token;
            this.user_profile = user.image_url;
          }

          // this.check_device_type()



          this.cart_data = this.user_helper.user_cart.cart_data;
          this.cart_unique_token = localStorage.getItem('cart_unique_token');
          let autocompleteElm = <HTMLInputElement>document.getElementById('address1');
          let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {
            strictBounds: false,
            bounds: new google.maps.LatLngBounds(new google.maps.LatLng(46.2043907, 6.143157699999961))
          });

          jQuery("#address1").focus();

          autocomplete.addListener('place_changed', () => {
            // this.user_helper.myLoading = true;
            var place = autocomplete.getPlace();
            if (place.geometry && place.geometry.location) {
              this.current_location.latitude = place.geometry.location.lat();
              this.current_location.longitude = place.geometry.location.lng();
              this.is_submitted = false;
              this.current_location.city1 = '';
              this.current_location.city2 = '';
              this.current_location.city3 = '';
              this.current_location.state = '';
              this.current_location.postal_code = '';
              this.current_location.country = '';
              for (var i = 0; i < place.address_components.length; i++) {
                // this.address = place.formatted_address;
                this.current_location.address = place.formatted_address;
                jQuery('#address1').val(place.formatted_address)
                if (place.address_components[i].types[0] == "locality") {
                  this.current_location.city1 = place.address_components[i].long_name;
                } else if (place.address_components[i].types[0] == "administrative_area_level_1") {
                  this.current_location.city2 = place.address_components[i].long_name;
                  this.current_location.city_code = place.address_components[i].short_name;
                  this.current_location.state = place.address_components[i].long_name;
                } else if (place.address_components[i].types[0] == "administrative_area_level_2") {
                  this.current_location.city3 = place.address_components[i].long_name;
                } else if (place.address_components[i].types[0] == "country") {
                  this.current_location.country = place.address_components[i].long_name;
                  this.current_location.country_code = place.address_components[i].short_name;
                  this.current_location.country_code_2 = place.address_components[i].short_name;
                } else if (place.address_components[i].types[0] == "postal_code") {
                  this.current_location.postal_code = place.address_components[i].long_name;
                }
              }
              this.delivery_list(this.current_location, false)
            }
          });
          // if(this.current_location.address !== '') {
          this.delivery_list(this.current_location, false)
          // } else {
          //   this.delivery_list(this.current_location)
          // }

          this.bln.showLogin = true;
          this.bln.showhedmid = true;
          this.bln.showcart = true;
          this.bln.isSticky = true;
          this.bln.showLink = false;
          this.bln.isHome = false;
          this.bln.cart = false;
          this.bln.showSignup = false;
          this.bln.isAdd_product = true;
          this.bln.isShop = false;
          this.bln.isInner_page = true;
          this.bln.isGreen = true;
          this.bln.isBod_btm_h = true;
          this.bln.address = true;
          this.bln.force_cart = false;



          $(document).ready(function () {

            $('video').parent().click(function () {
              if ($(this).children("video").get(0).paused) {
                $(this).children("video").get(0).play();
                $(this).children(".playpause").fadeOut();
              } else {
                $(this).children("video").get(0).pause();
                $(this).children(".playpause").fadeIn();
              }
            });

            $('a#ngb-tab-0').click(function () {
              $(this).closest('.section2').addClass('bg1');
            });
            $('a#ngb-tab-1').click(function () {
              $(this).closest('.section2').addClass('bg2');
            });

            $('a#ngb-tab-2').click(function () {
              $(this).closest('.section2').addClass('bg3');
            });
            $('a#ngb-tab-3').click(function () {
              $(this).closest('.section2').addClass('bg4');
            });

            $('a#ngb-tab-1, a#ngb-tab-2, a#ngb-tab-3').click(function () {
              $(this).closest('.section2').removeClass('bg1');
            });
            $('a#ngb-tab-0, a#ngb-tab-2,a#ngb-tab-3').click(function () {
              $(this).closest('.section2').removeClass('bg2');
            });
            $('a#ngb-tab-0, a#ngb-tab-1,a#ngb-tab-3').click(function () {
              $(this).closest('.section2').removeClass('bg3');
            });
            $('a#ngb-tab-0, a#ngb-tab-1,a#ngb-tab-2').click(function () {
              $(this).closest('.section2').removeClass('bg4');
            });
          });

        } else {
          this.user_helper.router.navigate([''])
        }
      }
    })
  }

  ngOnDestroy(){
      this.user_helper.clear_height_width();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  set_current_location() {
    navigator.geolocation.getCurrentPosition((position) => {
      this.geocoder(position.coords.latitude, position.coords.longitude, false)
    });
  }

  select_city(location){
    this.geocoder(location[0], location[1], true)
  }

  geocoder(latitude, longitude, bool) {

    var initialLocation = new google.maps.LatLng(latitude, longitude);
    var geocoder = new google.maps.Geocoder();

    let request = { latLng: initialLocation };
    geocoder.geocode(request, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.current_location.latitude = latitude;
        this.current_location.longitude = longitude;
        this.is_submitted = false;
        this.current_location.city1 = '';
        this.current_location.city2 = '';
        this.current_location.city3 = '';
        this.current_location.state = '';
        this.current_location.country = '';
        this.current_location.postal_code = '';
        this.user_helper.ngZone.run(() => {
          this.current_location.address = results[0].formatted_address;
          jQuery('#address1').val(results[0].formatted_address)
        });
        for (var i = 0; i < results[0].address_components.length; i++) {
          if (results[0].address_components[i].types[0] == "locality") {
            this.current_location.city1 = results[0].address_components[i].long_name;
          } else if (results[0].address_components[i].types[0] == "administrative_area_level_1") {
            this.current_location.city2 = results[0].address_components[i].long_name;
            this.current_location.state = results[0].address_components[i].long_name;
            this.current_location.city_code = results[0].address_components[i].short_name;
          } else if (results[0].address_components[i].types[0] == "administrative_area_level_2") {
            this.current_location.city3 = results[0].address_components[i].long_name;
          } else if (results[0].address_components[i].types[0] == "country") {
            this.current_location.country = results[0].address_components[i].long_name;
            this.current_location.country_code = results[0].address_components[i].short_name;
            this.current_location.country_code_2 = results[0].address_components[i].short_name;
          } else if (results[0].address_components[i].types[0] == "postal_code") {
            this.current_location.postal_code = results[0].address_components[i].long_name;
          }
        }
        this.delivery_list(this.current_location, bool)
      }
    });
  }

  get_store_list_with_tag(delivery_id, tag) {
    this.user_helper.router_id.user.selected_tag = [tag];
    this.selected_delivery_id = delivery_id;
    this.get_store_list();
  }

  get_store_list_without_tag() {
    this.user_helper.router_id.user.selected_tag = [];
    this.is_submitted = true;
    if (this.current_location.address && this.current_location.latitude && this.current_location.longitude) {
      this.get_store_list();
    }
  }

  delivery_list(current_location, bool) {
    this.user_helper.router_id.user_current_location = current_location;
    localStorage.setItem('current_location', JSON.stringify(current_location));
    current_location.cart_unique_token = this.cart_unique_token;
    current_location.user_id = this.user_id;
    current_location.server_token = this.server_token;
    current_location.myLoading = false;
    this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_DELIVERY_LIST_FOR_NEAREST_CITY, current_location, (res_data) => {

      this.user_helper.myLoading = false;
      if (res_data.success) {
        this.is_show_unavailable_delivery = false;
        this.user_helper.ngZone.run(() => {
          this.deliveries_in_city = res_data.deliveries;
          this.city_id = res_data.city._id;

          this.user_helper.router_id.user.delivery_currency = res_data.currency_sign;
          this.user_helper.user_cart.timezone = res_data.city.timezone;
          this.user_helper.user_cart.server_date = res_data.server_time;

          if (res_data.deliveries.length > 0) {
            this.selected_delivery_id = res_data.deliveries[0]._id;
            this.user_helper.height = 400;
            this.user_helper.width = 886;
            this.delivery_type_image = this.user_helper.get_image(res_data.deliveries[0].image_url3, 'webp')
            // this.user_helper.CONSTANT.IMAGE_BASE_URL + res_data.deliveries[0].image_url3;
            if(bool){
              this.get_store_list();
            }
          } else {
            this.selected_delivery_id = '';
          }
          // if(this.user_helper.user_cart.after_login !== "invoice") {
          //     let city_name = res_data.city_data.city1.replace(/ /g, "-");
          //     city_name = city_name.toLowerCase();
          //     this.location.replaceState('deliveries/' + city_name);
          // }
        })
      } else {
        this.selected_delivery_id = '';
        this.deliveries_in_city = [];
        this.is_show_unavailable_delivery = true;
      }
    });
  }

  check_device_type() {
    var userAgent = navigator.userAgent || navigator.vendor;


    if (/android/i.test(userAgent)) {
      this.device_type = "android"
      this.app_url = "https://play.google.com/store/apps/details?id=com.hop.delivery";
      jQuery('#content').click();
      jQuery('.modal-content').css('background-color', '#9bd1ef');
      setTimeout(() => {
        jQuery('.modal-content').css('padding', '70px 0px');
        jQuery('.modal-dialog').css('margin-top', '5px');
      })
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      this.device_type = "ios";
      this.app_url = "https://itunes.apple.com/us/app/hop-delivery/id1322634409"
      jQuery('#content').click();
      jQuery('.modal-content').css('background-color', '#9bd1ef');
      setTimeout(() => {
        jQuery('.modal-content').css('padding', '70px 0px');
        jQuery('.modal-dialog').css('margin-top', '5px');
      })
    }
    jQuery('#device_modal').click(() => {
      this.user_helper.router.navigate(['faq']);
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  get_store_list() {
    if (this.selected_delivery_id) {
      let delivery_index = this.deliveries_in_city.findIndex((x) => x._id == this.selected_delivery_id);
      let delivery = this.deliveries_in_city[delivery_index];
      this.user_helper.router_id.user.delivery_type_id = delivery._id;
      this.user_helper.router_id.user_current_delivery_type = delivery;
      this.user_helper.router_id.user.city_id = this.city_id;
      this.user_helper.router_id.delivery_tag = delivery.famous_products_tags;
      this.user_helper.router_id.deliveries_in_city = this.deliveries_in_city;
      let delivery_name = delivery.delivery_name.replace(/ /g, "_");
      this.user_helper.router.navigate(['stores/'+delivery_name]);
    }
  }



  onLinkClick(index) {
    this.color = this.color_array[index];
  }

  onTabChange($event: NgbTabChangeEvent) {
    this.color = this.color_array[Number($event.activeId)];
  }

  clickon_category(selected_delivery_id, delivery_type_image) {
    this.selected_delivery_id = selected_delivery_id;
    // this.delivery_type_image = this.user_helper.CONSTANT.IMAGE_BASE_URL + delivery_type_image;
    this.get_store_list_without_tag();
  }
}
