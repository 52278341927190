import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {title, links, status, placeholder, label, button, description, price, menu_title, orderTable} from '../helper';
import {ViewEncapsulation} from '@angular/core';
import {Helper} from "../user_helper";
declare var jquery:any;
declare var $ :any;

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [Helper]
})
export class OrdersComponent implements OnInit {

    public title:any = title;
    public links:any = links;
    public placeholder:any = placeholder;
    public label:any = label;
    public button:any = button;
    public description:any = description;
    public price:any = price;
    public menu_title:any = menu_title;
    public orderTable:any = orderTable;

    model;
    model2;

    current_order_list: any[] = [];
    order_history: any[] = [];
    curren_selected_tab: number = 1;
    private user_id: string = '';
    private server_token: string = '';
    ORDER_STATE:any;
    ORDER_STATUS_ID: any;
    status:any = status;
    is_filter: boolean = false;
    start_date: any = '';
    end_date: any = '';

    date_error: number = 0;

    constructor(  public bln: BooleanService, public user_helper: Helper ) { }

    ngOnInit() {

        this.bln.showLogin = false;
        this.bln.showhedmid = true;
        this.bln.showcart = true;
        this.bln.isSticky = false;
        this.bln.showLink = false;
        this.bln.isHome = true;
        this.bln.cart = true;
        this.bln.showSignup = true;
        this.bln.isAdd_product = true;
        this.bln.isShop = true;
        this.bln.isInner_page = true;
        this.bln.isGreen = true;
        this.bln.isBod_btm_h = false;
        this.bln.isOrd_b = true;
        this.ORDER_STATE=this.user_helper.ORDER_STATE;
        this.ORDER_STATUS_ID = this.user_helper.ORDER_STATUS_ID;
        this.bln.address = true;
        this.bln.force_cart = false;

        let user = JSON.parse(localStorage.getItem('user'));
        if(user && user._id){
            this.user_id = user._id;
            this.server_token = user.server_token;
        } else {
            if(this.user_helper.user_cart.is_main_website){
                this.user_helper.router.navigate(['/home']);
              } else {
                this.user_helper.router.navigate(['']).then(() => {
                    window.location.reload();
                });
              }
        }

        this.get_current_order_list();
        this.history();

    }

    get_current_order_list(){
        this.user_helper.user_cart.myLoading = true;
        this.curren_selected_tab = 1;

        let json = {
            user_id: this.user_id, 
            server_token: this.server_token,
            store_id:this.user_helper.user_cart.website_detail.store_id,
            is_hopper_on_demand: false
        }

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_ORDERS, json, (res_data) => {
            this.user_helper.user_cart.myLoading = false;
            if(res_data.success){
                this.current_order_list = res_data.order_list;
                this.current_order_list.sort(this.sortOrder);
            } else {
                this.current_order_list = [];
            }
        });
    }

    sortOrder(a, b) {
        if (a.unique_id < b.unique_id)
            return 1;
        if (a.unique_id > b.unique_id)
            return -1;
    }

    history_filter(){
        if(this.start_date == '' || this.start_date == undefined){
            this.date_error = 1;
        } else if(this.end_date == '' || this.end_date == undefined){
            this.date_error = 2;
        } else {
            this.date_error = 0;
            this.history();
        }
    }

    history(){
        this.user_helper.user_cart.myLoading = true;
        this.curren_selected_tab = 2;

        let json = {
            store_id:this.user_helper.user_cart.website_detail.store_id,
            user_id: this.user_id,
            server_token: this.server_token, 
            start_date:this.start_date, end_date: this.end_date
        }

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.ORDER_HISTORY, json, (res_data) => {

            this.user_helper.user_cart.myLoading = false;
            if(res_data.success){
                this.order_history = res_data.order_list;
                this.order_history.sort(this.sortOrder);
            } else {
                this.order_history = [];
            }
        });
    }

    get_order_detail(order_id, order_status){
        this.user_helper.router_id.user.order_id = order_id;
        this.user_helper.router.navigate(['delivery-coming']);
        // if(order_status == this.ORDER_STATE.ORDER_COMPLETED){
        //     this.user_helper.router.navigate(['order/invoice']);
        // } else {
        //     this.user_helper.router.navigate(['order_detail']);
        // }
    }

    get_history_detail(order_id, order_status_id){
        // if(order_status_id === this.ORDER_STATUS_ID.COMPLETED){
        this.user_helper.router_id.user.order_id = order_id;
        this.user_helper.router.navigate(['order_detail']);
        // }
    }

}
