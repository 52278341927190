import { Component, OnInit } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel';
import { BooleanService } from '../boolean.service';
import { placeholder, button, language, label, title, menu_title, hopperSlideList, list_info } from '../helper';
import {ViewEncapsulation} from '@angular/core';
import {Helper} from "../user_helper";
declare var google;

@Component({
  selector: 'app-hopper',
  templateUrl: './hopper.component.html',
    providers: [Helper]
})
export class HopperComponent implements OnInit {

   public button:any = button;
  public language:any = language;
  public menu_title:any = menu_title;
  public label:any = label;
  public title:any = title;
  public placeholder:any = placeholder;
  private hopperSlideList:any = hopperSlideList;
  private list_info:any = list_info;
show_future: boolean = false;
country_list: any[] = [];

  
   name: string;
   snames:string;
    password: string;
    address:string;
   email: string;
    mobile: number;
    message: boolean = false;
    country_name: string;
    country_phone_code: string = '';

  constructor(public bln: BooleanService, public user_helper: Helper ) {}

  ngOnInit() {

    // var detail = this.user_helper.user_cart.observable.subscribe((data) => {
    //   if (data) {
    //     if(!this.user_helper.user_cart.is_main_website){
    //       this.user_helper.router.navigate(['']).then(() => {
    //           window.location.reload();
    //       });
    //     }
    //     if (detail) {
    //       detail.unsubscribe();
    //     }
    //   }
    // });

  this.bln.showLogin = true;
  this.bln.showhedmid = true;
  this.bln.showcart = true;
  this.bln.isSticky = true;
  this.bln.showLink = false;
  this.bln.isHome = false;
  this.bln.cart = false;
  this.bln.showSignup = false;
  this.bln.isAdd_product = true;
  this.bln.isShop = true;
  this.bln.isInner_page = false;
   this.bln.isGreen = true;
   this.bln.isBod_btm_h = true;
      this.bln.address = true;
      this.bln.force_cart = false;

   this.name = "";
   this.snames = "";
   this.password = "";
   this.email = "";
   this.mobile = null;
   this.address = "";

   let autocompleteElm = <HTMLInputElement>document.getElementById('address123');
      let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {
        strictBounds: false,
            bounds: new google.maps.LatLngBounds(new google.maps.LatLng(46.2043907, 6.143157699999961))
      });
      autocomplete.addListener('place_changed', () => {
        var place = autocomplete.getPlace();
        this.address = place.formatted_address;
      });
      this.country_list = this.user_helper.country_list;
   if(this.country_list.length>0){
      this.country_phone_code = '+41';
      this.country_name = 'Switzerland';
    }
  }

  set_current_location(){
        navigator.geolocation.getCurrentPosition((position) => {
            this.geocoder(position.coords.latitude, position.coords.longitude)
        });
    }

    geocoder(latitude, longitude){
        var initialLocation = new google.maps.LatLng(latitude, longitude);
        var geocoder = new google.maps.Geocoder();

        let request = {latLng: initialLocation};
        geocoder.geocode(request, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                    this.address = results[0].formatted_address;
            }
        });
    }

    select_country(){
        var index = this.country_list.findIndex((x)=>x.country_name == this.country_name);
        this.country_phone_code = this.country_list[index].country_phone_code
    }


  onSubmit(data){
      let json = {
          name: this.name,
          email: this.email,
          password: this.password,
          snames: this.snames,
          mobile: this.mobile,
          address: this.address,
          country_name: this.country_name,
            country_phone_code: this.country_phone_code
      }

      this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.SEND_HOPPER_EMAIL, json, (res_data) => {
          this.show_future = true;
          this.bln.showLogin = true;
  this.bln.showhedmid = true;
  this.bln.showcart = true;
  this.bln.isSticky = false;
  this.bln.showLink = false;
  this.bln.isHome = true;
  this.bln.cart = true;
  this.bln.showSignup = false;
  this.bln.isAdd_product = true;
  this.bln.isShop = true;
  this.bln.isInner_page = true;
  this.bln.isBod_btm_h = true;
  this.bln.force_cart = true;
          this.name = "";
          this.snames = "";
          this.password = "";
          this.email = "";
          this.mobile = null;
          this.address = "";
          this.message = true;
          setTimeout(function(){
            this.message = false;
          },5000)
      });
  }

  redirect(){
    document.querySelector('#l_section4').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
