import { Component, OnInit } from '@angular/core';
import { OwlModule } from 'ngx-owl-carousel';
import { BooleanService } from '../boolean.service';
import { placeholder, button, label, title, service, description, slide_conList, partnerList} from '../helper';
import {ViewEncapsulation} from '@angular/core';
import {Helper} from "../user_helper";
import {Response} from "@angular/http";
import { map } from "rxjs/operators";
declare var google;

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  encapsulation: ViewEncapsulation.None,
    providers: [Helper]

})
export class PartnerComponent implements OnInit {

  public button:any = button;
  public label:any = label;
  public title:any = title;
  public placeholder:any = placeholder;
  public service:any = service;
  public description:any = description;
  private slide_conList:any = slide_conList;
  private partnerList:any = partnerList;
    deliveries: any[] = [];
   name: string;
   snames:string;
    password: string;
    address:string;
   email: string;
    mobile: number;
    country_name: string;
    country_phone_code: string = '';
    delivery_type: string = '';
    companyName: string = '';
    postal_code: string = '';
    message: boolean = false;
    country_list: any[] = [];

    show_future: boolean = false;

constructor( public bln: BooleanService , public user_helper: Helper ) { }



  ngOnInit() {

    // if(!this.user_helper.user_cart.is_main_website){
    //   this.user_helper.router.navigate(['']).then(() => {
    //       window.location.reload();
    //   });
    // }


  this.bln.showLogin = true;
  this.bln.showhedmid = true;
  this.bln.showcart = true;
  this.bln.isSticky = true;
  this.bln.showLink = false;
  this.bln.isHome = false;
  this.bln.cart = false;
  this.bln.showSignup = false;
  this.bln.isAdd_product = true;
  this.bln.isShop = true;
  this.bln.isInner_page = false;
  this.bln.isGreen = true;
  this.bln.isBod_btm_h = true;
      this.bln.address = true;

   this.name = "";
   this.snames = "";
   this.password = "";
   this.email = "";
   this.mobile = null;
   this.address = "";
   this.country_phone_code = '';
   this.country_name = '';
   this.user_helper.http.get('/admin/delivery_list').pipe(map((res)=>res.json())).subscribe((res_data) => {
          // this.deliveries = res_data.deliveries;
          res_data.deliveries.forEach((delivery)=>{
            if(delivery.is_business){
              this.deliveries.push(delivery);
            }
          })
      });

   let autocompleteElm = <HTMLInputElement>document.getElementById('address123');
      let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {
        strictBounds: false,
            bounds: new google.maps.LatLngBounds(new google.maps.LatLng(46.2043907, 6.143157699999961))
      });
      autocomplete.addListener('place_changed', () => {
        var place = autocomplete.getPlace();
        this.address = place.formatted_address;
      });

   this.country_list = this.user_helper.country_list;
   if(this.country_list.length>0){
      this.country_phone_code = '+41';
      this.country_name = 'Switzerland';
    }

  }

  select_country(){
        var index = this.country_list.findIndex((x)=>x.country_name == this.country_name);
        this.country_phone_code = this.country_list[index].country_phone_code
    }

    set_current_location(){
        navigator.geolocation.getCurrentPosition((position) => {
            this.geocoder(position.coords.latitude, position.coords.longitude)
        });
    }

    geocoder(latitude, longitude){
        var initialLocation = new google.maps.LatLng(latitude, longitude);
        var geocoder = new google.maps.Geocoder();

        let request = {latLng: initialLocation};
        geocoder.geocode(request, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                    this.address = results[0].formatted_address;
            }
        });
    }

  onSubmit(data){


        let json = {
            name: this.name,
            email: this.email,
            password: this.password,
            snames: this.snames,
            mobile: this.mobile,
            address: this.address,
            delivery_type: this.delivery_type,
            companyName: this.companyName,
            postal_code: this.postal_code,
            country_name: this.country_name,
            country_phone_code: this.country_phone_code
        }

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.SEND_PARTNER_EMAIL, json, (res_data) => {
             this.message = true;
             this.show_future = true;
             setTimeout(function(){
               this.message = false;
             },5000)
            this.name = "";
            this.snames = "";
            this.password = "";
            this.email = "";
            this.mobile = null;
            this.address = "";
            this.delivery_type = '';
            this.companyName = '';  
            this.postal_code = '';
        });
  }

  redirect(){
    document.querySelector('#l_section4_in').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

}
