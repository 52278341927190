import { Component, OnInit, OnDestroy,HostListener } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {placeholder, option, title, label} from '../helper';
import {ViewEncapsulation} from '@angular/core';
import {Helper} from '../user_helper';
import {Location} from "@angular/common";
declare var $:any;
import * as moment from 'moment-timezone';
import {Params} from '@angular/router';

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [Helper]
})

export class OrderComponent implements OnInit, OnDestroy {

    public placeholder:any = placeholder;
    public option:any = option;
    public current_location: any;
    public selected_tag: any[] =[];
    deliveries_in_city: any[] = [];
    delivery_type_id: any = '';
    public city_id : Object;
    public current_delivery_type : any;
    public store_list : any[] = [];
    public filtered_store_list : any[] = [];
    public city_name: string = '';
    public filter_store_name: string = '';
    public delivery_currency :string = '';
    public cart_unique_token: string = '';
    public user_id: string = '';
    public server_token: string = '';
    public favourite_stores: any[] = [];
    public user_profile: string = '';
    public cart_data : any = {};
    is_show: boolean = false;
    public title:any = title;
    public label:any = label;
    store_interval: any;
    page: number = 1;
    delivery_time:any[] = [];
    is_delivery_available:boolean=true;
    is_emergency:boolean = false;
    emergency_close_till:string='';

    constructor(private location: Location, public bln: BooleanService, public user_helper: Helper) { }

    @HostListener('window:popstate', ['$event'])
    onBrowserBackBtnClose(event: Event) {
        window.scroll(0,0);
    }

    ngOnDestroy(){
        this.user_helper.clear_height_width();
    }

    ngOnInit() {
        // if(this.user_helper.user_cart.is_main_website && localStorage.getItem('current_location')){
            this.user_helper.get_cart();
            
            this.bln.showLogin = true;
            this.bln.showhedmid = true;
            this.bln.showcart = false;
            this.bln.isSticky = false;
            this.bln.showLink = false;
            this.bln.isHome = true;
            this.bln.cart = true;
            this.bln.showSignup = false;
            this.bln.isAdd_product = true;
            this.bln.isShop = true;
            this.bln.isInner_page = true;
            this.bln.isGreen = true;
            this.bln.isBod_btm_h = false;
            this.bln.address = true;

            let user = JSON.parse(localStorage.getItem('user'));
            if(user && user._id){
                this.user_id = user._id;
                this.server_token = user.server_token;
                this.user_profile = user.image_url;
                this.favourite_stores = user.favourite_stores;
            }
            this.cart_unique_token = localStorage.getItem('cart_unique_token');

            let current_location;
            if(localStorage.getItem('current_location')){
                current_location = JSON.parse(localStorage.getItem('current_location'));
            } else {
                current_location = {
                  address: "",
                  city1: "Geneva",
                  city2: "Geneva",
                  city3: "Geneva",
                  city_code: "GE",
                  country: "Switzerland",
                  country_code: "CH",
                  country_code_2: "CH",
                  latitude: 46.2043907,
                  longitude: 6.143157699999961,
                  postal_code: '',
                  state: ''
                }
            }
            current_location.cart_unique_token = this.cart_unique_token;
            current_location.user_id = this.user_id;
            current_location.server_token = this.server_token;

            

            // this.delivery_type_id = this.user_helper.router_id.user.delivery_type_id;
            this.deliveries_in_city = this.user_helper.router_id.deliveries_in_city;
            this.current_delivery_type = this.user_helper.router_id.user_current_delivery_type;
            this.delivery_type_id = (this.current_delivery_type&&this.current_delivery_type._id)? this.current_delivery_type._id : this.user_helper.router_id.user.delivery_type_id;

            if(!this.deliveries_in_city || this.deliveries_in_city.length==0){
                this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_DELIVERY_LIST_FOR_NEAREST_CITY, current_location, (res_data) => {

                    if (res_data.success) {
                        this.deliveries_in_city = res_data.deliveries;

                        var index = this.deliveries_in_city.findIndex((x)=>x._id==this.delivery_type_id);
                        if(index == -1){
                            index = 0;
                        }
                        this.current_delivery_type = this.deliveries_in_city[index];

                    } else {

                    }

                })
            }


            this.selected_tag = [];

            

            this.city_id = this.user_helper.router_id.user.city_id;
            this.current_location = JSON.parse(JSON.stringify(current_location));
            this.city_name = current_location.city1;
            this.delivery_currency = this.user_helper.router_id.user.delivery_currency;

            
            this.cart_data = this.user_helper.user_cart.cart_data;

            if(this.delivery_type_id == ''){
                this.user_helper.route.params.subscribe((x: Params) => {
                    if(x['delivery_name']){
                        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_DELIVERY_LIST_FOR_NEAREST_CITY, current_location, (res_data) => {

                            if (res_data.success && res_data.deliveries.length>0) {
                                this.deliveries_in_city = res_data.deliveries;
                                var delivery_name = x['delivery_name'].replace(/_/g, " ");
                                var index = this.deliveries_in_city.findIndex((x1)=>x1.delivery_name==delivery_name);
                                if(index == -1){
                                    index = 0;
                                }
                                this.current_delivery_type = this.deliveries_in_city[index];
                                this.delivery_type_id = this.deliveries_in_city[index]._id;

                                this.current_delivery_type = this.deliveries_in_city[index];
                                this.city_id = res_data.city._id;
                                this.user_helper.router_id.user.delivery_currency = res_data.currency_sign;
                                this.user_helper.user_cart.timezone = res_data.city.timezone;
                                this.user_helper.user_cart.server_date = res_data.server_time;
                                if(x['tags']){
                                    var tags = x['tags'].replace(/_/g, " ");
                                    tags = tags.split(',');
                                    this.selected_tag = tags;
                                    this.filter_store();
                                }

                                this.get_store_list()
                            } else {
                                if(this.user_helper.user_cart.is_main_website){
                                    this.user_helper.router.navigate(['/home']);
                                } else {
                                    this.user_helper.router.navigate(['']).then(() => {
                                        window.location.reload();
                                    });
                                }
                            }

                        })
                    } else {
                        if(this.user_helper.user_cart.is_main_website){
                            this.user_helper.router.navigate(['/home']);
                        } else {
                            this.user_helper.router.navigate(['']).then(() => {
                                window.location.reload();
                            });
                        }
                    }
                });
                
            } else {
                this.get_store_list()
                this.user_helper.route.params.subscribe((x: Params) => {
                    if(x['tags']){
                        var tags = x['tags'].replace(/_/g, " ");
                        tags = tags.split(',');
                        this.selected_tag = tags;
                        this.filter_store();
                    }
                });
            }

            $(document).ready(function(){
                $('.food_list .btn').click(function(e){
                  e.preventDefault();
                  var x = $(this).text();
                  $('.con_name').empty().append(x);

                })
                $('.sort').click();
            });
                
        // } else {
            // this.user_helper.router.navigate(['']).then(() => {
            //     window.location.reload();
            // });
        // }

    }

    hide_filter(){
        $('.food_list').slideToggle();
        this.is_show = !this.is_show;
    }

    range(number){
        let a = [];
        for(let i = 0; i < number; ++i)
        {
            a.push(i+1)
        }
        return a;
    }

    select_famous_tag(tag){
        var index = this.selected_tag.indexOf(tag);
        if(index == -1){
            this.selected_tag.push(tag)
        } else {
            this.selected_tag.splice(index, 1);
        }
        var delivery_name = this.current_delivery_type.delivery_name.replace(/ /g, "_");
        this.location.replaceState('stores/' + delivery_name + '/' +this.selected_tag.toString().replace(/ /g, "_"));
        this.filter_store();
    }

    clear_filter(){
        var delivery_name = this.current_delivery_type.delivery_name.replace(/ /g, "_");
        this.selected_tag = [];
        this.location.replaceState('stores/' + delivery_name);
        this.filter_store_name = '';
        this.filter_store();
    }

    clear_all(){
        this.selected_tag = [];
        this.filter_store();
    }

    get_delivery_type_name(){
        let index = this.deliveries_in_city.findIndex((x)=>x._id == this.delivery_type_id);
        if(index != -1){
            return this.deliveries_in_city[index].delivery_name;
        } else {
            return 'Select Category'
        }
    }

    toggle_dropdown(){
        $('.user_dt1').toggle();
    }

    change_delivery_type(delivery_type_id){
        this.delivery_type_id = delivery_type_id;
        let index = this.deliveries_in_city.findIndex((x)=>x._id == this.delivery_type_id);
        this.current_delivery_type = this.deliveries_in_city[index];
        this.user_helper.router_id.user_current_delivery_type = this.deliveries_in_city[index];
        this.selected_tag = [];
        this.get_store_list();
        this.user_helper.router_id.user.delivery_type_id = this.deliveries_in_city[index]._id;
        let delivery_name = this.deliveries_in_city[index].delivery_name.replace(/ /g, "_");
        this.location.replaceState('stores/' + delivery_name);
    }

    onScroll() {
        console.log('scrolled!!');
      }

    get_store_list(){
        let json = {
            city_id: this.city_id ,
            store_delivery_id: this.delivery_type_id,
            user_id: this.user_id,
            server_token: this.server_token,
            cart_unique_token: this.cart_unique_token,
            latitude: this.current_location.latitude,
            longitude: this.current_location.longitude
        }

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_STORE_LIST, json, (res_data) => {
            this.user_helper.myLoading = false;
            if(res_data.success){
                this.store_list = res_data.stores;
                this.filtered_store_list = res_data.stores;
                setTimeout(()=>{
                  this.user_helper.set_dynamic_height_width();
                },200)
                this.city_name = res_data.city_name;
                this.delivery_time = res_data.delivery_time;
                this.is_emergency = res_data.is_emergency
                this.emergency_close_till = res_data.emergency_close_till
                this.check_delivery_open(res_data.server_time, res_data.timezone);
                this.check_open(res_data.server_time, res_data.timezone);
                if(this.store_interval){
                    clearInterval(this.store_interval);
                }
                this.store_interval = setInterval(()=>{
                    this.check_open(res_data.server_time, res_data.timezone);
                }, 300000);
            } else {
                this.store_list = [];
                this.filtered_store_list = [];
                this.user_helper.data.storage = {
                    "code": res_data.error_code,
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message();
                this.delivery_time = [];
            }
        });
    }

    check_delivery_open(server_date, timezone){
        let date:any = server_date;
        date = new Date(date).toLocaleString("en-US", {timeZone: timezone})
        date = new Date(date);
        let weekday = date.getDay();
        let current_time = date.getTime();
        this.is_delivery_available = false;

        let week_index = this.delivery_time.findIndex((x) => x.day == weekday)
        if(this.delivery_time[week_index].is_delivery_open){
            let day_time = this.delivery_time[week_index].day_time;
            if(day_time.length == 0){
                this.is_delivery_available = false;
            } else {
                day_time.forEach((store_time , index) =>{
                    let open_time = store_time.delivery_open_time;
                    open_time = open_time.split(':')
                    let open_date_time = date.setHours(open_time[0] , open_time[1], 0 ,0)
                    open_date_time = new Date(open_date_time);
                    open_date_time = open_date_time.getTime();

                    let close_time = store_time.delivery_close_time;
                    close_time = close_time.split(':')
                    let close_date_time = date.setHours(close_time[0] , close_time[1], 0 ,0)
                    close_date_time = new Date(close_date_time);
                    close_date_time = close_date_time.getTime();

                    if(current_time > open_date_time && current_time < close_date_time){
                        this.is_delivery_available = true;
                    }
                });
            }
        } else {
            this.is_delivery_available = false;
        }
    }

    check_open(server_date, timezone){
        let date:any = server_date;
        date = new Date(date).toLocaleString("en-US", {timeZone: timezone})
        date = new Date(date);
        let weekday = date.getDay();
        let current_time = date.getTime();

        this.filtered_store_list.forEach((store) =>{
            var index = this.favourite_stores.findIndex((x)=> x == store._id);
            if(index == -1){
                store.favourite = false;
            } else {
                store.favourite = true;
            }
            // store.distance = this.get_distance_two_location(store.location);
            store.close = true;
            if(!store.is_top){
                store.is_top = false;
            }
            store.nextopentime = '';
            store.close_date_time = 0;

            let week_index = store.store_time.findIndex((x) => x.day == weekday)
            if(store.store_time[week_index].is_store_open){
                let day_time = store.store_time[week_index].day_time;
                day_time = day_time.filter((x)=>{
                    if(x.is_on==true || x.is_on==undefined){
                        return x;
                    }
                })
                // if(store.store_time[week_index].is_store_open_full_time){
                //     store.close = false;
                // }
                // else {
                
                    if(day_time.length == 0){
                        store.close = true;
                        store.nextopentime = '';
                        store.close_date_time = Number.MAX_SAFE_INTEGER;
                        this.check_next_open_time(server_date, timezone, store, week_index, 0, null, 0);
                    } else {
                        // console.log(day_time)
                        day_time.forEach((store_time , index) =>{
                            let open_time = store_time.store_open_time;
                            open_time = open_time.split(':')
                            let open_date_time = date.setHours(open_time[0] , open_time[1], 0 ,0)
                            open_date_time = new Date(open_date_time);
                            open_date_time = open_date_time.getTime();

                            let close_time = store_time.store_close_time;
                            close_time = close_time.split(':')
                            let close_date_time = date.setHours(close_time[0] , close_time[1], 0 ,0)
                            close_date_time = new Date(close_date_time);
                            close_date_time = close_date_time.getTime();

                            if(current_time > open_date_time && current_time < close_date_time){
                                store.close = false;
                            }

                            if(current_time < open_date_time && store.nextopentime == ''){
                                store.nextopentime = store_time.store_open_time;
                                var time = store_time.store_open_time.split(':')
                                store.close_date_time = JSON.parse(JSON.stringify(new Date(date.setHours(open_time[0] , open_time[1], 0 ,0)).getTime()));
                                // console.log('open_date_time   111: '+ store.name + ' ' + store.close_date_time)
                            }
                        });

                        if(store.close && !store.nextopentime){
                            this.check_next_open_time(server_date, timezone, store, week_index, 0, null, 0);
                        }
                    }
                
                // }
            } else {
                store.close = true;
                store.nextopentime = '';
                store.close_date_time = Number.MAX_SAFE_INTEGER;
                this.check_next_open_time(server_date, timezone, store, week_index, 0, null, 0)
            }

            if(!store.close){
                if(!store.is_stop_new_order_all_day){
                    if(store.close_till){
                        let close_till = store.close_till;
                        close_till = close_till.split(':')
                        let open_date_time = date.setHours(close_till[0] , close_till[1], 0 ,0)
                        open_date_time = new Date(open_date_time);
                        open_date_time = open_date_time.getTime();
                        if(current_time < open_date_time){
                            store.close = true;
                            store.nextopentime = store.close_till;
                            let close_time = store.close_till;
                            close_time = close_time.split(':')
                            let close_date_time = date.setHours(close_time[0] , close_time[1], 0 ,0)
                            close_date_time = new Date(close_date_time);
                            close_date_time = close_date_time.getTime();
                            store.close_date_time = close_date_time;
                            if(store.store_order[0]==1){
                                store.is_delivery_possible = true;
                            } else if(store.store_order[0]==2){
                                store.is_pickup_possible = true;
                            }
                        }
                    }
                } else {
                    store.close = true;
                    store.nextopentime = '';
                    store.close_date_time = Number.MAX_SAFE_INTEGER;
                    if(store.store_order[0]==1){
                        store.is_delivery_possible = true;
                    } else if(store.store_order[0]==2){
                        store.is_pickup_possible = true;
                    }
                }
            }

            if(!store.close && !this.user_helper.user_cart.ipickup_delivery && this.is_emergency && this.emergency_close_till){

              var close_till_time = 0;
              let close_till: any = new Date(this.emergency_close_till);
              close_till = new Date(close_till).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
              close_till = new Date(close_till);
              if(this.user_helper.user_cart.schedule_date){
                let selected_date: any = new Date(this.user_helper.user_cart.schedule_date);
                selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
                selected_date = new Date(selected_date);

                close_till_time = Math.round(close_till.getTime() - selected_date.getTime());
              }

              // if(close_till_time > 0 || !this.user_helper.user_cart.schedule_date){
                store.close = true;
                store['is_delivery_possible'] = false;
                store['is_pickup_possible'] = true;
                store['is_delivery_stop_admin'] = true;
                store.nextopentime = close_till.getHours()+':'+close_till.getMinutes();
              // }
            }

            if(store.store_conges && store.store_conges.length>0){
                this.check_conges(store, server_date, timezone)
            }
            if(!store.close){
                store.close_date_time = 0;
            }

            let delivery_name = this.current_delivery_type.delivery_name.replace(/ /g, "-");
            delivery_name = delivery_name.replace(/(|)/g, "");
            delivery_name = delivery_name.toLowerCase();
            delivery_name = delivery_name.replace(/[^a-zA-Z ]/g, "")
            let city_name = this.city_name.replace(/ /g, "-");
            city_name = city_name.toLowerCase();
            let store_name = store.name.replace(/ /g, "-");
            store_name = store_name.toLowerCase();
            store_name = store_name.replace(/(|)/g, "");
            store_name = store_name.replace(/[^a-zA-Z ]/g, "")

            var url = '/' + city_name + '/' + delivery_name + '/' + store_name + '/' + store._id;
            store.page_url = url;

        });


        
        
        this.filter_store();
    }

    check_conges(store, server_date, timezone){
        let date:any = server_date;
        date = new Date(date).toLocaleString("en-US", {timeZone: timezone})
        date = new Date(date);
        var current_time = date.getTime();
        store.store_conges.forEach((store_conges_detail)=>{
            var start_time:any = new Date(store_conges_detail.start_time).toLocaleString("en-US", {timeZone: timezone})
            start_time = new Date(start_time);
            var end_time:any = new Date(store_conges_detail.end_time).toLocaleString("en-US", {timeZone: timezone})
            end_time = new Date(end_time);
            if(start_time.getTime()<=current_time && end_time.getTime()>current_time){
                store.close = true;
                store.conges = true;
                var close_date_time = end_time.getTime();
                store.close_date_time = close_date_time;

                const diffTime = Math.abs(end_time.getTime() - date.getTime());
                const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

                store.conges = false;
                let weekday = end_time.getDay();
                store.nextopentime = '';
                store.close_date_time = Number.MAX_SAFE_INTEGER;
                this.check_next_open_time(server_date, timezone, store, weekday-1, diffDays-1, close_date_time, 0)
                    
            }
        })
    }

    check_next_open_time(server_date, timezone, store, week_index, count, end_time, loop_count){
        count++;
        let date:any = JSON.parse(JSON.stringify(server_date));
        date = new Date(date).toLocaleString("en-US", {timeZone: timezone})
        date = new Date(date);
        let weekday = date.getDay();
        let current_time = date.getTime();

        var days = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
        var date_time = JSON.parse(JSON.stringify(server_date));
        date_time = new Date(date_time);
        if(week_index == 6){
            week_index = 0;
        } else {
            week_index++;
        }
        date_time = date_time.setDate(date_time.getDate() + count);
        date_time = new Date(date_time);
        if(loop_count !== 7){
            loop_count++;
            let day_time = store.store_time[week_index].day_time;
            day_time = day_time.filter((x)=>{
                if(x.is_on==true || x.is_on==undefined){
                    return x;
                }
            })
            
            if(store.store_time[week_index].is_store_open){
                if(day_time.length == 0){
                    this.check_next_open_time(server_date, timezone, store, week_index, count, end_time, loop_count);
                } else {
                    day_time.forEach((store_time , index) =>{

                        let open_time = store_time.store_open_time;
                        open_time = open_time.split(':')
                        let open_date_time = date_time.setHours(open_time[0] , open_time[1], 0 ,0)
                        open_date_time = new Date(open_date_time);
                        open_date_time = open_date_time.getTime();

                        let close_time = store_time.store_close_time;
                        close_time = close_time.split(':')
                        let close_date_time = date_time.setHours(close_time[0] , close_time[1], 0 ,0)
                        close_date_time = new Date(close_date_time);
                        close_date_time = close_date_time.getTime();

                        if(!end_time || close_date_time >= end_time){
                            if(current_time < open_date_time && store.nextopentime == ''){
                                if(!end_time || open_date_time >= end_time){
                                    const diffTime = Math.abs(open_date_time - current_time);
                                    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                                    if(diffDays<7){
                                        var date = new Date(date_time.setHours(open_time[0] , open_time[1], 0 ,0))
                                        store.nextopentime = days[date.getDay()] + ' à ' + store_time.store_open_time;
                                    } else {
                                        open_date_time = new Date(open_date_time)
                                        store.nextopentime = open_date_time.getDate()+'/' + (open_date_time.getMonth()+1) + ' à ' + store_time.store_open_time;
                                    }
                                    if(end_time){
                                        store.conges = true;
                                        store.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+store.nextopentime
                                    }
                                    store.close_date_time = JSON.parse(JSON.stringify(new Date(date_time.setHours(open_time[0] , open_time[1], 0 ,0)).getTime()));
                                } else {
                                    const diffTime = Math.abs(end_time - current_time);
                                    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                                    var end_time_date = new Date(end_time);
                                    var hours = ("0" + end_time_date.getHours()).slice(-2);
                                    var minutes = ("0" + end_time_date.getMinutes()).slice(-2);
                                    if(diffDays<7){
                                        var date = new Date(date_time.setHours(end_time_date.getHours() , end_time_date.getMinutes(), 0 ,0))
                                        store.nextopentime = days[date.getDay()] + ' à ' + hours +':'+ minutes;
                                    } else {
                                        store.nextopentime = end_time_date.getDate()+'/' + (end_time_date.getMonth()+1) + ' à ' + hours +':'+ minutes;
                                    }
                                    store.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+store.nextopentime
                                    store.conges = true;
                                    store.close_date_time = JSON.parse(JSON.stringify(new Date(date_time.setHours(end_time_date.getHours() , end_time_date.getMinutes(), 0 ,0)).getTime()));
                                }
                            }
                        }
                    });

                    if(!store.nextopentime){
                        this.check_next_open_time(server_date, timezone, store, week_index, count, end_time, loop_count);
                    }
                }
            } else {
                this.check_next_open_time(server_date, timezone, store, week_index, count, end_time, loop_count)
            }
        }
    }

    get_item_list(store){
        this.user_helper.router_id.user_current_store = store;
        this.user_helper.router_id.user.store_id = store._id;
        // let delivery_name = this.current_delivery_type.delivery_name.replace(/ /g, "-");
        // delivery_name = delivery_name.replace(/(|)/g, "");
        // delivery_name = delivery_name.toLowerCase();
        // delivery_name = delivery_name.replace(/[^a-zA-Z ]/g, "")
        // let city_name = this.city_name.replace(/ /g, "-");
        // city_name = city_name.toLowerCase();
        // let store_name = store.name.replace(/ /g, "-");
        // store_name = store_name.toLowerCase();
        // store_name = store_name.replace(/(|)/g, "");
        // store_name = store_name.replace(/[^a-zA-Z ]/g, "")

        this.user_helper.router_id.load_item_page = true;
        // if(this.user_helper.user_cart.is_main_website){
        //     this.user_helper.router.navigate([city_name + '/' + delivery_name + '/' + store_name + '/' + store._id]).then(() => {
        //         window.location.reload();
        //     });
        // } else {
        //     this.user_helper.router.navigate(['']).then(() => {
        //         window.location.reload();
        //     });
        // }
    }

    filter_store()
    {
        let filtered_store_tag_wise = [];
        if(this.selected_tag.length > 0){
            filtered_store_tag_wise = this.store_list.filter((store) => {
                var bool = false;
                this.selected_tag.forEach((tag) => {
                    var index = store.famous_products_tags.indexOf(tag);
                    if(index !== -1){
                        bool = true;
                    }
                })
                if(bool){
                    return store
                }
            });
        } else {
            filtered_store_tag_wise = this.store_list;
        }
        
        let data: any = this.filter_store_name.replace(/^\s+|\s+$/g, '');
        data = data.replace(/ +(?= )/g, '');
        data = data.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
        data = data.replace(/ /g, "");
        data = data.replace(/(|)/g, "");
        data = data.replace(/[^a-zA-Z ]/g, "");
        data = new RegExp(data,"gi");

        this.filtered_store_list = filtered_store_tag_wise.filter((store) => {
            var name = store.name.replace(/^\s+|\s+$/g, '');
            name = name.replace(/ +(?= )/g, '');
            name = name.normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            name = name.replace(/ /g, "");
            name = name.replace(/(|)/g, "");
            name = name.replace(/[^a-zA-Z ]/g, "");
            var a = name.match(data);
            return a !== null
        });
        this.filtered_store_list.sort(this.sortItem2);
        this.filtered_store_list.sort(this.sortItem);
        this.filtered_store_list.sort(this.sortItem1);
        $('html, body').animate({scrollTop: ($(window).scrollTop() + 1) + 'px'}, 300);
    }

    sortItem(a,b) {
        return (a.close === b.close)? 0 : a.close? 1 : -1;
    }
    sortItem1(a,b) {
        return ((a.is_top === b.is_top))? 0 : ((a.is_top && !a.close)? -1 : 1);
    }
    sortItem2(a,b) {
        if (a.close_date_time < b.close_date_time)
            return -1;
        if (a.close_date_time > b.close_date_time)
            return 1;
        return 0;

        // return (a.close_date_time < b.close_date_time) ? -1 : ((a.close_date_time > b.close_date_time) ? 1 : 0);

    }

}

