import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {title, teamList} from '../helper';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html'
})
export class TeamComponent implements OnInit {

  public title:any = title;
  public teamList:any = teamList;

  constructor(public bln: BooleanService ) { }

  ngOnInit() {

  this.bln.showLogin = true;
  this.bln.showhedmid = true;
  this.bln.showcart = true;
  this.bln.isSticky = false;
  this.bln.showLink = false;
  this.bln.isHome = true;
  this.bln.cart = true;
  this.bln.showSignup = false;
  this.bln.isAdd_product = true;
  this.bln.isShop = true;
  this.bln.isInner_page = true;
  this.bln.isGreen = true;
  this.bln.isBod_btm_h = true;
  this.bln.force_cart = false;
  }

}
