import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {title, faqList, faqList2, description, teamList} from '../helper';
import {Helper} from "../user_helper";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

   public title:any = title;
   public faqList:any = faqList;
   public faqList2:any = faqList2;
   public description:any = description;

   public teamList:any = teamList;
  constructor(public user_helper: Helper, public bln: BooleanService) { }

  ngOnInit() {

    // if(!this.user_helper.user_cart.is_main_website){
    //   this.user_helper.router.navigate(['']).then(() => {
    //       window.location.reload();
    //   });
    // }


  this.bln.showLogin = true;
  this.bln.showhedmid = true;
  this.bln.showcart = true;
  this.bln.isSticky = true;
  this.bln.showLink = false;
  this.bln.isHome = false;
  this.bln.cart = false;
  this.bln.showSignup = false;
  this.bln.isAdd_product = true;
  this.bln.isShop = true;
  this.bln.isInner_page = false;
   this.bln.isGreen = true;
   this.bln.isBod_btm_h = true;
      this.bln.address = true;
      this.bln.force_cart = false;
  }

}
