import { Component, OnInit, HostListener, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {button, language, menu_title, label, title, placeholder, description, ordList, price} from '../helper';
import { NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {ViewEncapsulation} from '@angular/core';
import {Params} from '@angular/router';
import {Location} from '@angular/common';
import {Helper} from "../user_helper";
declare var google;
import * as moment from 'moment-timezone';

export interface CartProductItems{
    item_id: Object,
    unique_id: Number,
    item_name: string,
    quantity: Number,
    image_url: any,
    details: string,
    specifications: any[],
    formule_list: any[],
    item_price: Number,
    offer_price: Number,
    is_buy_one_get_one: boolean,
    total_specification_price: Number,
    total_item_and_specification_price: Number,
    note_for_item: string
}

export interface cartProducts{
    items: any[],
    product_id: Object,
    product_name: string,
    unique_id: number,
    total_item_price: number
}

declare var $:any;

@Component({
  selector: 'app-shop',
  templateUrl: './shop.component.html',
  encapsulation: ViewEncapsulation.None,
    providers: [Helper]
 
})
export class ShopComponent implements OnInit, OnDestroy {
  
  closeResult: string;
  public button:any = button;
  public language:any = language;
  public menu_title:any = menu_title;
  public label:any = label;
  public title:any = title;
  public placeholder:any = placeholder;
    public description:any = description;
    private ordList:any = ordList;
    public price:any = price;

    private city_name: string = '';
    public current_store : any;
    private current_location : any;
    public store_id : string = "";
    public filtered_product_item_list: any[] = [];
    private product_item_list: any[] = [];
    current_item: any = {image_url:[]};
    current_formule:any = {};
    current_main_item: any = {};
    required_count: number = 0;
    private total: number = 0;
    required_temp_count: number = 0;
    delivery_currency : string = '';
    qty: number = 1;
    private cart_unique_token: string = '';
    public user_id: string = '';
    private server_token: string = '';
    public user_profile: string = '';
    note_for_item: string = '';
    product_name: string = '';
    product_unique_id: number = 0;
    private cart_data : any = {};
    selected_product_id: Object = null;

    filter_item_name: string = '';

    start_screex: number = 0;
    end_screex: number = 0;

    private cartProductItems : CartProductItems;
    private cartProducts : cartProducts;
    public device_type : string = "web";

    selected_item_index: number = 0;
    selected_product_index: number = 0;
    private favourite_stores: any[] = [];

  public counter:number = 0;
  is_show_time: boolean = true;
  is_open_item_modal : boolean = false;

  private store_location : any[];
  private delivery_location : any[];
  public delivery_address : string = '';
  floor: any = '';
  entry_code: any = '';
  delivery_note: string = '';
  use_an_address: boolean = false;
  favourite_addresses: any[] = [];
  selected_address_id: string = '';

  is_schedule_order: Boolean = false;
  schedule_date: any = '';
  schedule_time: string = '';
  schedule_time_error: Boolean = false;
  store_open_day: string = '';
  clicked_date: any = null;
  server_date: any = null;
  date_array: any[] = [];
  time_array: any[] = [];
  is_loaded = true;
  // ipickup_delivery: boolean = false;
  selected_product_name: string = 'Select Product';
  type:number=1;
  is_visible_in_store: boolean = false;

  cart_offer_message: string = '';
  cart_offer_value: number = 0;
  cart_offer_error: string = '';
  cart_offer_error2: string = '';
  cart_offer_progress_bar: number = -1;
  delivery_offers: any[] = [];

  delivery_time:any[] = [];
  is_delivery_available:boolean=true;
  is_emergency:boolean = false;
  emergency_close_till:string='';
  @ViewChild('is_delivery_stop_admin') is_delivery_stop_admin!: TemplateRef<any>;


  change_pickup(){
    if(this.user_helper.user_cart.ipickup_delivery){
      this.user_helper.user_cart.ipickup_delivery = false;
    } else {
      this.user_helper.user_cart.ipickup_delivery = true;
    }
  }

  remove_from_cart(product_index, item_index){
    this.is_loaded = !this.is_loaded;
    this.user_helper.remove_from_cart(product_index, item_index)
    this.check_cart_offer();
  }
  
  cart_decrease_qty(product_index, item_index){
    this.is_loaded = !this.is_loaded;
    this.user_helper.decrease_qty(product_index, item_index)
    this.check_cart_offer();
  }
  
  cart_increase_qty(product_index, item_index){
    this.is_loaded = !this.is_loaded;
    this.user_helper.increase_qty(product_index, item_index)
    this.check_cart_offer();
  }

  increment(){
      this.counter += 1;
    }
    
    decrement(){
      if(this.counter > 0){
      this.counter -= 1;
      }
    }

  constructor( public bln: BooleanService, private location: Location, private modalService: NgbModal , public user_helper: Helper) { }


  @HostListener('window:popstate', ['$event'])
  onBrowserBackBtnClose(event: Event) {
      event.preventDefault(); 
      if(this.user_helper.user_cart.is_main_website){
        this.user_helper.router.navigate(['stores']);
      }
  }

  ngOnDestroy(){
      this.user_helper.clear_height_width();
  }

  ngAfterViewInit(){
    
  }

  ngOnInit() {
    if(this.user_helper.router_id.load_item_page){
      // location.reload();
    }

    // this.user_helper.get_cart();

    this.current_store = {
          price_rating : 0,
          famous_for: []
      };

      var detail = this.user_helper.user_cart.observable.subscribe((data)=>{

        if(data && !this.user_helper.router_id.load_item_page){
          
          if(detail){
            detail.unsubscribe();
          }

          this.user_helper.route.params.subscribe((x: Params) => {

              if(this.user_helper.user_cart.is_main_website){
                this.store_id = x['store_id'] || "";
                this.city_name = x['city_name'];
              } else {
                this.store_id = this.user_helper.user_cart.website_detail.store_id;
                this.city_name = 'city_name';
              }


              // this.current_location = this.user_helper.router_id.user_current_location;
              if(localStorage.getItem('current_location')){
                this.current_location =  JSON.parse(localStorage.getItem('current_location'));
              } else {
                this.current_location =  {
                    city1: '',
                    city2: '',
                    city3: '',
                    country: '',
                    country_code: '',
                    country_code_2: '',
                    city_code: '',
                    latitude: '',
                    longitude: '',
                    address: ''
                }
              }
              this.delivery_currency = this.user_helper.router_id.user.delivery_currency;
              this.cart_unique_token = localStorage.getItem('cart_unique_token');

              let user = JSON.parse(localStorage.getItem('user'));
              if (user && user._id) {
                  this.user_id = user._id;
                  this.server_token = user.server_token;
                  this.user_profile = user.image_url;
                  this.favourite_stores = user.favourite_stores;
              }

              this.cart_data = this.user_helper.user_cart.cart_data;
              if (this.store_id.length !== 24) {
                  this.user_helper.router.navigate(['deliveries']);
              } else {
                  this.get_item_list()
              }

              this.cartProductItems = {
                  item_id: null,
                  unique_id: 0,
                  item_name: '',
                  quantity: 0,
                  image_url: [],
                  details: '',
                  specifications: [],
                  formule_list: [],
                  item_price: 0,
                  offer_price: 0,
                  is_buy_one_get_one: false,
                  total_specification_price: 0,
                  total_item_and_specification_price: 0,
                  note_for_item: ''
              }
              this.cartProducts = {
                  items: [],
                  product_id: null,
                  product_name: '',
                  unique_id: 0,
                  total_item_price: 0
              }

          });
        }
      })

    this.bln.showLogin = false;
    this.bln.showhedmid = true;
    this.bln.showcart = false;
    this.bln.isSticky = false;
    this.bln.showLink = false;
    this.bln.cart = true;
    this.bln.isHome = true;
    this.bln.showSignup = true;
    this.bln.isAdd_product = false;
    this.bln.isShop = false;
    this.bln.isInner_page = true;
    this.bln.isGreen = true;
    this.bln.isBod_btm_h = true;
      this.bln.address = true;
      this.bln.force_cart = false;

    /* slide */
    $(".box ul li a").click(function(e){
      e.preventDefault();
      var hrefVal = $(this).attr("href");
      var sectionOffset = $(hrefVal).offset().top - 120;
      $('html, body').animate({
        scrollTop: sectionOffset
      }, 500);
    });



       var print = function(msg) {
         alert(msg);
       };

       var setInvisible = function(elem) {
         elem.css('visibility', 'hidden');
       };
       var setVisible = function(elem) {
         elem.css('visibility', 'visible');
       };

       

       var elem = $("#fix_bar");
       var items = elem.children();

       // Inserting Buttons
       // elem.prepend('<div id="right-button" style="visibility: hidden;"><a><img src="../assets/images/arrow.svg" /></a></div>');
       // elem.append('  <div id="left-button"><a><img src="../assets/images/arrow-right.svg" style="height: 20px;" /></a></div>');

       // $('#left-button').css('visibility', 'hidden')

       // Inserting Inner
       // items.wrapAll('<div id="inner" />');

       // Inserting Outer
       // elem.find('#inner').wrap('<div id="outer"/>');

       var outer = $('#outer');

       var updateUI = function() {
         var maxWidth = outer.outerWidth(true);
         var actualWidth = 0;
         $.each($('#inner >'), function(i, item) {
           actualWidth += $(item).outerWidth(true);
         });

         if (actualWidth <= maxWidth) {
           //setVisible($('#left-button'));
         }
       };
       // updateUI();



       $('#right-button').click(function() {
         var leftPos = outer.scrollLeft();
         outer.animate({
           scrollLeft: leftPos - 200
         }, 800, function() {
           if ($('#outer').scrollLeft() <= 0) {
             setInvisible($('#right-button'));
           }
         });
       });

       $('#left-button').click(function() {
         setVisible($('#right-button'));
         var leftPos = outer.scrollLeft();
         outer.animate({
           scrollLeft: leftPos + 200
         }, 800,  function() {
           
         });
       });

       $(window).resize(function() {
         // updateUI();
       });

       





  $(window).resize(function(){
    $.fn.hasScrollBar = function()
              {
                  // return this.get(0).scrollWidth > this.innerWidth();
                

              }
             
                if($('#outer').hasScrollBar()){
                  $('#left-button').css('visibility', 'visible')
                } else {
                  $('#left-button').css('visibility', 'hidden')
                }
              
  })

    let that = this;
    $(window).scroll(function() {
      if ($(this).scrollTop() > 400 && (that.product_item_list.length > 1 || (that.product_item_list[0] && that.product_item_list[0].items.length>2))){  
        $('#fix_bar').addClass('fix_bar')
        
      } else {
        $('#fix_bar').removeClass('fix_bar')
      }

      var height = $('.shop').height() + $('.shop_banner').height() + $('#fix_bar').height();

      if ($(this).scrollTop() > height - 50 && (that.product_item_list.length > 1 || (that.product_item_list[0] && that.product_item_list[0].items.length>2))){  

        // if(($(window).scrollTop() + $(window).height()) >= ($(document).height() - $('footer').height())) {
        //  $('.fix_bar1').css('width', '33.33%')
        //   $('.fix_bar1').css('left', 'unset')
        //   $('#fix_bar1').removeClass('fix_bar1')
        // } else {
          $('#fix_bar1').addClass('fix_bar1');
          var width = $('.item_div').width();
          var full_width = $(window).width();
          var left = (((full_width - width)/2) + 5);

          if($(window).width() > 1024){
            $('.fix_bar1').css('width', width/3)
            $('.fix_bar1').css('left', ((width * 2)/3) + left)
          } else {
            $('.fix_bar1').css('width', width/2)
            $('.fix_bar1').css('left', ((width)/2) + left)
          }
          
          if($('#fix_bar1').height()>($(window).height()-150)){
            $('.fix_bar1').css('bottom', '50px')
            $('.fix_bar1').css('top', '80px')
          } else {
            $('.fix_bar1').css('top', '80px')
            $('.fix_bar1').css('bottom', 'unset')
          }
        // }
      } else {
        $('.fix_bar1').css('width', '33.33%')
        $('.fix_bar1').css('left', 'unset')
        $('#fix_bar1').removeClass('fix_bar1')
      }
      
    });
    if(!this.user_helper.user_cart.is_main_website){
      var cart_detail = this.user_helper.user_cart.is_get_cart_observable.subscribe((data)=>{
        if(data){
          if(cart_detail){
            cart_detail.unsubscribe();
          }
          if(this.user_helper.user_cart.cart_data.cart && this.user_helper.user_cart.cart_data.cart.length > 0){
            this.store_location = this.user_helper.user_cart.store_location;
            this.delivery_location = this.user_helper.user_cart.cart_data.destination_addresses[0].location;
            this.delivery_address = this.user_helper.user_cart.cart_data.destination_addresses[0].address;
            this.delivery_currency = this.user_helper.router_id.user.currency;
            $('#basket_address').val(this.delivery_address);
            this.floor = this.user_helper.user_cart.cart_data.destination_addresses[0].floor;
            this.entry_code = this.user_helper.user_cart.cart_data.destination_addresses[0].entry_code;
          }
            // if(this.delivery_address == ''){
              // this.user_helper.router.navigate(['']);
            // }

            let autocompleteElm = <HTMLInputElement>document.getElementById('basket_address');
            let autocomplete = new google.maps.places.Autocomplete((autocompleteElm), {
              strictBounds: false,
              bounds: new google.maps.LatLngBounds(new google.maps.LatLng(46.2043907, 6.143157699999961))
            });

            autocomplete.addListener('place_changed', () => {
                this.user_helper.myLoading = true;
                var place = autocomplete.getPlace();
                this.delivery_location = [place.geometry.location.lat(), place.geometry.location.lng()];
                this.delivery_address = place.formatted_address;
                $('#basket_address').val(this.delivery_address);
                this.update_address();
            });
            this.get_fav_address();
          
        }
      });
    }

  }

  focus_out_address(){
    $('#basket_address').val(this.delivery_address);
  }

  check_valid_time(){

        this.is_schedule_order = this.user_helper.user_cart.is_schedule_order;

        let server_date:any = new Date(this.user_helper.user_cart.server_date);
        server_date = new Date(server_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
        server_date = new Date(server_date);

        let selected_date: any = this.user_helper.user_cart.schedule_date

        let day_diff = selected_date.getDay() - server_date.getDay();
        let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());

        if(timeDiff/60000 >= this.current_store.schedule_order_create_after_minute){
            this.schedule_time_error = false;
            if(day_diff > 0){
                this.check_open(selected_date, false, false);
            } else {
                this.check_open(selected_date, true, false);
            }

        } else {
            this.schedule_time_error = true;
        }
    }

    asap(){
        $('.date_time').siblings('.dropdown_div').toggle();
        this.is_schedule_order = this.user_helper.user_cart.is_schedule_order;
        let server_date:any;
        if(this.user_helper.user_cart.server_date){
          server_date =  new Date(this.user_helper.user_cart.server_date);
        } else {
          server_date = new Date();
        }
        if(this.user_helper.user_cart.timezone){
          server_date = new Date(server_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
          server_date = new Date(server_date);
        }
        this.server_date = server_date;
        var days = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
        var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];

        let time_date = new Date(server_date);
        time_date.setHours(0,0,0,0);
        // for(var i=0; i<96; i++){
        //     this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
        //     time_date.setMinutes(time_date.getMinutes()+15);
        // }

        var selected_date: any = new Date(this.user_helper.user_cart.server_date);
        var week_day = selected_date.getDay();
        if(this.user_helper.user_cart.schedule_date){
          selected_date = new Date(this.user_helper.user_cart.schedule_date)
        }
        let weekday = selected_date.getDay();

        var current_date = new Date();
        var current_week_day = current_date.getDay();

        let week_index = this.current_store.store_time.findIndex((x) => x.day == weekday)
        let day_time = this.current_store.store_time[week_index].day_time;
        day_time = day_time.filter((x)=>{
            if(x.is_on==true || x.is_on==undefined){
                return x;
            }
        })

        this.time_array = [];
        // if(this.current_store.store_time[week_index].is_store_open_full_time){
        //     for(var i=0; i<96; i++){
        //         this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
        //         time_date.setMinutes(time_date.getMinutes()+15);
        //     }
        // } else {
          var time_index = 0;
          day_time.forEach((store_time , index) =>{
            let open_time = store_time.store_open_time;
            open_time = open_time.split(':');

            var open_hour = Number(open_time[0]);
            var open_min = Number(open_time[1]);

            let close_time = store_time.store_close_time;
            close_time = close_time.split(':');

            var close_hour = Number(close_time[0]);
            var close_min = Number(close_time[1]);

            var min = 0;
            var open_diff_hour = 4;
            if(open_min>=0 && open_min<15){
              min = 0;
              open_diff_hour = 4;
            } else if(open_min>=15 && open_min<30){
              min = 15;
              open_diff_hour = 3;
            } else if(open_min>=30 && open_min<45){
              min = 30;
              open_diff_hour = 2;
            } else if(open_min>=45 && open_min<60){
              min = 45;
              open_diff_hour = 1;
            }

            var close_diff_hour = 1;
            if(close_min>0 && close_min<15){
              close_diff_hour = 2;
            } else if(close_min>15 && close_min<30){
              close_diff_hour = 3;
            } else if(close_min>30 && close_min<45){
              close_diff_hour = 4;
            } else if(close_min>45 && close_min<60){
              close_diff_hour = 5;
            }


            var diff_hour = (close_hour - open_hour) - 1;
            diff_hour = diff_hour * 4;
            diff_hour = diff_hour + open_diff_hour + close_diff_hour;

            time_date.setHours(open_hour,min,0,0);
            for(var i=0; i<diff_hour; i++){
                this.time_array[time_index] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
                time_date.setMinutes(time_date.getMinutes()+15);
                time_index++;
            }


            // this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
            // time_date.setMinutes(time_date.getMinutes()+15);
          });
          if(day_time.length==0){
            for(var i=0; i<1; i++){
                this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
                time_date.setMinutes(time_date.getMinutes()+15);
            }
          }
        // }

        
        for(var i=0; i<7; i++){
            let date = new Date(server_date);
            date.setDate(date.getDate()+i);
            date = new Date(date);
            var day = days[ date.getDay()];
            var month = months[ date.getMonth() ];
            var date_format = day + ', ' + month + ' ' + date.getDate();
            this.date_array[i] = {week_day: week_day, date_format: date_format, date: date.getFullYear() + '-' +  (date.getMonth()+1) + '-' +  date.getDate()};
            
            if(week_day == 6){
              week_day = 0;
            } else {
              week_day++;
            }
        }
        // this.user_helper.scheduleDatePickerOptions.disableUntil.year = server_date.getFullYear();
        // this.user_helper.scheduleDatePickerOptions.disableUntil.month = server_date.getMonth()+1;
        // this.user_helper.scheduleDatePickerOptions.disableUntil.day = server_date.getDate()-1;
        //
        // this.user_helper.scheduleDatePickerOptions.disableSince.year = after_date.getFullYear();
        // this.user_helper.scheduleDatePickerOptions.disableSince.month = after_date.getMonth()+1;
        // this.user_helper.scheduleDatePickerOptions.disableSince.day = after_date.getDate();

        let date = this.user_helper.user_cart.schedule_date;
        if(this.user_helper.user_cart.schedule_date !== null && this.user_helper.user_cart.schedule_date !== ''){
            this.schedule_date = date.getFullYear() + '-' +  (date.getMonth()+1) + '-' +  date.getDate();
            var day = days[ date.getDay()];
            var month = months[ date.getMonth() ];
            this.clicked_date = day + ', ' + month + ' ' + date.getDate();
            this.schedule_time = date.getHours()+':'+date.getMinutes();
        } else {
            this.schedule_date = this.date_array[0].date;
            this.clicked_date = this.date_array[0].date_format;
            if(this.time_array.length>0){
              this.schedule_time = this.time_array[0].time_format;
            }
        }
    }

    set_date(date){
        this.clicked_date=date.date_format;
        this.schedule_date=date.date;

        let time_date = new Date();
        time_date.setHours(0,0,0,0);
        this.time_array = [];
        // if(this.current_store.store_time[date.week_day].is_store_open_full_time){
        //     for(var i=0; i<96; i++){
        //         this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
        //         time_date.setMinutes(time_date.getMinutes()+15);
        //     }
        // } else {
          let day_time = this.current_store.store_time[date.week_day].day_time;
          day_time = day_time.filter((x)=>{
              if(x.is_on==true || x.is_on==undefined){
                  return x;
              }
          })
          var time_index = 0;
          day_time.forEach((store_time , index) =>{
            let open_time = store_time.store_open_time;
            open_time = open_time.split(':');

            var open_hour = Number(open_time[0]);
            var open_min = Number(open_time[1]);

            let close_time = store_time.store_close_time;
            close_time = close_time.split(':');

            var close_hour = Number(close_time[0]);
            var close_min = Number(close_time[1]);

            var min = 0;
            var open_diff_hour = 4;
            if(open_min>=0 && open_min<15){
              min = 0;
              open_diff_hour = 4;
            } else if(open_min>=15 && open_min<30){
              min = 15;
              open_diff_hour = 3;
            } else if(open_min>=30 && open_min<45){
              min = 30;
              open_diff_hour = 2;
            } else if(open_min>=45 && open_min<60){
              min = 45;
              open_diff_hour = 1;
            }

            var close_diff_hour = 1;
            if(close_min>0 && close_min<15){
              close_diff_hour = 2;
            } else if(close_min>15 && close_min<30){
              close_diff_hour = 3;
            } else if(close_min>30 && close_min<45){
              close_diff_hour = 4;
            } else if(close_min>45 && close_min<60){
              close_diff_hour = 5;
            }


            var diff_hour = (close_hour - open_hour) - 1;
            diff_hour = diff_hour * 4;
            diff_hour = diff_hour + open_diff_hour + close_diff_hour;

            time_date.setHours(open_hour,min,0,0);
            for(var i=0; i<diff_hour; i++){
                this.time_array[time_index] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
                time_date.setMinutes(time_date.getMinutes()+15);
                time_index++;
            }

            // this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
            // time_date.setMinutes(time_date.getMinutes()+15);
          });

          if(day_time.length==0){
            for(var i=0; i<1; i++){
                this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
                time_date.setMinutes(time_date.getMinutes()+15);
            }
          }
        // }

        $(".dropdown2 dt a#date").parents().siblings().children(".dropdown2 dd ul").toggle().closest(".select_div").removeClass("ar_rot");
        $(".dropdown2 dt a#time").parents().siblings().children(".dropdown2 dd ul").toggle().closest(".select_div").addClass("ar_rot");
    }
    set_time(time){
        $('#time_toggle').click();
        this.schedule_time=time.time_format;
        $('.dropdown2 dt a#time').parents().siblings().children(".dropdown2 dd ul").toggle().closest(".select_div").toggleClass("ar_rot");
        this.set_order_time(true);
    }

    set_order_time(boolean) {
        this.is_schedule_order = boolean;
        if(boolean){
            if(this.schedule_date !== '' && this.schedule_time !== ''){

                let server_date: any = new Date(this.user_helper.user_cart.server_date);
                server_date = new Date(server_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
                server_date = new Date(server_date);
               
                let date = JSON.parse(JSON.stringify(this.schedule_date.split('-')));
                let time = this.schedule_time.split(':')

                let selected_date: any = new Date(Date.now());
                selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
                selected_date = new Date(selected_date);
                selected_date.setDate(date[2])
                selected_date.setMonth(date[1]-1)
                selected_date.setFullYear(date[0])
                selected_date.setHours(time[0], time[1], 0, 0);
                let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());

                if(timeDiff/60000 >= this.current_store.schedule_order_create_after_minute){
                    this.schedule_time_error = false;
                    $('.date_time').siblings('.dropdown_div').toggle();
                    this.user_helper.user_cart.schedule_date = selected_date;
                    this.user_helper.user_cart.clicked_date = this.clicked_date + ' ' + this.schedule_time;
                    this.user_helper.user_cart.is_schedule_order = this.is_schedule_order;
                    this.check_valid_time();
                } else {
                    this.schedule_time_error = true;
                }
            } else {
                this.schedule_time_error = true;
            }

        } else {
            this.user_helper.user_cart.schedule_date = null;
            this.schedule_time_error = false;
            this.schedule_date = '';
            this.schedule_time = '';
            $('#schedule_time').val('');
            this.user_helper.user_cart.is_schedule_order = this.is_schedule_order;
            let date:any = this.user_helper.user_cart.server_date;
            date = new Date(date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
            date = new Date(date);
            this.check_open(date, true, false)
            //$('.date_time').siblings('.dropdown_div').toggle();
        }
    }
    pad2(number) {
       return (number < 10 ? '0' : '') + number
    }

  update_address() {

        let json = {
            latitude: this.delivery_location[0],
            longitude: this.delivery_location[1],
            destination_address: this.delivery_address,
            destination_addresses: [{
                "delivery_status": 0,
                "address_type" : "destination",
                "address" : this.delivery_address,
                "entry_code": this.entry_code,
                "floor": this.floor,
                "city" : this.user_helper.user_cart.cart_data.destination_addresses[0].city,
                "postal_code" : this.user_helper.user_cart.cart_data.destination_addresses[0].postal_code,
                "country_code" : this.user_helper.user_cart.cart_data.destination_addresses[0].country_code,
                "country" : this.user_helper.user_cart.cart_data.destination_addresses[0].country,
                "state" : this.user_helper.user_cart.cart_data.destination_addresses[0].state,
                "location" : this.delivery_location,
                "note" : this.delivery_note,
                "user_type" :this.user_helper.user_cart.cart_data.destination_addresses[0].user_type,
                "user_details" : this.user_helper.user_cart.cart_data.destination_addresses[0].user_details
            }],
            cart_id: this.cart_data.cart_id
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CHANGE_DELIVERY_ADDRESS, json, (res_data) => {
            if(res_data.success){
                this.user_helper.user_cart.cart_data.destination_addresses[0].address = this.delivery_address;
                this.user_helper.user_cart.cart_data.destination_addresses[0].location = this.delivery_location;
                this.user_helper.user_cart.cart_data.destination_addresses[0].floor = this.floor;
                this.user_helper.user_cart.cart_data.destination_addresses[0].entry_code = this.entry_code;
                this.user_helper.user_cart.cart_data.destination_addresses[0].note = this.delivery_note;
            } else {
                this.delivery_location = this.user_helper.user_cart.cart_data.destination_addresses[0].location;
                this.delivery_address = this.user_helper.user_cart.cart_data.destination_addresses[0].address;
                $('#basket_address').val(this.delivery_address);
                this.floor = '';
                this.entry_code = '';
                this.delivery_note = '';
                this.selected_address_id = '';
            }
        });
    }

    set_current_location(){
      this.use_an_address = false;
      this.floor = '';
      this.entry_code = '';
      this.delivery_note = '';
      navigator.geolocation.getCurrentPosition((position) => {
          this.geocoder(position.coords.latitude, position.coords.longitude)
      });
    }

    geocoder(latitude, longitude){

        this.delivery_location = [latitude, longitude];
        var initialLocation = new google.maps.LatLng(latitude, longitude);
        var geocoder = new google.maps.Geocoder();

        let request = {latLng: initialLocation};
        geocoder.geocode(request, (results, status) => {
            if (status == google.maps.GeocoderStatus.OK) {
                this.user_helper.ngZone.run(() => {
                    this.delivery_address = results[0].formatted_address;
                    $('#basket_address').val(this.delivery_address);
                    this.update_address();
                });
            }
        });
    }

    get_address(){
      if(this.favourite_addresses.length>0){
        this.use_an_address = true;
      }
    }

    get_fav_address(){
      this.user_helper.http_post_method_requester('api/user/get_favoutire_addresses', {myLoading: false,user_id: this.user_id, server_token: this.server_token}, (res_data) => {
        if(res_data.success){
          this.favourite_addresses = res_data.favourite_addresses;
        } else {
          this.favourite_addresses = [];
        }
      });
    }

    set_address(address){
      this.delivery_location = [address.latitude, address.longitude];
      this.delivery_address = address.address;
      $('#basket_address').val(this.delivery_address);
      this.update_address();
      this.floor = address.floor;
      this.entry_code = address.entry_code;
      this.delivery_note = address.comment;
      this.selected_address_id = address._id;
    }

    open_item_modal(item, item_index , product_index, event, content, is_available){
      if(is_available){
        this.user_helper.get_cart();
        this.note_for_item = '';
        if(event.target.attributes.class != undefined) {
            if(event.target.attributes.class.value !== "carousel-indicators_li" && event.target.attributes.class.value !== "carousel-indicators_li active"){
                this.qty = 1;
                this.required_count = 0;
                this.current_item = JSON.parse(JSON.stringify(item));
                // this.current_item.specifications.sort(this.sortFormule);
                this.current_formule = {};
                this.current_main_item = item;
                this.product_unique_id = this.filtered_product_item_list[product_index]._id.unique_id;
                this.product_name = this.filtered_product_item_list[product_index]._id.name;
                this.calculate_is_required();
                this.calculateTotalAmount();
                this.open(content);
            }
        } else {
            this.qty = 1;
            this.required_count = 0;
            this.current_item = JSON.parse(JSON.stringify(item));
            this.current_formule = {};
            // this.current_item.specifications.sort(this.sortFormule);
            this.current_main_item = item;
            this.product_unique_id = this.filtered_product_item_list[product_index]._id.unique_id;
            this.product_name = this.filtered_product_item_list[product_index]._id.name;
            this.calculate_is_required();
            this.calculateTotalAmount();
            this.open(content);

        }
        if(this.check_buy_one_get_one()){
          this.current_item.is_buy_one_get_one = true;
        } else {
          this.current_item.is_buy_one_get_one = false;
        }
        this.is_open_item_modal = true;

      }
    }

    select_formule(formule:any){
      this.current_formule = JSON.parse(JSON.stringify(formule));
      console.log(this.current_formule)
      this.calculate_is_required();
      this.calculateTotalAmount();
    }  

    redirect(id){
      // document.querySelector('#tab' + id).scrollIntoView({ behavior: 'smooth', block: 'start' });

      var height = $('#tab' + id).offset().top - $('html,body').offset().top;

      $('html,body').animate({
          scrollTop: height - $('.fullview').height() - 70
      })
      this.selected_product_name = this.product_item_list[id]._id.name
    }

    add_favourite_store() {
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.ADD_FAVOURITE_STORE, {myLoading: false, user_id: this.user_id, store_id: this.current_store._id}, (res_data) => {
            this.current_store.favourite = true;
            this.favourite_stores = res_data.favourite_stores;
            let user = JSON.parse(localStorage.getItem('user'));
            user.favourite_stores = res_data.favourite_stores;
            localStorage.setItem('user', JSON.stringify(user));

        });
    }

    remove_favourite_store(){
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.REMOVE_FAVOURITE_STORE, {myLoading: false, user_id: this.user_id, store_id: [this.current_store._id]}, (res_data) => {
            this.current_store.favourite = false;
            this.favourite_stores = res_data.favourite_stores;
            let user = JSON.parse(localStorage.getItem('user'));
            user.favourite_stores = res_data.favourite_stores;
            localStorage.setItem('user', JSON.stringify(user));
        });
    }

    get_item_list(){
        let json = {
            store_id: this.store_id,
            user_id: this.user_id,
            server_token: this.server_token,
            myLoading: false,
            cart_unique_token: this.cart_unique_token
        }
      this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.USER_GET_STORE_PRODUCT_ITEM_LIST, json, (res_data) => {

          this.user_helper.myLoading = false;
          if(res_data.success){
              this.current_store = res_data.store;
              let specification_price;
              var date: any = res_data.server_time;
              date = new Date(date).toLocaleString("en-US", {timeZone: res_data.timezone})
              date = new Date(date);
              let product_array = res_data.products.filter((product_data) => {
                if(product_data._id.is_visible_in_store){
                  this.is_visible_in_store = true;
                }  
                if(this.check_product_available(product_data._id, date)){
                  let item_array = product_data.items.filter((item_data) => {
                      if(item_data.formule_list){

                        item_data.formule_list.forEach((formule_data)=>{
                          if(this.current_store.you_want_to_add_price_in_menu && this.current_store.add_price_value){
                            if(this.current_store.add_price_type==1){
                              formule_data.price = formule_data.price + ((formule_data.price * this.current_store.add_price_value)/100);
                            } else if(this.current_store.add_price_type==2){
                              formule_data.price = formule_data.price + this.current_store.add_price_value;
                            }
                          }
                        })
                      }

                      specification_price = 0;
                      item_data.specifications.filter((specification_group) => {
                          specification_group.list.filter((specification) => {
                            specification.price = Number(specification.price);
                            if(specification.is_default_selected && !specification.is_close && !specification_group.is_close){
                              if(!specification_group.formule_id){
                                specification_price = specification_price + Number(specification.price);
                              }
                            }
                            if(this.current_store.you_want_to_add_price_in_menu && this.current_store.add_price_value){
                              if(this.current_store.add_price_type==1){
                                specification.price = specification.price + ((specification.price * this.current_store.add_price_value)/100);
                              }
                            }
                          })
                          if(specification_group.min_selection==undefined || specification_group.max_selection==undefined){
                            if(specification_group.type==1){
                              specification_group.min_selection = 1;
                              specification_group.max_selection = 1;
                              // specification_group.is_required = true;
                            } else {
                              specification_group.max_selection = specification_group.list.length;
                              if(specification_group.is_required){
                                specification_group.min_selection = 1;
                              } else {
                                specification_group.min_selection = 0;
                              }
                            }
                          }
                          if(specification_group.max_selection==0){
                            specification_group.max_selection = 1;
                          }
                      })
                      let total_item_price = item_data.price + specification_price;
                      item_data.total_item_price = total_item_price;

                      if(this.current_store.you_want_to_add_price_in_menu && this.current_store.add_price_value){
                        if(this.current_store.add_price_type==1){
                          item_data.price = item_data.price + ((item_data.price * this.current_store.add_price_value)/100);
                          item_data.total_item_price = item_data.total_item_price + ((total_item_price * this.current_store.add_price_value)/100);
                        } else if(this.current_store.add_price_type==2){
                          item_data.price = item_data.price + this.current_store.add_price_value;
                          item_data.total_item_price = item_data.total_item_price + this.current_store.add_price_value;
                        }
                      }
                      return item_data;
                  });
                  product_data.items = item_array;
                  product_data.items.sort(this.sortItem);
                  return product_data;
                }

                
              });
              product_array.sort(this.sortOnOff);
              product_array.sort(this.sortProduct);
              this.product_item_list = product_array;
              this.selected_product_id = product_array[0]._id._id;
              this.filtered_product_item_list = product_array;

              this.selected_product_name = this.product_item_list[0]._id.name;

              this.filter_item("");
              this.delivery_currency = res_data.currency;
              this.user_helper.router_id.user.delivery_currency = res_data.currency;
              
              this.set_product_item_offer_tag()
              this.user_helper.user_cart.timezone = res_data.timezone;
              // this.helper.user_cart.store_location = res_data.store.location;

              this.user_helper.router_id.user.delivery_type_id = res_data.delivery_id;
              this.user_helper.router_id.user.city_id = res_data.store.city_id;

              let store_name = res_data.store.name.replace(/ /g, "-");
               store_name = store_name.replace(/(|)/g, "");
              store_name = store_name.toLowerCase();
              store_name = store_name.replace(/[^a-zA-Z ]/g, "")
              let delivery_name = res_data.delivery_name.replace(/ /g, "-");
              delivery_name = delivery_name.replace(/(|)/g, "");
              delivery_name = delivery_name.toLowerCase();
              delivery_name = delivery_name.replace(/[^a-zA-Z ]/g, "")
              let city_name = res_data.city_name.replace(/ /g, "-");
              city_name = city_name.toLowerCase();

              var arr_char = store_name.split("");
              var new_store_name = '';
              for (var i = 0; i < arr_char.length; i++) {
                if(arr_char[i] !== '(' && arr_char[i] !== ')'){
                  new_store_name = new_store_name + arr_char[i];
                }
              }

              var arr_delivery_name_char = delivery_name.split("");
              var new_delivery_name = '';
              for (var i = 0; i < arr_delivery_name_char.length; i++) {
                if(arr_delivery_name_char[i] !== '(' && arr_delivery_name_char[i] !== ')'){
                  new_delivery_name = new_delivery_name + arr_delivery_name_char[i];
                }
              }

              if(this.user_helper.user_cart.is_main_website){
                this.location.replaceState(city_name + '/' + new_delivery_name + '/' + new_store_name + '/' + res_data.store._id);
              }

              $.fn.hasScrollBar = function()
              {
                
                  return this.get(0).scrollWidth > this.innerWidth();
                

              }
              setTimeout(()=>{
                // if($('#outer').hasScrollBar()){
                //   $('#left-button').css('visibility', 'visible')
                // }
                this.user_helper.set_dynamic_height_width();
              },2000)

              this.delivery_time = res_data.delivery_time;
              this.is_emergency = res_data.is_emergency
                      this.emergency_close_till = res_data.emergency_close_till
              this.check_open(res_data.server_time, true, true);
              this.check_delivery_open(res_data.server_time, res_data.timezone);
              this.check_cart_offer();
              this.check_delivery_offer();
              
          } else {
              this.user_helper.data.storage = {
                  "code": res_data.error_code,
                  "message": this.user_helper.ERROR_CODE[res_data.error_code],
                  "class": "alert-danger"
              }
              if(this.user_helper.user_cart.is_main_website){
                this.user_helper.router.navigate(['/home']);
              } else {
                // this.user_helper.router.navigate(['']).then(() => {
                //     window.location.reload();
                // });
              }
          }
      });
    }

    check_delivery_open(server_date, timezone){
        let date:any = server_date;
        date = new Date(date).toLocaleString("en-US", {timeZone: timezone})
        date = new Date(date);
        let weekday = date.getDay();
        let current_time = date.getTime();
        this.is_delivery_available = false;

        let week_index = this.delivery_time.findIndex((x) => x.day == weekday)
        if(this.delivery_time[week_index].is_delivery_open){
            let day_time = this.delivery_time[week_index].day_time;
            if(day_time.length == 0){
                this.is_delivery_available = false;
            } else {
                day_time.forEach((store_time , index) =>{
                    let open_time = store_time.delivery_open_time;
                    open_time = open_time.split(':')
                    let open_date_time = date.setHours(open_time[0] , open_time[1], 0 ,0)
                    open_date_time = new Date(open_date_time);
                    open_date_time = open_date_time.getTime();

                    let close_time = store_time.delivery_close_time;
                    close_time = close_time.split(':')
                    let close_date_time = date.setHours(close_time[0] , close_time[1], 0 ,0)
                    close_date_time = new Date(close_date_time);
                    close_date_time = close_date_time.getTime();

                    if(current_time > open_date_time && current_time < close_date_time){
                        this.is_delivery_available = true;
                    }
                });
            }
        } else {
            this.is_delivery_available = false;
        }
    }

    set_product_item_offer_tag(){
      if(this.current_store.offers){
        this.current_store.offers.forEach((offer)=>{
          if(offer.offer_type==1 || offer.offer_type==2){
            offer.product_item_ids.forEach((product_item_id: string)=>{
              this.check_product_item_offer(product_item_id, offer)
            })
          }
        })
      }
    }

    check_product_item_offer(product_item_id:string, offer:any){
      this.filtered_product_item_list.forEach((product_detail, product_index)=>{
        if(product_item_id.toString() == product_detail._id._id.toString()){
          if(!product_detail.offer_tags){
            product_detail.offer_tags = []
          }
          product_detail.offer_tags.push(offer)
          product_detail.items.forEach((item_detail:any, item_index:any)=>{
            if(!item_detail.offer_tags){
              item_detail.offer_tags = []
            }
            item_detail.offer_tags.push(offer)
            if(offer.offer_type==2){
              item_detail.offer_price = 0;
              var discount_value = offer.discount_value;
              if(offer.discount_type==0){
                if(discount_value>100){
                  discount_value=100
                }
                item_detail.offer_price = item_detail.price - ((item_detail.price * discount_value)/100);
              } else {
                if(discount_value>item_detail.price){
                  discount_value=item_detail.price
                }
                item_detail.offer_price = item_detail.price - discount_value
              }
            }
          });
        } else {
          product_detail.items.forEach((item_detail:any, item_index:any)=>{
            if(product_item_id.toString() == item_detail._id.toString()){
              if(!item_detail.offer_tags){
                item_detail.offer_tags = []
              }
              item_detail.offer_tags.push(offer)
              if(offer.offer_type==2){
                item_detail.offer_price = 0;
                var discount_value = offer.discount_value;
                if(offer.discount_type==0){
                  if(discount_value>100){
                    discount_value=100
                  }
                  item_detail.offer_price = item_detail.price - ((item_detail.price * discount_value)/100);
                } else {
                  if(discount_value>item_detail.price){
                    discount_value=item_detail.price
                  }
                  item_detail.offer_price = item_detail.price - discount_value
                }
              }
            }
          })
        }
      })

    }

    check_product_available(product_data, server_date){

      var date = new Date(server_date);
      if(!product_data.is_product_time){
        product_data.is_available = true;
        
      } else {
        
        let current_time = date.getTime();
        product_data.is_available = false;
        let weekday = date.getDay();
        let week_index = product_data.product_time.findIndex((x) => x.day == weekday)

        if(product_data.product_time[week_index].is_available){
          let day_time = product_data.product_time[week_index].day_time;

          day_time.forEach((product_time , index) =>{
            let open_time = product_time.open_time;
            open_time = open_time.split(':')
            let x = date.setHours(open_time[0] , open_time[1], 0 ,0)
            let x1 = new Date(x);
            let x2 = x1.getTime();

            let close_time = product_time.close_time;
            close_time = close_time.split(':')
            let y = date.setHours(close_time[0] , close_time[1], 0 ,0)
            let y1 = new Date(y);
            let y2 = y1.getTime();

            if(current_time >= x2 && current_time <= y2){
              product_data.is_available = true;
              product_data.available_text = 'Disponible jusqu’à '+product_time.close_time
            }
            if(current_time<x2){
              product_data.available_text = 'Disponible dès '+product_time.open_time;
            }
          });
          if(!product_data.is_available && !product_data.available_text){
            if(day_time.length>0){
              product_data.available_text = 'Disponible entre '+day_time[0].open_time+ ' et '+day_time[0].close_time;
            } else {
              product_data.available_text = 'Non disponible actuellement'
            }
          }
        } else {
          product_data.available_text = 'Non disponible actuellement'
        }
        
      }
      return true;
    }

    check_open(selected_date, today, bool){

      var date: any = JSON.parse(JSON.stringify(selected_date));
      date = new Date(date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
      date = new Date(date);
      let weekday = date.getDay();
      let current_time = date.getTime();
      if(this.current_store.is_business){
        this.current_store.close = true;
        this.current_store.conges = false;
        if(bool){
          this.current_store.main_conges = false;
          this.current_store.main_close = true;
          this.current_store.main_nextopentime = '';
        }
        this.current_store.nextopentime = '';

        var current_date = new Date();
        var current_week_day = current_date.getDay();

        if(today){
            this.store_open_day = this.title.today;
        } else {
            this.store_open_day = this.user_helper.WEEK_DAY[weekday];
        }

        let week_index = this.current_store.store_time.findIndex((x) => x.day == weekday)
        
        if(this.current_store.store_time[week_index].is_store_open){

          let day_time = this.current_store.store_time[week_index].day_time;
          day_time = day_time.filter((x)=>{
              if(x.is_on==true || x.is_on==undefined){
                  return x;
              }
          })
          if(day_time.length == 0){
              this.current_store.close = true;
              if(bool){
                this.current_store.main_close = true;
              }
              this.check_next_open_time(selected_date, this.current_store, week_index, bool, 0, null, 0);
          } else {
              day_time.forEach((store_time , index) =>{
                  let open_time = store_time.store_open_time;
                  open_time = open_time.split(':')
                  let x = date.setHours(open_time[0] , open_time[1], 0 ,0)
                  let x1 = new Date(x);
                  let x2 = x1.getTime();

                  let close_time = store_time.store_close_time;
                  close_time = close_time.split(':')
                  let y = date.setHours(close_time[0] , close_time[1], 0 ,0)
                  let y1 = new Date(y);
                  let y2 = y1.getTime();

                  if(current_time >= x2 && current_time <= y2){
                      this.current_store.close = false;
                      if(bool){
                        this.current_store.main_close = false;
                      }
                  }

                  if(current_time < x2 && this.current_store.nextopentime == ''){
                      this.current_store.nextopentime = store_time.store_open_time;
                      if(bool){
                        this.current_store.main_nextopentime = store_time.store_open_time;
                      }
                  }
              });
              if(this.current_store.close && !this.current_store.nextopentime){
                this.check_next_open_time(selected_date, this.current_store, week_index, bool, 0, null, 0);
              }
          }
            
        } else {
            this.current_store.close = true;
            if(bool){
              this.current_store.main_close = true;
            }
            this.check_next_open_time(selected_date, this.current_store, week_index, bool, 0, null, 0);
        }

        if(!this.current_store.close){
          if(!this.current_store.is_stop_new_order_all_day){
            if(this.current_store.close_till){
              let close_till = this.current_store.close_till;
              close_till = close_till.split(':')
              let open_date_time = date.setHours(close_till[0] , close_till[1], 0 ,0)
              open_date_time = new Date(open_date_time);
              open_date_time = open_date_time.getTime();
              if(current_time < open_date_time){
                  this.current_store.close = true;
                  if(bool){
                    this.current_store.main_close = true;
                    this.current_store.main_nextopentime = this.current_store.close_till;
                  }
                  this.current_store.nextopentime = this.current_store.close_till;
                  if(this.current_store.store_order[0]==1){
                      this.current_store['is_delivery_possible'] = true;
                  } else if(this.current_store.store_order[0]==2){
                      this.current_store['is_pickup_possible'] = true;
                  }
              }
            }
          } else {
              this.current_store.close = true;
              if(bool){
                this.current_store.main_close = true;
                this.current_store.main_nextopentime = '';
              }
              this.current_store.nextopentime = '';
              if(this.current_store.is_stop_new_order_all_day){
                if(this.current_store.store_order[0]==1){
                    this.current_store['is_delivery_possible'] = true;
                } else if(this.current_store.store_order[0]==2){
                    this.current_store['is_pickup_possible'] = true;
                }
              }
          }
        }

        if(!this.current_store.close && !this.user_helper.user_cart.ipickup_delivery && this.is_emergency && this.emergency_close_till){

          var close_till_time = 0;
          let close_till: any = new Date(this.emergency_close_till);
          close_till = new Date(close_till).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
          close_till = new Date(close_till);
          if(this.user_helper.user_cart.schedule_date){
            let selected_date: any = new Date(this.user_helper.user_cart.schedule_date);
            selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
            selected_date = new Date(selected_date);

            close_till_time = Math.round(close_till.getTime() - selected_date.getTime());
          }

          // if(close_till_time > 0 || !this.user_helper.user_cart.schedule_date){
            this.current_store.close = true;
            this.current_store.main_close = true;
            this.current_store['is_delivery_possible'] = false;
            this.current_store['is_delivery_stop_admin'] = true;
            this.current_store['is_pickup_possible'] = true;
            this.current_store.nextopentime = close_till.getHours()+':'+close_till.getMinutes();
            this.current_store.main_nextopentime = close_till.getHours()+':'+close_till.getMinutes();
            this.open(this.is_delivery_stop_admin)
          // }
        }

      } else {
        this.current_store.close = true;
        if(bool){
          this.current_store.main_close = true;
          this.current_store.main_nextopentime = '';
        }
        this.current_store.nextopentime = '';
      }

      if(this.current_store.store_conges && this.current_store.store_conges.length>0){
          this.check_conges(selected_date, this.user_helper.user_cart.timezone, bool)
      }
      
    }

    check_conges(server_date, timezone, bool){
        let date:any = server_date;
        date = new Date(date).toLocaleString("en-US", {timeZone: timezone})
        date = new Date(date);
        var current_time = date.getTime();
        this.current_store.store_conges.forEach((store_conges_detail)=>{
            var start_time:any = new Date(store_conges_detail.start_time).toLocaleString("en-US", {timeZone: timezone})
            start_time = new Date(start_time);
            var end_time:any = new Date(store_conges_detail.end_time).toLocaleString("en-US", {timeZone: timezone})
            end_time = new Date(end_time);
            if(start_time.getTime()<=current_time && end_time.getTime()>current_time){
                this.current_store.close = true;
                this.current_store.conges = true;
                if(bool){
                  this.current_store.main_close = true;
                  this.current_store.main_conges = true;
                }
                const diffTime = Math.abs(end_time.getTime() - date.getTime());
                const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                // if(diffDays<=7){
                    let weekday = end_time.getDay();
                    this.current_store.conges = false;
                    this.current_store.nextopentime = '';
                    if(bool){
                      this.current_store.main_conges = false;
                      this.current_store.main_nextopentime = '';
                    }
                    this.check_next_open_time(server_date, this.current_store, weekday-1, bool, diffDays-1, end_time.getTime(), true)
                // } else {
                //   this.current_store.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+moment(end_time).tz(timezone !== undefined ? timezone: 'Europe/Zurich').format('DD/MM à HH:mm');
                //   if(bool){
                //     this.current_store.main_nextopentime = 'Partenaire en congé Précommandez, réouvre le '+moment(end_time).tz(timezone !== undefined ? timezone: 'Europe/Zurich').format('DD/MM à HH:mm');
                //   }
                // }
            }
        })
    }

    check_next_open_time(server_date, store, week_index, bool, count, end_time, loop_count){
        count++;
        let date:any = JSON.parse(JSON.stringify(server_date));
        date = new Date(date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
        date = new Date(date);
        let weekday = date.getDay();
        let current_time = date.getTime();

        var days = ['Dimanche','Lundi','Mardi','Mercredi','Jeudi','Vendredi','Samedi'];
        var date_time = JSON.parse(JSON.stringify(server_date));
        date_time = new Date(date_time);
        if(week_index == 6){
            week_index = 0;
        } else {
            week_index++;
        }
        date_time = date_time.setDate(date_time.getDate() + count);
        date_time = new Date(date_time);

        if(loop_count != 7){
          loop_count++;
            let day_time = this.current_store.store_time[week_index].day_time;
            day_time = day_time.filter((x)=>{
                if(x.is_on==true || x.is_on==undefined){
                    return x;
                }
            })
            if(this.current_store.store_time[week_index].is_store_open){
                  // if(store.store_time[week_index].is_store_open_full_time){
                  //     store.nextopentime = days[week_index];
                  //     if(bool){
                  //       store.main_nextopentime = days[week_index];
                  //     }
                  // }
                  // else {
                
                    if(day_time.length == 0){
                        this.check_next_open_time(server_date,  this.current_store, week_index,bool, count, end_time, loop_count);
                    } else {
                        day_time.forEach((store_time , index) =>{
                            let open_time = store_time.store_open_time;
                            open_time = open_time.split(':')
                            let open_date_time = date_time.setHours(open_time[0] , open_time[1], 0 ,0)
                            open_date_time = new Date(open_date_time);
                            open_date_time = open_date_time.getTime();

                            let close_time = store_time.store_close_time;
                            close_time = close_time.split(':')
                            let close_date_time = date_time.setHours(close_time[0] , close_time[1], 0 ,0)
                            close_date_time = new Date(close_date_time);
                            close_date_time = close_date_time.getTime();

                            if(!end_time || close_date_time >= end_time){
                              if(current_time < open_date_time && !this.current_store.nextopentime){
                                  if(!end_time || open_date_time >= end_time){
                                      
                                      const diffTime = Math.abs(open_date_time - current_time);
                                      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                                      if(diffDays<7){
                                        var date = new Date(date_time.setHours(open_time[0] , open_time[1], 0 ,0))
                                        this.current_store.nextopentime = days[date.getDay()] + ' à ' + store_time.store_open_time;
                                      } else {
                                          open_date_time = new Date(open_date_time)
                                          this.current_store.nextopentime = open_date_time.getDate()+'/' + (open_date_time.getMonth()+1) + ' à ' + store_time.store_open_time;
                                      }
                                      if(end_time){
                                          this.current_store.conges = true;
                                          this.current_store.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+this.current_store.nextopentime
                                      }
                                      if(bool){
                                        this.current_store.main_conges = this.current_store.conges;
                                        this.current_store.main_nextopentime = this.current_store.nextopentime
                                      }  
                                   } else {
                                      const diffTime = Math.abs(end_time - current_time);
                                      const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                                      var end_time_date = new Date(end_time);
                                      var hours = ("0" + end_time_date.getHours()).slice(-2);
                                      var minutes = ("0" + end_time_date.getMinutes()).slice(-2);
                                      if(diffDays<7){
                                        var date = new Date(date_time.setHours(end_time_date.getHours() , end_time_date.getMinutes(), 0 ,0))
                                          this.current_store.nextopentime = days[date.getDay()] + ' à ' + hours +':'+ minutes;
                                      } else {
                                          this.current_store.nextopentime = end_time_date.getDate()+'/' + (end_time_date.getMonth()+1) + ' à ' + hours +':'+ minutes;
                                      }
                                      this.current_store.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+this.current_store.nextopentime
                                      this.current_store.conges = true;
                                      if(bool){
                                        this.current_store.main_conges = this.current_store.conges;
                                        this.current_store.main_nextopentime = this.current_store.nextopentime
                                      } 
                                  }
                              }
                            }
                        });
                        if(!this.current_store.nextopentime){
                            this.check_next_open_time(server_date, this.current_store, week_index, bool, count, end_time, loop_count);
                        }
                    }
                  // }
            } else {
                this.check_next_open_time(server_date, this.current_store, week_index, bool,count, end_time, loop_count)
            }
        }
    }

    close_item_modal(){
        this.current_item = {image_url:[]};
        // this.item_modal.close();
    }

    sortItem(a, b) {
        if (a.sequence_number < b.sequence_number)
            return -1;
        if (a.sequence_number > b.sequence_number)
            return 1;
    }

    sortProduct(a,b){
        if (a._id.sequence_number < b._id.sequence_number)
            return -1;
        if (a._id.sequence_number > b._id.sequence_number)
            return 1;
    }

    sortOnOff(a, b) {
        if (a._id.is_visible_in_store > b._id.is_visible_in_store)
            return -1;
        if (a._id.is_visible_in_store < b._id.is_visible_in_store)
            return 1;
        return 0;
    }

    check_buy_one_get_one(){
      if(this.current_item.offer_tags){
        var index = this.current_item.offer_tags.findIndex((offer)=>offer.offer_type==1);
        if(index !== -1){
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    change_buy_one_get_one(is_buy_one_get_one){
      this.current_item.is_buy_one_get_one = is_buy_one_get_one;
    }

    // check_buy_one_get_one_selected(is_buy_one_get_one){
    //   console.log('check_buy_one_get_one_selected')
    //   if(this.current_item.is_buy_one_get_one == is_buy_one_get_one){
    //     return true;
    //   } else {
    //     return false;
    //   }
    // }

    calculate_is_required(){
      this.required_count = 0;
      this.current_item.specifications.forEach((specification_group) => {
        if(!specification_group.formule_id || specification_group.formule_id==this.current_formule.formule_id){
          if(specification_group.is_required && !specification_group.is_close){
              this.required_count++;
          }
          let list = specification_group.list.filter((x:any)=>{
            if(!x.is_close){
              return x;
            }
          })
          if(list.length < specification_group.min_selection){
            specification_group.min_selection = list.length;
          }
          if(list.length < specification_group.max_selection){
            specification_group.max_selection = list.length;
          }
        }
      })
    }

    calculateTotalAmount(){
      this.total = this.current_item.price;
      if(this.current_formule.formule_id && this.current_formule.price){
        this.total = Number(this.current_formule.price)
      }
      this.required_temp_count = 0;
      this.current_item.specifications.forEach((specification_group , specification_group_index) => {
        if(!specification_group.formule_id || specification_group.formule_id==this.current_formule.formule_id){
          let isAllowed = false;
          var default_selected_count = 0;
          specification_group.selected_specification = 0;
          specification_group.list.forEach((specification , specification_index) => {

              if(specification.is_default_selected && !specification.is_close && !specification_group.is_close){
                  this.total = this.total + specification.price;
                  default_selected_count++;
                  specification_group.selected_specification++;
              }
              specification_group.default_selected_count = default_selected_count;
          });

          if(specification_group.type ==1 && specification_group.is_required) {
              // if(specification_group.min_selection) {
              //     if (default_selected_count >= specification_group.range) {
              //         this.required_temp_count++;
              //     }
              // } else {
                  if (default_selected_count >= 1) {
                      this.required_temp_count++;
                  }
              // }
          } else if(specification_group.type == 2 && specification_group.is_required) {
              // if (specification_group.range) {
                  if (default_selected_count >= specification_group.min_selection) {
                      this.required_temp_count++;
                  }
              // } else {
              //     if (default_selected_count >= 1) {
              //         this.required_temp_count++;
              //     }
              // }
          }
          // if(specification_group.is_option_limit && specification_group.option_limit_number <= specification_group.selected_specification){
          //   specification_group.is_disabled = true;
          // } else {
          //   specification_group.is_disabled = false;
          // }
        }
      });
      this.total = this.total * this.qty;
      this.check_item_offer();
    }

    changeradio(event, specification_group_index, specification_index){
      if(!this.current_item.specifications[specification_group_index].list[specification_index].is_close){

        var index = this.current_item.specifications[specification_group_index].list.findIndex(x => x.is_default_selected == true)
        if(index !== -1){
            this.current_item.specifications[specification_group_index].list[index].is_default_selected = false;
        }
        this.current_item.specifications[specification_group_index].list[specification_index].is_default_selected = true;
        this.calculateTotalAmount();
      }
    }

    changecheckbox(event, specification_group_index, specification_index){
      if(!this.current_item.specifications[specification_group_index].list[specification_index].is_close){
        this.current_item.specifications[specification_group_index].list[specification_index].is_default_selected = event.target.checked;
        this.calculateTotalAmount();
      }
    }

    range(number){
        let a = [];
        for(let i = 0; i < number; ++i)
        {
            a.push(i+1)
        }
        return a;
    }

    filter_item(data)
    {
        data = data.replace(/^\s+|\s+$/g, '');
        data = data.replace(/ +(?= )/g, '');
        
        data = new RegExp(data,"gi");

        var product_array = [];
        this.product_item_list.forEach((product) => {

            var item_array = product.items.filter((item_data) => {
                var a = item_data.name.match(data);
                return a !== null;
            })
            if(item_array.length > 0)
            {
                product_array.push({_id:product._id , name:product.name , store_id:product.store_id , unique_id:product.unique_id,
                    is_visible_in_store: product.is_visible_in_store , items:item_array})
            }
        });
        this.filtered_product_item_list = product_array;

        // setTimeout(function () {
        //     jQuery('.iradio').iCheck({
        //         handle: 'radio',
        //         radioClass: 'iradio_square-green'
        //     });
        //
        //     jQuery('.iradio').on('ifChecked', (event) => {
        //
        //         let scroll = (jQuery("#product"+event.target.id).offset().top  - jQuery(".first_div").offset().top + 70);
        //         jQuery("body").animate({
        //             scrollTop: scroll
        //         },1000);
        //
        //     });
        // }, 1000);
    }

    incerase_qty(){
        this.is_loaded = !this.is_loaded;
        this.qty++;
        this.calculateTotalAmount();
    }

    // dragstart_image(event){
    //     this.start_screex = event.screenX
    // }
    // dragend_image(event, length){
    //     this.end_screex = event.screenX;
    //     var a = jQuery('.a.active').attr('data-slide-to');
    //     a = Number(a);
    //     if(this.start_screex < this.end_screex && a > 0){
    //         a = a-1;
    //         jQuery('.a#aa'+a).click()
    //     } else if(this.start_screex > this.end_screex && a < length){
    //         a = a+1;
    //         jQuery('.a#aa'+a).click()
    //     }
    // }

    decrease_qty(){
        this.is_loaded = !this.is_loaded;
        if(this.qty>1){
            this.qty--;
            this.calculateTotalAmount();
        }
    }

    clear_old_cart(){
        this.user_helper.myLoading = true;
        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            cart_unique_token: this.cart_unique_token,
            cart_id: this.user_helper.user_cart.cart_data.cart_id
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CLEAR_CART, json, (res_data) => {
            // if(res_data.success){
                this.user_helper.user_cart.cart_data.cart = [];
                this.user_helper.user_cart.cart_data.selectedStoreId = null;
                this.user_helper.user_cart.cart_data.max_item_quantity_add_by_user = 0;
                this.user_helper.user_cart.cart_data.total_item = 0;
                this.user_helper.user_cart.total_cart_amount = 0;
                this.user_helper.user_cart.cart_data.pickup_addresses = [];
                this.user_helper.user_cart.cart_data.destination_addresses = [];
                if(this.current_location.latitude == 0 || this.current_location.longitude == 0){

                    this.user_helper.user_cart.destination_address.location = [];
                    this.user_helper.user_cart.destination_address.address = '';
                    this.user_helper.user_cart.destination_address.city = '';
                    this.user_helper.user_cart.destination_address.country = '';
                    this.user_helper.user_cart.destination_address.state = '';
                    this.user_helper.user_cart.destination_address.postal_code = '';
                    this.user_helper.user_cart.destination_address.country_code = '';
                    this.addToCart()
                
                } else {
                    this.user_helper.user_cart.destination_address.location = [this.current_location.latitude, this.current_location.longitude];
                    this.user_helper.user_cart.destination_address.address = this.current_location.address;
                    this.user_helper.user_cart.destination_address.city = this.current_location.city1
                    this.user_helper.user_cart.destination_address.country = this.current_location.country
                    this.user_helper.user_cart.destination_address.state = this.current_location.state
                    this.user_helper.user_cart.destination_address.postal_code = this.current_location.postal_code
                    this.user_helper.user_cart.destination_address.country_code = this.current_location.country_code

                    this.handleClickOnAddToCart()
                }
            // } else {
            //   // this.user_helper.get_cart();
            //     this.user_helper.data.storage = {
            //         "code": res_data.error_code,
            //         "message": this.user_helper.ERROR_CODE[res_data.error_code],
            //         "class": "alert-danger"
            //     }
            // }
        });
    }

    handleClickOnAddToCart(){

        // if(this.current_store.close){
        //     console.log("Store Closed")
        // } else {
        //     if(this.current_location.address == ''){
        //         console.log("your current location is empty")
        //     } else {

        if(this.user_helper.user_cart.cart_data.cart.length == 0){
            if(!this.current_location.latitude || !this.current_location.longitude){

                this.user_helper.user_cart.destination_address.location = [];
                this.user_helper.user_cart.destination_address.address = '';
                this.user_helper.user_cart.destination_address.city = '';
                this.user_helper.user_cart.destination_address.country = '';
                this.user_helper.user_cart.destination_address.state = '';
                this.user_helper.user_cart.destination_address.postal_code = '';
                this.user_helper.user_cart.destination_address.country_code = '';
                this.addToCart()
                // navigator.geolocation.getCurrentPosition((position) => {

                //     // this.helper.user_cart.cart_data.deliveryLatLng = [position.coords.latitude, position.coords.longitude];
                //     this.user_helper.user_cart.destination_address.location = [position.coords.latitude, position.coords.longitude];

                //     var initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                //     var geocoder = new google.maps.Geocoder();

                //     let request = {latLng: initialLocation};
                //     geocoder.geocode(request, (results, status) => {
                //         if (status == google.maps.GeocoderStatus.OK) {
                //             this.user_helper.ngZone.run(() => {
                //                 // this.helper.user_cart.cart_data.deliveryAddress = results[0].formatted_address;
                //                 this.user_helper.user_cart.destination_address.address = results[0].formatted_address;
                //                 this.user_helper.user_cart.destination_address.city = this.current_location.city1;
                //                 this.addToCart()
                //             });
                //         } else {
                //             this.addToCart();
                //         }
                //     });
                // },(error) => {
                //     // this.helper.user_cart.cart_data.deliveryLatLng = this.current_store.location;
                //     this.user_helper.user_cart.destination_address.location = this.current_store.location;

                //     // this.helper.user_cart.cart_data.deliveryAddress = this.current_location.address;
                //     this.user_helper.user_cart.destination_address.address = this.current_location.address;
                //     this.user_helper.user_cart.destination_address.city = this.current_location.city1
                //     this.addToCart()
                // });
            } else {
                // this.helper.user_cart.cart_data.deliveryLatLng = [this.current_location.latitude, this.current_location.longitude];
                // this.helper.user_cart.cart_data.deliveryAddress = this.current_location.address;
                this.user_helper.user_cart.destination_address.location = [this.current_location.latitude, this.current_location.longitude];
                this.user_helper.user_cart.destination_address.address = this.current_location.address;
                this.user_helper.user_cart.destination_address.city = this.current_location.city1
                this.user_helper.user_cart.destination_address.country = this.current_location.country
                this.user_helper.user_cart.destination_address.state = this.current_location.state
                this.user_helper.user_cart.destination_address.postal_code = this.current_location.postal_code
                this.user_helper.user_cart.destination_address.country_code = this.current_location.country_code

                this.addToCart()
            }

        } else {
            if(this.user_helper.user_cart.cart_data.selectedStoreId == this.store_id){
                this.addToCart()
            } else {

              $('#close_item_model').click();
              $('#clear_cart_modal').click();
              this.is_open_item_modal = false;
            }
        }
    }

    addToCart(){

        this.user_helper.myLoading = true;
        let specificationPriceTotal = 0;
        let specificationPrice = 0;
        let specificationList = [];

        this.current_item.specifications.forEach((specification_group , specification_group_index) => {
          if(!specification_group.formule_id || specification_group.formule_id==this.current_formule.formule_id){
            let specificationItemCartList = [];
            specification_group.list.forEach((specification , specification_index) => {

                if(specification.is_default_selected && !specification.is_close && !specification_group.is_close){

                    specificationPrice = specificationPrice + specification.price;
                    specificationPriceTotal = specificationPriceTotal + specification.price;
                    specificationItemCartList.push(specification)
                }
            });

            if(specificationItemCartList.length > 0){
                let specificationsItem_json ={
                    list: specificationItemCartList,
                    unique_id: specification_group.unique_id,
                    name: specification_group.name,
                    price: specificationPrice,
                    is_required: specification_group.is_required,
                    min_selection: specification_group.min_selection,
                    max_selection: specification_group.max_selection,
                    type: specification_group.type,
                    formule_id: specification_group.formule_id
                }
                specificationList.push(specificationsItem_json);
            }
            specificationPrice = 0;
          }
        });

        this.cartProductItems.item_id = this.current_item._id;
        this.cartProductItems.unique_id = this.current_item.unique_id;
        this.cartProductItems.item_name = this.current_item.name;
        this.cartProductItems.quantity = this.qty;
        this.cartProductItems.image_url = this.current_item.image_url;
        this.cartProductItems.details = this.current_item.details;
        this.cartProductItems.specifications = specificationList;
        this.cartProductItems.item_price = this.current_item.price;
        this.cartProductItems.offer_price = this.current_item.offer_price;
        this.cartProductItems.is_buy_one_get_one = this.current_item.is_buy_one_get_one;
        this.cartProductItems.total_specification_price = specificationPriceTotal;
        this.cartProductItems.total_item_and_specification_price = this.total;
        this.cartProductItems.note_for_item = JSON.parse(JSON.stringify(this.note_for_item));
        if(this.current_formule.formule_id){
          this.cartProductItems.formule_list = [this.current_formule]
          this.cartProductItems.item_price = Number(this.current_formule.price)
          this.cartProductItems.item_name = this.current_formule.name;
        }
    
        this.note_for_item = '';

        this.user_helper.user_cart.cart_main_item.push(this.current_main_item);
        this.user_helper.user_cart.total_cart_amount = this.user_helper.user_cart.total_cart_amount + this.total - (this.current_item.offer_price ? this.current_item.offer_price : 0);

        if(this.isProductExistInLocalCart(this.cartProductItems))
        {

        } else {
            let cartProductItemsList = [];
            cartProductItemsList.push(this.cartProductItems)
            this.cartProducts.items = cartProductItemsList;
            this.cartProducts.product_id = this.current_item.product_id;
            this.cartProducts.product_name = this.product_name
            this.cartProducts.unique_id = this.product_unique_id
            this.cartProducts.total_item_price = this.total;
            this.user_helper.user_cart.cart_data.is_get_card = true;

            this.user_helper.user_cart.cart_data.cart.push(this.cartProducts);
            this.user_helper.user_cart.cart_data.selectedStoreId = this.store_id;
            this.user_helper.user_cart.cart_data.max_item_quantity_add_by_user = this.current_store.max_item_quantity_add_by_user;
            this.user_helper.user_cart.store_location = this.current_store.location;
            if(this.user_helper.user_cart.cart_data.pickup_addresses.length == 0){
                this.user_helper.user_cart.pickup_address.location = this.current_store.location;
                this.user_helper.user_cart.pickup_address.address = this.current_store.address;
                this.user_helper.user_cart.pickup_address.user_type = this.current_store.user_type;
                this.user_helper.user_cart.pickup_address.user_details = {
                    "name": this.current_store.name,
                    "country_phone_code": this.current_store.country_phone_code,
                    "phone": this.current_store.phone,
                    "email": this.current_store.email
                }
                this.user_helper.user_cart.cart_data.pickup_addresses.push(this.user_helper.user_cart.pickup_address);
            }
            this.user_helper.user_cart.cart_data.total_item++;

            this.addItemInServerCart(this.user_helper.user_cart.cart_data.cart);

        }
    }

    isProductExistInLocalCart(cartProductItems){

        let bool = false;
        this.user_helper.user_cart.cart_data.cart.forEach((cart_item) =>{

            if(cart_item.product_id == this.current_item.product_id && bool == false){
                cart_item.items.push(cartProductItems);
                cart_item.total_item_price = cart_item.total_item_price + this.total;
                this.addItemInServerCart(this.user_helper.user_cart.cart_data.cart);
                this.user_helper.user_cart.cart_data.total_item++;
                bool = true;
            }
        });
        return bool;
    }

    sortFormule(a:any,b:any){
      if (a.formule_id)
          return -1;
      return 0;
    }
  

    addItemInServerCart(cartData){

        this.cartProductItems = {
            item_id: null,
            unique_id: 0,
            item_name: '',
            quantity: 0,
            image_url: [],
            details: '',
            specifications: [],
            formule_list: [],
            item_price: 0,
            is_buy_one_get_one: false,
            offer_price: 0,
            total_specification_price: 0,
            total_item_and_specification_price: 0,
            note_for_item: ''
        }
        this.cartProducts = {
            items: [],
            product_id: null,
            product_name: '',
            unique_id: 0,
            total_item_price: 0
        }
        this.check_cart_offer();
        this.user_helper.add_to_cart();
        
        // this.item_modal.close();
        $('#close_item_model').click();
        $('#clear_cart_modal_close').click();
        this.is_open_item_modal = false;
        this.is_loaded = !this.is_loaded;
    }


  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', windowClass:'product_pop with_img'}).result.then((result) => {
        setTimeout(()=>{
          $('#close_item_model').click(function(){
            this.is_open_item_modal = false
         })
         $('#close_item_model1').click(()=>{
            this.is_open_item_modal = false
         })
         $('#clear_cart_modal_close').click(()=>{
            this.is_open_item_modal = false
         })
        }, 1000);

      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

    open_edit_item_modal(content2, item, product, selected_item_index, selected_product_index){

        this.current_formule = {formule_id: ''}
        if(item.formule_list && item.formule_list.length>0){
          this.current_formule = JSON.parse(JSON.stringify(item.formule_list[0]));
        }
    
        this.selected_item_index = selected_item_index;
        this.selected_product_index = selected_product_index;
        let item_index = this.user_helper.user_cart.cart_main_item.findIndex((x) => x._id == item.item_id);
        let current_specification = this.user_helper.user_cart.cart_main_item[item_index].specifications;
        let order_specification = item.specifications;
        let new_specification = [];

        current_specification.forEach((x) => {
            var index = order_specification.findIndex((order_sp) => order_sp.unique_id == x.unique_id)
            if(x.formule_id){
              index = order_specification.findIndex((order_sp:any) => (order_sp.unique_id == x.unique_id && order_sp.formule_id==x.formule_id))
            }
  
            if(index == -1){
              x.list.forEach((y:any)=>{
                y.price = Number(y.price);
              });
              new_specification.push(x);
  
            } else {
                var new_specification_list = [];
                x.list.forEach((y)=>{
                    y.price = Number(y.price);
                    var list_index = order_specification[index].list.findIndex((order_sp_list) => order_sp_list.unique_id == y.unique_id)
                    if(list_index == -1){
                        y.is_default_selected = false;
                        new_specification_list.push(y);
                    } else {
                        order_specification[index].list[list_index].price = y.price;
                        new_specification_list.push(order_specification[index].list[list_index]);
                    }
                });
                let json = {
                    list: new_specification_list,
                    "unique_id" : x.unique_id,
                    "name" : x.name,
                    "is_required": x.is_required,
                    "min_selection": x.min_selection,
                    formule_id: x.formule_id,
                    "max_selection": x.max_selection,
                    "price" : Number(x.price),
                    "type" : x.type
                }
                new_specification.push(json);
            }
        });
        this.qty = item.quantity;
        this.required_count = 0;
        this.current_item = JSON.parse(JSON.stringify(item));
        this.current_item.price = this.user_helper.user_cart.cart_main_item[item_index].price;
        this.current_item.name = this.user_helper.user_cart.cart_main_item[item_index].name;
        // new_specification.sort(this.sortFormule);
  
        this.current_item.specifications = new_specification;
        this.current_item.formule_list = this.user_helper.user_cart.cart_main_item[item_index].formule_list;
        this.calculate_is_required();
        this.edit_item_calculateTotalAmount();
        this.note_for_item = item.note_for_item
        if(this.check_buy_one_get_one() && this.current_item.is_buy_one_get_one){
          this.current_item.is_buy_one_get_one = true;
        } else {
          this.current_item.is_buy_one_get_one = false;
        }

        setTimeout(()=>{
            this.open(content2);
        },100);
        this.is_open_item_modal = true;
    }

    edit_item_calculateTotalAmount(){
        this.total = this.current_item.price;
        if(this.current_formule.formule_id && this.current_formule.price){
          this.total = Number(this.current_formule.price)
        }
        this.required_temp_count = 0;
        this.current_item.specifications.forEach((specification_group , specification_group_index) => {
          if(!specification_group.formule_id || specification_group.formule_id==this.current_formule.formule_id){  
            let isAllowed = false;

            if(specification_group.min_selection==undefined || specification_group.max_selection==undefined){
              if(specification_group.type==1){
                specification_group.min_selection = 1;
                specification_group.max_selection = 1;
                // specification_group.is_required = true;
              } else {
                specification_group.max_selection = specification_group.list.length;
                if(specification_group.is_required){
                  specification_group.min_selection = 1;
                } else {
                  specification_group.min_selection = 0;
                }
              }
            }
            if(specification_group.max_selection==0){
              specification_group.max_selection = 1;
            }

            var default_selected_count = 0;
            specification_group.selected_specification = 0;
            specification_group.list.forEach((specification , specification_index) => {
              specification.price = Number(specification.price);
                if(specification.is_default_selected && !specification.is_close && !specification_group.is_close){
                    this.total = this.total + specification.price;
                    default_selected_count++;
                    specification_group.selected_specification++;
                }
                specification_group.default_selected_count = default_selected_count;
            });

            if(specification_group.type ==1 && specification_group.is_required) {
              if (default_selected_count >= 1) {
                  this.required_temp_count++;
              }
            } else if(specification_group.type == 2 && specification_group.is_required) {
              if (default_selected_count >= specification_group.min_selection) {
                  this.required_temp_count++;
              }
            }
          }
        });
  
        this.total = this.total * this.qty;

        this.check_item_offer();
    }

    check_item_offer(){
      if(this.current_item &&this.current_item.offer_tags){
        var index = this.current_item.offer_tags.findIndex((offer)=>offer.offer_type==2);
        if(index !== -1){
          var offer_detail = this.current_item.offer_tags[index];
          var discount_type = offer_detail.discount_type;
          var discount_value = offer_detail.discount_value;
          var offer_price = 0;
          if(discount_type==0){
            offer_price = (this.total * discount_value)/100;
          } else{
            offer_price = discount_value;
            if(offer_price > this.total){
              offer_price = this.total;
            }
          }
          // this.total = this.total - offer_price;
          this.current_item.offer_price = offer_price;
        }
      }
    }

    check_cart_offer(){
      var cart_offer_message = '';
      var cart_offer_error = '';
      var cart_offer_error2 = '';
      this.cart_offer_value = 0
      var index = this.current_store.offers.findIndex((offer)=>offer.offer_type==4);
      if(index !== -1){
          var offer_detail = this.current_store.offers[index];
          var discount_type = offer_detail.discount_type;
          var discount_value = offer_detail.discount_value;
          if(!offer_detail.is_min_order_price || (offer_detail.is_min_order_price && this.user_helper.user_cart.total_cart_amount>=offer_detail.min_order_price)){
              this.cart_offer_error = '';
              this.cart_offer_error2 = '';
              if(discount_type==0){
                  this.cart_offer_message = 'VOUS BÉNÉFICIEZ DE -'+discount_value+'% SUR VOTRE COMMANDE';
                  this.cart_offer_progress_bar = 100;
                  this.cart_offer_value = ((this.user_helper.user_cart.total_cart_amount * discount_value)/100);
              } else{
                  this.cart_offer_message = 'VOUS BÉNÉFICIEZ DE -'+discount_value+' '+this.delivery_currency+' SUR VOTRE COMMANDE';
                  this.cart_offer_progress_bar = -1;
                  this.cart_offer_value = discount_value;
                  if(this.cart_offer_value > this.user_helper.user_cart.total_cart_amount){
                    this.cart_offer_value = this.user_helper.user_cart.total_cart_amount
                  }
              }
          } else {
              this.cart_offer_message = '';
              this.cart_offer_value = 0
              var amount = offer_detail.min_order_price - this.user_helper.user_cart.total_cart_amount;
              if(discount_type==0){
                  this.cart_offer_error = '⭐️️ - '+discount_value+'% DÈS '+offer_detail.min_order_price+this.delivery_currency;
              } else {
                  this.cart_offer_error = '⭐️️ - '+discount_value+this.delivery_currency+' DÈS '+offer_detail.min_order_price+this.delivery_currency;
              }
              this.cart_offer_progress_bar = ((this.user_helper.user_cart.total_cart_amount*100)/offer_detail.min_order_price);
              this.cart_offer_error2 = 'Plus que '+amount.toFixed(2)+' '+this.delivery_currency+' pour profiter de l’offre '
          }
      } else {
        this.cart_offer_message = '';
        this.cart_offer_error = '';
        this.cart_offer_error2 = '';
        this.cart_offer_value = 0
      }
    }

    check_delivery_offer(){
      this.delivery_offers = [];
      var offer_list = this.current_store.offers.filter((offer)=>offer.offer_type==3);
      offer_list.forEach((offer_detail)=>{
        var offer_message = '';
        if(offer_detail.discount_type==0){
          offer_message = offer_detail.discount_value+'% des frais de livraison sont offerts'
        } else if(offer_detail.discount_type==1){
          offer_message = offer_detail.discount_value+this.delivery_currency+' des frais de livraison sont offerts'
        } else if(offer_detail.discount_type==2){
          offer_message = 'Frais de livraisons offerts'
        }
        if(offer_detail.is_min_order_price){
          offer_message = offer_message + ' dès '+offer_detail.min_order_price+this.delivery_currency
        }
        this.delivery_offers.push(offer_message);
      })
    }

    updateCart(){
        let specificationPriceTotal = 0;
        let specificationPrice = 0;
        let specificationList = [];
        this.current_item.specifications.forEach((specification_group , specification_group_index) => {
          if(!specification_group.formule_id || specification_group.formule_id==this.current_formule.formule_id){
            let specificationItemCartList = [];
            specification_group.list.forEach((specification , specification_index) => {

                if(specification.is_default_selected && !specification.is_close && !specification_group.is_close){

                    specificationPrice = specificationPrice + specification.price;
                    specificationPriceTotal = specificationPriceTotal + specification.price;
                    specificationItemCartList.push(specification)
                }
            });

            if(specificationItemCartList.length > 0){
                let specificationsItem_json ={
                    list: specificationItemCartList,
                    unique_id: specification_group.unique_id,
                    name: specification_group.name,
                    price: specificationPrice,
                    type: specification_group.type,
                    formule_id: specification_group.formule_id,
                    is_required: specification_group.is_required,
                    min_selection: specification_group.min_selection,
                    max_selection: specification_group.max_selection
                }
                specificationList.push(specificationsItem_json);
            }
            specificationPrice = 0;
          }
        });

        

        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].item_price = this.current_item.price;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].offer_price = this.current_item.offer_price;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].is_buy_one_get_one = this.current_item.is_buy_one_get_one;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].total_specification_price = specificationPriceTotal;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].total_item_and_specification_price = this.total;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].note_for_item = this.note_for_item;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].specifications = specificationList;

        if(this.current_formule.formule_id){
          this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].formule_list = [this.current_formule];
          this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].item_price = Number(this.current_formule.price)
          this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].item_name = this.current_formule.name
        } else {
          this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items[this.selected_item_index].formule_list = [];
        }

        
        var total_item_price = 0;
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].items.forEach((item) =>{
            total_item_price = total_item_price + item.total_item_and_specification_price;
        });
        this.user_helper.user_cart.cart_data.cart[this.selected_product_index].total_item_price = total_item_price;

        $('#close_item_model1').click();
        this.user_helper.calculateTotalAmount();
        this.is_open_item_modal = false;
        this.check_cart_offer();
    }

    close_item_modal2(){
      $('#close_item_model').click();
      this.is_open_item_modal = false;
    }

    close_item_modal1(){
      $('#close_item_model1').click();
      this.is_open_item_modal = false;
    }

    clear_cart_modal_close(){
      $('#clear_cart_modal_close').click();
      this.is_open_item_modal = false;
    }

    toggle_dropdown(){
        $(".product_dt").slideToggle("slow");
        // $('.product_dt').toggle();
        $('.product_list_l').toggleClass('product_list_l_open')
        if(this.type==1){
          this.type=2;
        } else {
          this.type=1;
        }
    }

    select_schedule(){
      var date = new Date();
      this.user_helper.user_cart.is_schedule_order=true;
      this.user_helper.user_cart.schedule_date = date;
      var days = ['Sun','Mon','Tues','Wed','Thur','Fri','Sat'];
      var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
      var day = days[ date.getDay()];
      var month = months[ date.getMonth() ];
      this.clicked_date = day + ', ' + month + ' ' + date.getDate();
      this.user_helper.user_cart.clicked_date = this.clicked_date + ' ' + date.getHours() +':' +date.getMinutes();
    }

}
