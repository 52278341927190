import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { BooleanService } from "../boolean.service";
import {
  button,
  language,
  description,
  menu_title,
  label,
  title,
  placeholder,
  price,
  ordList,
  orderTable,
  links,
} from "../helper";
import { Helper } from "../user_helper";
declare var $: any;
declare var google;
import { UUID } from "angular2-uuid";
import { ModalDismissReasons, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { HeaderComponent } from "../header/header.component";
import { Location } from "@angular/common";
import * as moment from "moment-timezone";
import { Response, Http } from "@angular/http";
import { ViewEncapsulation } from "@angular/core";
declare var stripe;
import { AuthService } from "angularx-social-login";
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from "angularx-social-login";

export interface OrderPayment {
  _id: string;
  promo_payment: number;
  service_tax: number;
  total_admin_tax_price: number;
  total_cart_price: number;
  total_service_price: number;
  total_store_tax_price: number;
  total_item_count: number;
  total_item_price: number;
  item_tax: number;
  total_order_price: number;
  total: number;
  is_distance_unit_mile: Boolean;
  price_per_unit_time: number;
  price_per_unit_distance: number;
  delivery_price: number;
  is_promo_for_delivery_service: boolean;
  is_store_pay_delivery_fees: boolean;
}

export interface UserLogin {
  email: string;
  social_id: string;
  login_by: any;
  cart_unique_token: string;
  password: string;
}
export interface UserRegister {
  cart_unique_token: string;
  first_name: String;
  last_name: String;
  email: String;
  password: String;
  social_id: string;
  login_by: string;
  confirm_password: String;
  country_id: string;
  city: Object;
  address: String;
  country_phone_code: string;
  phone: Number;
  image_url: String;
  referral_code: String;
  is_email_verified: Boolean;
  is_phone_number_verified: Boolean;
  currency_code: string;
}
export interface UserForgotPassword {
  email: String;
}

export interface AddCard {
  card_holder_name: string;
  card_number: string;
  last_four: number;
  expiry_month: number;
  expiry_year: number;
  cvv: number;
  card_type: string;
  payment_token: string;
}

export interface StoreData {
  _id: string;
  name: string;
  image_url: string;
  delivery_time: number;
  close: Boolean;
  nextopentime: string;
  store_time: any[];
  item_tax: number;
  is_business: boolean;
  is_taking_schedule_order: boolean;
  schedule_order_create_after_minute: 0;
  is_stop_new_order_all_day: boolean;
  close_till: any;
  payment_mode_for_pickup: number;
  store_conges: any[];
  conges: boolean;
  store_order: any;
  is_pickup_possible: boolean;
  is_delivery_possible: boolean;
  is_delivery_stop_admin: boolean;
  offers: any[];
}

@Component({
  selector: "app-basket",
  templateUrl: "./basket.component.html",
  providers: [Helper, HeaderComponent],
})
export class BasketComponent implements OnInit, OnDestroy {
  minus_img: any = "../assets/images/minus_c.png";
  plus_img: any = "../assets/images/plus_c.png";

  public add_card_data: AddCard;
  public button: any = button;
  public language: any = language;
  public menu_title: any = menu_title;
  public label: any = label;
  public title: any = title;
  public description: any = description;
  public placeholder: any = placeholder;
  public price: any = price;
  public ordList: any = ordList;
  public orderTable: any = orderTable;
  private links: any = links;
  closeResult: string;
  moment = moment();
  email_placeholder: Number = 1;
  email_or_phone_error: Boolean = false;
  promo_code_apply_on_minimum_amount: number;
  @ViewChild("apple_google_pay") apple_google_pay: ElementRef;
  modalReference: any;

  public cart_unique_token: string = "";
  public user_id: string = "";
  public server_token: string = "";
  public user_profile: string = "";
  private cart_data: any = {};
  private store_location: any[];
  private delivery_location: any[];
  public delivery_address: string = "";
  postal_code: string = "";
  country_code: string = "";
  city: string = "";
  country: string = "";
  state: string = "";
  cart_offer_error: string = "";
  delivery_user_phone: string = "";
  delivery_user_name: string = "";
  public delivery_currency: string = "";
  public store_detail: StoreData = {
    _id: "",
    name: "",
    delivery_time: 0,
    image_url: "",
    is_business: false,
    close: false,
    nextopentime: "",
    store_time: [],
    item_tax: 0,
    is_taking_schedule_order: false,
    schedule_order_create_after_minute: 0,
    is_stop_new_order_all_day: false,
    close_till: "",
    payment_mode_for_pickup: 0,
    store_conges: [],
    conges: false,
    store_order: [],
    is_pickup_possible: true,
    is_delivery_possible: true,
    is_delivery_stop_admin: false,
    offers: [],
  };
  delivery_note: string = "";
  promocode: string = "";
  promo_applied: Boolean = false;
  google_distance: any = 0;
  google_time: any = 0;
  bool: Boolean = true;
  edit_address: Boolean = true;
  mimimum_amount: number = 0;
  disable_place_holder_button: boolean = true;
  minimum_phone_number_length: number = 8;
  maximum_phone_number_length: number = 15;
  password_type: string = "password";

  city_id: string = "";
  private payment_gateway: any[] = [];
  public selected_payment_gateway: string = "";
  public card_list: any[] = [];
  private is_cash_payment_mode: Boolean = false;
  promo_error: number = 0;
  public order_payment: OrderPayment;
  card_error: string = "";
  address_error: string = "";

  is_schedule_order: Boolean = false;
  schedule_date: any = "";
  schedule_time: string = "";
  schedule_time_error: Boolean = false;
  store_open_day: string = "";
  // ipickup_delivery: Boolean = false;
  service_tax: number = 0;
  floor: any = "";
  entry_code: any = "";
  wallet: number = 0;
  wallet_currency_code: string = "";
  is_use_wallet: boolean = false;

  clicked_date: any = null;
  server_date: any = null;
  date_array: any[] = [];
  time_array: any[] = [];

  modal_type: number = 1;

  selected_item_index: number = 0;
  selected_product_index: number = 0;
  note_for_item: string = "";
  current_item: any = { image_url: [] };
  current_formule: any = {};
  current_main_item: any = {};
  required_count: number = 0;
  private total: number = 0;
  required_temp_count: number = 0;
  qty: number = 1;

  public user_login: UserLogin;
  error_message: string = "";

  delay_order_error: boolean = false;
  is_invoice_response: boolean = true;

  fname: string;
  sname: string;
  password: string;
  email: string;
  mobile: number;
  setting_data: any = {};
  private user_register: UserRegister;
  country_list: any[] = [];
  private user_forgot_password: UserForgotPassword;
  use_an_address: boolean = false;
  favourite_addresses: any[] = [];
  selected_address_id: string = "";
  login_type: number = 1;
  promo_code_detail: string = "";

  is_delay_order: boolean = false;
  delay_distance: number = null;
  delay_time: number = null;
  close_till: any = null;
  add_preparation_time_min: number = 0;

  add_cart_subscriber: any;
  applePay: boolean = false;
  googlePay: boolean = false;
  prButton: any;
  delivery_offers: any[] = [];

  delivery_time: any[] = [];
  is_delivery_available: boolean = true;
  is_emergency: boolean = false;
  emergency_close_till: string = "";

  constructor(
    private authService: AuthService,
    public bln: BooleanService,
    public location: Location,
    public user_helper: Helper,
    private modalService: NgbModal,
    public HeaderComponent: HeaderComponent
  ) {}

  ngAfterViewInit() {
    $(".sss").click(function (e) {
      e.preventDefault();
      $(".dropdown_div").hide();
    });

    $(".schedule").click(function (e) {
      e.preventDefault();
      $(".drop_form").show().parent().addClass("open2");
    });

    $(".dropdown2 dt a").click(function (e) {
      e.preventDefault();

      //$(".dropdown2 dd ul").toggle().closest(".select_div").toggleClass("ar_rot");
      $(this)
        .parents()
        .siblings()
        .children(".dropdown2 dd ul")
        .toggle()
        .closest(".select_div")
        .toggleClass("ar_rot");
    });

    $(".dropdown2 dd ul li a").click(function (e) {
      e.preventDefault();
      var text = $(this).html();

      //$(".dropdown2 dt a span").html(text);
      $(this)
        .closest("dd")
        .siblings()
        .children()
        .children(".dropdown2 dt a span")
        .html(text);
      $(".dropdown2 dd ul").hide().closest(".select_div").removeClass("ar_rot");
    });
  }

  ngOnDestroy() {
    if (this.add_cart_subscriber) {
      this.add_cart_subscriber.unsubscribe();
    }
  }

  ngOnInit() {
    this.fname = "";
    this.sname = "";
    this.password = "";
    this.email = "";
    this.mobile = null;
    this.user_helper.router_id.user.is_busy = false;
    this.user_helper.router_id.user.is_store_busy = false;

    this.user_login = {
      cart_unique_token: localStorage.getItem("cart_unique_token"),
      email: "",
      password: "",
      login_by: this.title.manual,
      social_id: "",
    };
    this.user_forgot_password = {
      email: "",
    };
    this.selected_payment_gateway =
      this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE;
    this.add_card_data = {
      card_holder_name: "",
      card_number: "",
      last_four: null,
      expiry_year: null,
      expiry_month: null,
      cvv: null,
      card_type: "",
      payment_token: "",
    };

    this.user_register = {
      cart_unique_token: localStorage.getItem("cart_unique_token"),
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      social_id: "",
      login_by: this.title.manual,
      confirm_password: "",
      country_id: "Switzerland",
      city: "",
      address: "",
      country_phone_code: "+41",
      phone: null,
      image_url: "./default.png",
      referral_code: "",
      is_phone_number_verified: false,
      is_email_verified: false,
      currency_code: "CHF",
    };

    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.GET_SETTING_DETAIL,
      {},
      (res_data) => {
        this.user_helper.myLoading = false;
        this.setting_data = res_data.setting;

        if (
          this.setting_data.is_user_login_by_phone == true &&
          this.setting_data.is_user_login_by_email == true
        ) {
          this.email_placeholder = 1;
        } else if (this.setting_data.is_user_login_by_phone == true) {
          this.email_placeholder = 2;
        } else if (this.setting_data.is_user_login_by_email == true) {
          this.email_placeholder = 3;
        }
      }
    );

    this.bln.showLogin = false;
    this.bln.showhedmid = true;
    this.bln.showcart = false;
    this.bln.isSticky = false;
    this.bln.showLink = false;
    this.bln.isHome = true;
    this.bln.cart = true;
    this.bln.showSignup = true;
    this.bln.isAdd_product = false;
    this.bln.isShop = true;
    this.bln.isInner_page = true;
    this.bln.isBod_btm_h = true;
    this.bln.address = true;
    this.bln.force_cart = false;

    this.city_id = this.user_helper.user_cart.cart_data.city_id;

    // if(this.user_helper.user_cart.cart_data.total_item<=0){
    //   this.user_helper.router.navigate(['']);
    // }
    if (!this.user_helper.user_cart.cart_data.is_get_card) {
      if (this.user_helper.user_cart.is_main_website) {
        this.user_helper.router.navigate(["/home"]);
      } else {
        this.user_helper.router.navigate(["/commander"]).then(() => {
          window.location.reload();
        });
      }
    }

    /* counter */

    $(document).ready(function () {
      var counter = 0;
      $(".plus").click(function () {
        counter++;
        $(this).siblings(".value span").html(counter);
      });
      $(".minus").click(function () {
        if (counter > 0) {
          counter--;
        }
        $(this).siblings(".value span").html(counter);
      });

      /* remove */

      $(".remove").click(function (e) {
        e.preventDefault();
        $(this).closest(".ord_in").hide();
      });
    });

    this.delivery_note = this.user_helper.user_cart.delivery_note;
    this.cart_unique_token = localStorage.getItem("cart_unique_token");
    this.cart_data = this.user_helper.user_cart.cart_data;

    let user = JSON.parse(localStorage.getItem("user"));
    if (user && user._id) {
      this.user_id = user._id;
      this.server_token = user.server_token;
      //this.is_use_wallet = user.is_use_wallet
      this.user_profile = user.image_url;
      this.delivery_user_name = user.first_name + user.last_name;
      this.delivery_user_phone = user.phone;
      this.get_fav_address();
    }

    this.order_payment = {
      _id: null,
      promo_payment: 0,
      total_cart_price: 0,
      service_tax: 0,
      total_admin_tax_price: 0,
      total_service_price: 0,
      total_store_tax_price: 0,
      total_item_count: 0,
      total_item_price: 0,
      item_tax: 0,
      total_order_price: 0,
      total: 0,
      is_distance_unit_mile: false,
      price_per_unit_distance: 0,
      price_per_unit_time: 0,
      delivery_price: 0,
      is_promo_for_delivery_service: false,
      is_store_pay_delivery_fees: false,
    };

    this.store_detail = {
      _id: "",
      name: "",
      delivery_time: 0,
      image_url: "",
      close: false,
      nextopentime: "",
      store_time: [],
      is_business: false,
      item_tax: 0,
      is_taking_schedule_order: false,
      schedule_order_create_after_minute: 0,
      is_stop_new_order_all_day: false,
      close_till: "",
      payment_mode_for_pickup: 0,
      store_conges: [],
      conges: false,
      store_order: [],
      is_pickup_possible: true,
      is_delivery_possible: true,
      is_delivery_stop_admin: false,
      offers: [],
    };

    // if(this.user_helper.user_cart.cart_data.destination_addresses.length>0 && this.user_helper.user_cart.cart_data.destination_addresses[0].address)
    // {

    // } else {
    //   this.user_helper.router.navigate(['']);
    // }

    if (this.cart_data.cart.length > 0) {
      this.user_helper.myLoading = true;
      this.store_location = this.user_helper.user_cart.store_location;
      this.delivery_location =
        this.user_helper.user_cart.cart_data.destination_addresses[0].location;
      this.delivery_address =
        this.user_helper.user_cart.cart_data.destination_addresses[0].address;
      this.postal_code =
        this.user_helper.user_cart.cart_data.destination_addresses[0].postal_code;
      this.country_code =
        this.user_helper.user_cart.cart_data.destination_addresses[0].country_code;
      this.city =
        this.user_helper.user_cart.cart_data.destination_addresses[0].city;
      this.country =
        this.user_helper.user_cart.cart_data.destination_addresses[0].country;
      this.state =
        this.user_helper.user_cart.cart_data.destination_addresses[0].state;
      this.delivery_currency = this.user_helper.router_id.user.currency;
      $("#basket_address").val(this.delivery_address);
      this.floor =
        this.user_helper.user_cart.cart_data.destination_addresses[0].floor;
      this.entry_code =
        this.user_helper.user_cart.cart_data.destination_addresses[0].entry_code;

      // if(this.delivery_address == ''){
      // this.user_helper.router.navigate(['']);
      // }

      let autocompleteElm = <HTMLInputElement>(
        document.getElementById("basket_address")
      );
      let autocomplete = new google.maps.places.Autocomplete(autocompleteElm, {
        strictBounds: false,
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(46.2043907, 6.143157699999961)
        ),
      });

      autocomplete.addListener("place_changed", () => {
        this.user_helper.myLoading = true;
        var place = autocomplete.getPlace();
        this.delivery_location = [
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        ];
        this.delivery_address = place.formatted_address;
        for (var i = 0; i < place.address_components.length; i++) {
          if (place.address_components[i].types[0] == "locality") {
            this.city = place.address_components[i].long_name;
          } else if (place.address_components[i].types[0] == "country") {
            this.country = place.address_components[i].long_name;
            this.country_code = place.address_components[i].short_name;
          } else if (
            place.address_components[i].types[0] ==
            "administrative_area_level_1"
          ) {
            this.state = place.address_components[i].long_name;
          } else if (place.address_components[i].types[0] == "postal_code") {
            this.postal_code = place.address_components[i].long_name;
          }
        }
        $("#basket_address").val(this.delivery_address);
        this.update_address();
      });

      this.user_helper.add_to_cart((response) => {
        this.get_payment_gateway(
          JSON.parse(localStorage.getItem("current_location"))
        );
        // setTimeout(()=>{
        this.add_cart_subscriber =
          this.user_helper.user_cart.is_add_cart_observable.subscribe(
            (data) => {
              if (data) {
                if (!this.google_distance) {
                  this.get_distnce_time();
                } else {
                  this.get_order_invoice(
                    this.google_distance,
                    this.google_time
                  );
                }
              }
            }
          );
        // }, 2000)
      });
      //   if(JSON.parse(localStorage.getItem('current_location'))){
      //   }
    } else {
      // this.user_helper.router.navigate(['']);
    }
    this.country_list = this.user_helper.country_list;
    // this.user_helper.http.get(this.user_helper.GET_METHOD.GET_COUNTRY_LIST).map((response: Response) => response.json()) .subscribe(res_data => {
    //           this.country_list = res_data.countries;
    if (this.country_list.length > 0) {
      this.user_register.currency_code = "CHF";
      this.user_register.country_phone_code = "+41";
      this.user_register.country_id = "Switzerland";
    }
    //     },
    // (error: any) => {
    //     this.user_helper.http_status(error)
    // });
  }

  go_to_store() {
    this.user_helper.router_id.load_item_page = true;
    if (this.user_helper.user_cart.is_main_website) {
      this.user_helper.router
        .navigate([
          "city_name" +
            "/" +
            "delivery_name" +
            "/" +
            this.store_detail.name +
            "/" +
            this.store_detail._id,
        ])
        .then(() => {
          window.location.reload();
        });
    } else {
      this.user_helper.router.navigate(["/commander"]).then(() => {
        window.location.reload();
      });
    }
  }

  password_type_change() {
    if (this.password_type == "password") {
      this.password_type = "text";
    } else {
      this.password_type = "password";
    }
  }

  select_country() {
    var index = this.country_list.findIndex(
      (x) => x.country_name == this.user_register.country_id
    );
    this.user_register.currency_code = this.country_list[index].currency_code;
    this.user_register.country_phone_code =
      this.country_list[index].country_phone_code;
  }

  pad2(number) {
    return (number < 10 ? "0" : "") + number;
  }

  check_valid_time() {
    this.is_schedule_order = this.user_helper.user_cart.is_schedule_order;

    let server_date: any = new Date(this.user_helper.user_cart.server_date);
    server_date = new Date(server_date).toLocaleString("en-US", {
      timeZone: this.user_helper.user_cart.timezone,
    });
    server_date = new Date(server_date);

    let selected_date: any = this.user_helper.user_cart.schedule_date;

    let day_diff = selected_date.getDay() - server_date.getDay();
    let timeDiff = Math.round(selected_date.getTime() - server_date.getTime());

    if (
      timeDiff / 60000 >=
      this.store_detail.schedule_order_create_after_minute
    ) {
      this.schedule_time_error = false;
      if (day_diff > 0) {
        this.check_open(selected_date, false);
      } else {
        this.check_open(selected_date, true);
      }
    } else {
      this.schedule_time_error = true;
    }
  }

  asap() {
    $(".drop_form").show().parent().addClass("open2");
    $(".date_time").siblings(".dropdown_div").toggle();
    this.is_schedule_order = this.user_helper.user_cart.is_schedule_order;
    let server_date: any;
    if (this.user_helper.user_cart.server_date) {
      server_date = new Date(this.user_helper.user_cart.server_date);
    } else {
      server_date = new Date();
    }
    if (this.user_helper.user_cart.timezone) {
      server_date = new Date(server_date).toLocaleString("en-US", {
        timeZone: this.user_helper.user_cart.timezone,
      });
      server_date = new Date(server_date);
    }
    this.server_date = server_date;
    var days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let time_date = new Date(server_date);
    time_date.setHours(0, 0, 0, 0);
    // for(var i=0; i<96; i++){
    //     this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
    //     time_date.setMinutes(time_date.getMinutes()+15);
    // }

    var selected_date: any = new Date(this.user_helper.user_cart.server_date);
    var week_day = selected_date.getDay();
    if (this.user_helper.user_cart.schedule_date) {
      selected_date = new Date(this.user_helper.user_cart.schedule_date);
    }
    let weekday = selected_date.getDay();

    var current_date = new Date();
    var current_week_day = current_date.getDay();

    let week_index = this.store_detail.store_time.findIndex(
      (x) => x.day == weekday
    );
    let day_time = this.store_detail.store_time[week_index].day_time;
    day_time = day_time.filter((x) => {
      if (x.is_on == true || x.is_on == undefined) {
        return x;
      }
    });

    this.time_array = [];
    // if(this.store_detail.store_time[week_index].is_store_open_full_time){
    //     for(var i=0; i<96; i++){
    //         this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
    //         time_date.setMinutes(time_date.getMinutes()+15);
    //     }
    // } else {
    var time_index = 0;
    day_time.forEach((store_time, index) => {
      let open_time = store_time.store_open_time;
      open_time = open_time.split(":");

      var open_hour = Number(open_time[0]);
      var open_min = Number(open_time[1]);

      let close_time = store_time.store_close_time;
      close_time = close_time.split(":");

      var close_hour = Number(close_time[0]);
      var close_min = Number(close_time[1]);

      var min = 0;
      var open_diff_hour = 4;
      if (open_min >= 0 && open_min < 15) {
        min = 0;
        open_diff_hour = 4;
      } else if (open_min >= 15 && open_min < 30) {
        min = 15;
        open_diff_hour = 3;
      } else if (open_min >= 30 && open_min < 45) {
        min = 30;
        open_diff_hour = 2;
      } else if (open_min >= 45 && open_min < 60) {
        min = 45;
        open_diff_hour = 1;
      }

      var close_diff_hour = 1;
      if (close_min >= 0 && close_min < 15) {
        close_diff_hour = 1;
      } else if (close_min >= 15 && close_min < 30) {
        close_diff_hour = 2;
      } else if (close_min >= 30 && close_min < 45) {
        close_diff_hour = 3;
      } else if (close_min >= 45 && close_min < 60) {
        close_diff_hour = 4;
      }

      var diff_hour = close_hour - open_hour - 1;
      diff_hour = diff_hour * 4;
      diff_hour = diff_hour + open_diff_hour + close_diff_hour;

      time_date.setHours(open_hour, min, 0, 0);

      var preparation_time = 0;
      if (this.user_helper.user_cart.ipickup_delivery) {
        preparation_time = this.store_detail["preparation_time_for_pickup"];
      } else {
        preparation_time = this.store_detail["preparation_time_for_delivery"];
        if (this.google_time) {
          preparation_time =
            preparation_time + Math.ceil(this.google_time / 60);
        }
      }

      var preparation_time_diff = 0;
      if (preparation_time) {
        preparation_time_diff = Math.ceil(preparation_time / 15);
        diff_hour = diff_hour - preparation_time_diff;
        preparation_time = 15 * preparation_time_diff;
        time_date.setMinutes(time_date.getMinutes() + preparation_time, 0, 0);
      }

      for (var i = 0; i < diff_hour; i++) {
        this.time_array[time_index] = {
          time_format:
            this.pad2(time_date.getHours()) +
            ":" +
            this.pad2(time_date.getMinutes()),
          time: time_date.getHours() + ":" + time_date.getMinutes(),
        };
        time_date.setMinutes(time_date.getMinutes() + 15);
        time_index++;
      }

      // this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
      // time_date.setMinutes(time_date.getMinutes()+15);
    });
    if (day_time.length == 0) {
      for (var i = 0; i < 1; i++) {
        this.time_array[i] = {
          time_format:
            this.pad2(time_date.getHours()) +
            ":" +
            this.pad2(time_date.getMinutes()),
          time: time_date.getHours() + ":" + time_date.getMinutes(),
        };
        time_date.setMinutes(time_date.getMinutes() + 15);
      }
    }
    // }

    for (var i = 0; i < 7; i++) {
      let date = new Date(server_date);
      date.setDate(date.getDate() + i);
      date = new Date(date);
      var day = days[date.getDay()];
      var month = months[date.getMonth()];
      var date_format = day + ", " + month + " " + date.getDate();
      this.date_array[i] = {
        week_day: week_day,
        date_format: date_format,
        date:
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate(),
      };

      if (week_day == 6) {
        week_day = 0;
      } else {
        week_day++;
      }
    }
    // this.user_helper.scheduleDatePickerOptions.disableUntil.year = server_date.getFullYear();
    // this.user_helper.scheduleDatePickerOptions.disableUntil.month = server_date.getMonth()+1;
    // this.user_helper.scheduleDatePickerOptions.disableUntil.day = server_date.getDate()-1;
    //
    // this.user_helper.scheduleDatePickerOptions.disableSince.year = after_date.getFullYear();
    // this.user_helper.scheduleDatePickerOptions.disableSince.month = after_date.getMonth()+1;
    // this.user_helper.scheduleDatePickerOptions.disableSince.day = after_date.getDate();

    let date = this.user_helper.user_cart.schedule_date;
    if (
      this.user_helper.user_cart.schedule_date !== null &&
      this.user_helper.user_cart.schedule_date !== ""
    ) {
      this.schedule_date =
        date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
      var day = days[date.getDay()];
      var month = months[date.getMonth()];
      this.clicked_date = day + ", " + month + " " + date.getDate();
      this.schedule_time = date.getHours() + ":" + date.getMinutes();
    } else {
      this.schedule_date = this.date_array[0].date;
      this.clicked_date = this.date_array[0].date_format;
      this.schedule_time = this.time_array[0].time_format;
    }
  }

  set_date(date) {
    this.clicked_date = date.date_format;
    this.schedule_date = date.date;

    let time_date = new Date();
    time_date.setHours(0, 0, 0, 0);
    this.time_array = [];
    // if(this.store_detail.store_time[date.week_day].is_store_open_full_time){
    //     for(var i=0; i<96; i++){
    //         this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
    //         time_date.setMinutes(time_date.getMinutes()+15);
    //     }
    // } else {
    let day_time = this.store_detail.store_time[date.week_day].day_time;
    day_time = day_time.filter((x) => {
      if (x.is_on == true || x.is_on == undefined) {
        return x;
      }
    });
    var time_index = 0;
    day_time.forEach((store_time, index) => {
      let open_time = store_time.store_open_time;
      open_time = open_time.split(":");

      var open_hour = Number(open_time[0]);
      var open_min = Number(open_time[1]);

      let close_time = store_time.store_close_time;
      close_time = close_time.split(":");

      var close_hour = Number(close_time[0]);
      var close_min = Number(close_time[1]);

      var min = 0;
      var open_diff_hour = 4;
      if (open_min >= 0 && open_min < 15) {
        min = 0;
        open_diff_hour = 4;
      } else if (open_min >= 15 && open_min < 30) {
        min = 15;
        open_diff_hour = 3;
      } else if (open_min >= 30 && open_min < 45) {
        min = 30;
        open_diff_hour = 2;
      } else if (open_min >= 45 && open_min < 60) {
        min = 45;
        open_diff_hour = 1;
      }

      var close_diff_hour = 1;
      if (close_min > 0 && close_min < 15) {
        close_diff_hour = 2;
      } else if (close_min > 15 && close_min < 30) {
        close_diff_hour = 3;
      } else if (close_min > 30 && close_min < 45) {
        close_diff_hour = 4;
      } else if (close_min > 45 && close_min < 60) {
        close_diff_hour = 5;
      }

      var diff_hour = close_hour - open_hour - 1;
      diff_hour = diff_hour * 4;
      diff_hour = diff_hour + open_diff_hour + close_diff_hour;
      time_date.setHours(open_hour, min, 0, 0);

      var preparation_time = 0;
      if (this.user_helper.user_cart.ipickup_delivery) {
        preparation_time = this.store_detail["preparation_time_for_pickup"];
      } else {
        preparation_time = this.store_detail["preparation_time_for_delivery"];
        if (this.google_time) {
          preparation_time =
            preparation_time + Math.ceil(this.google_time / 60);
        }
      }
      if (preparation_time) {
        var preparation_time_diff = Math.ceil(preparation_time / 15);
        diff_hour = diff_hour - preparation_time_diff;
        preparation_time = 15 * preparation_time_diff;
        time_date.setMinutes(time_date.getMinutes() + preparation_time, 0, 0);
      }

      for (var i = 0; i < diff_hour; i++) {
        this.time_array[time_index] = {
          time_format:
            this.pad2(time_date.getHours()) +
            ":" +
            this.pad2(time_date.getMinutes()),
          time: time_date.getHours() + ":" + time_date.getMinutes(),
        };
        time_date.setMinutes(time_date.getMinutes() + 15);
        time_index++;
      }

      // this.time_array[i] = {time_format: this.pad2(time_date.getHours()) + ':' + this.pad2(time_date.getMinutes()), time: time_date.getHours() + ':' + time_date.getMinutes()}
      // time_date.setMinutes(time_date.getMinutes()+15);
    });
    if (day_time.length == 0) {
      for (var i = 0; i < 1; i++) {
        this.time_array[i] = {
          time_format:
            this.pad2(time_date.getHours()) +
            ":" +
            this.pad2(time_date.getMinutes()),
          time: time_date.getHours() + ":" + time_date.getMinutes(),
        };
        time_date.setMinutes(time_date.getMinutes() + 15);
      }
    }
    // }

    $(".dropdown2 dt a#date")
      .parents()
      .siblings()
      .children(".dropdown2 dd ul")
      .toggle()
      .closest(".select_div")
      .removeClass("ar_rot");
    $(".dropdown2 dt a#time")
      .parents()
      .siblings()
      .children(".dropdown2 dd ul")
      .toggle()
      .closest(".select_div")
      .addClass("ar_rot");

    // $('#date').parents().siblings().children(".dropdown2 dd ul").toggle().closest(".select_div").toggleClass("ar_rot");
  }
  set_time(time) {
    this.schedule_time = time.time_format;
    $(".dropdown2 dt a#time")
      .parents()
      .siblings()
      .children(".dropdown2 dd ul")
      .toggle()
      .closest(".select_div")
      .toggleClass("ar_rot");
    this.set_order_time(true);
  }

  set_order_time(boolean) {
    this.is_schedule_order = boolean;
    if (boolean) {
      if (this.schedule_date !== "" && this.schedule_time !== "") {
        let server_date: any = new Date(this.user_helper.user_cart.server_date);
        server_date = new Date(server_date).toLocaleString("en-US", {
          timeZone: this.user_helper.user_cart.timezone,
        });
        server_date = new Date(server_date);

        let date = JSON.parse(JSON.stringify(this.schedule_date.split("-")));
        let time = this.schedule_time.split(":");

        let selected_date: any = new Date(Date.now());
        selected_date = new Date(selected_date).toLocaleString("en-US", {
          timeZone: this.user_helper.user_cart.timezone,
        });
        selected_date = new Date(selected_date);
        selected_date.setDate(date[2]);
        selected_date.setMonth(date[1] - 1);
        selected_date.setFullYear(date[0]);
        selected_date.setHours(time[0], time[1], 0, 0);
        let timeDiff = Math.round(
          selected_date.getTime() - server_date.getTime()
        );

        if (
          timeDiff / 60000 >=
          this.store_detail.schedule_order_create_after_minute
        ) {
          this.schedule_time_error = false;
          $(".date_time").siblings(".dropdown_div").toggle();
          this.user_helper.user_cart.schedule_date = selected_date;
          this.user_helper.user_cart.clicked_date =
            this.clicked_date + " " + this.schedule_time;
          this.user_helper.user_cart.is_schedule_order = this.is_schedule_order;
          this.check_valid_time();
        } else {
          this.schedule_time_error = true;
        }
      } else {
        this.schedule_time_error = true;
      }
    } else {
      this.user_helper.user_cart.schedule_date = null;
      this.schedule_time_error = false;
      this.schedule_date = "";
      this.schedule_time = "";
      $("#schedule_time").val("");
      this.user_helper.user_cart.is_schedule_order = this.is_schedule_order;
      let date: any = this.user_helper.user_cart.server_date;
      date = new Date(date).toLocaleString("en-US", {
        timeZone: this.user_helper.user_cart.timezone,
      });
      date = new Date(date);
      this.check_open(date, true);
      //$('.date_time').siblings('.dropdown_div').toggle();
    }

    this.user_helper.add_to_cart();
    // this.get_order_invoice(this.google_distance, this.google_time)
  }

  get_payment_gateway(current_location) {
    if (current_location === null) {
      current_location = {};
    }

    if (!this.user_helper.user_cart.is_main_website) {
      current_location.city_id =
        this.user_helper.user_cart.website_detail.city_id;
    } else {
      current_location.city_id = this.city_id;
    }

    current_location.user_id = this.user_id;

    current_location.server_token = this.server_token;
    current_location.myLoading = false;

    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.GET_PAYMENT_GATEWAY,
      current_location,
      (res_data) => {
        this.wallet = res_data.wallet;
        this.wallet_currency_code = res_data.wallet_currency_code;
        this.is_use_wallet = res_data.is_use_wallet;
        if (res_data.success) {
          this.user_helper.myLoading = false;
          this.is_cash_payment_mode = res_data.is_cash_payment_mode;
          this.payment_gateway = res_data.payment_gateway;

          if (this.payment_gateway.length > 0) {
            this.selected_payment_gateway = this.payment_gateway[0]._id;
          } else if (this.is_cash_payment_mode) {
            this.selected_payment_gateway = "cash";
          }
          if (this.payment_gateway.length > 0) {
            let index = this.payment_gateway.findIndex(
              (x) =>
                x._id.toString() ==
                this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE
            );

            if (index !== -1) {
              this.get_card();
            }
          }
        } else {
        }
      }
    );
  }

  get_card() {
    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.GET_CARD_LIST,
      {
        myLoading: false,
        user_id: this.user_id,
        server_token: this.server_token,
      },
      (res_data) => {
        if (res_data.success) {
          this.card_list = res_data.cards;
        } else {
        }
      }
    );
  }

  select_card(card_id, card_index) {
    if (!this.card_list[card_index].is_default) {
      this.user_helper.myLoading = true;
      this.user_helper.http_post_method_requester(
        this.user_helper.POST_METHOD.SELECT_CARD,
        {
          user_id: this.user_id,
          server_token: this.server_token,
          card_id: card_id,
        },
        (res_data) => {
          this.user_helper.myLoading = false;
          if (res_data.success) {
            let index = this.card_list.findIndex((x) => x.is_default == true);
            if (index !== -1) {
              this.card_list[index].is_default = false;
              this.card_list[card_index].is_default = true;
            }
          } else {
            this.user_helper.data.storage = {
              message: this.user_helper.ERROR_CODE[res_data.error_code],
              class: "alert-danger",
            };
          }
        }
      );
    }
  }

  get_distnce_time() {
    if (!this.user_helper.user_cart.ipickup_delivery) {
      if (this.delivery_location[0] && this.delivery_location[1]) {
        let google_distance = 0;
        let google_time = 0;
        let origin = {
          lat: parseFloat(
            this.user_helper.user_cart.cart_data.pickup_addresses[0].location[0]
          ),
          lng: parseFloat(
            this.user_helper.user_cart.cart_data.pickup_addresses[0].location[1]
          ),
        };
        let destination = {
          lat: parseFloat(this.delivery_location[0]),
          lng: parseFloat(this.delivery_location[1]),
        };

        let service = new google.maps.DistanceMatrixService();
        service.getDistanceMatrix(
          {
            origins: [origin],
            destinations: [destination],
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
            travelMode: google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status == google.maps.DistanceMatrixStatus.OK) {
              this.google_distance =
                response.rows[0].elements[0].distance != undefined
                  ? response.rows[0].elements[0].distance.value
                  : 0;
              this.google_time =
                response.rows[0].elements[0].duration != undefined
                  ? response.rows[0].elements[0].duration.value
                  : 0;
              this.get_order_invoice(this.google_distance, this.google_time);
            } else {
              this.get_order_invoice(this.google_distance, this.google_time);
            }
            // this.get_order_invoice(0,0)
          }
        );
      } else {
        this.get_order_invoice(0, 0);
      }
    } else {
      this.get_order_invoice(0, 0);
    }
  }

  edit_address_field() {
    this.edit_address = true;
  }

  update_address() {
    this.edit_address = false;

    if (this.delivery_location[0] && this.delivery_location[1]) {
      let json = {
        latitude: this.delivery_location[0],
        longitude: this.delivery_location[1],
        destination_address: this.delivery_address,
        destination_addresses: [
          {
            delivery_status: 0,
            address_type: "destination",
            address: this.delivery_address,
            postal_code: this.postal_code,
            country_code: this.country_code,
            city: this.city,
            country: this.country,
            state: this.state,
            entry_code: this.entry_code,
            floor: this.floor,
            location: this.delivery_location,
            note: this.delivery_note,
            user_type:
              this.user_helper.user_cart.cart_data.destination_addresses[0]
                .user_type,
            user_details:
              this.user_helper.user_cart.cart_data.destination_addresses[0]
                .user_details,
          },
        ],
        cart_id: this.cart_data.cart_id,
      };
      this.user_helper.http_post_method_requester(
        this.user_helper.POST_METHOD.CHANGE_DELIVERY_ADDRESS,
        json,
        (res_data) => {
          if (res_data.success) {
            this.user_helper.user_cart.cart_data.destination_addresses[0].postal_code =
              this.postal_code;
            this.user_helper.user_cart.cart_data.destination_addresses[0].city =
              this.city;
            this.user_helper.user_cart.cart_data.destination_addresses[0].country =
              this.country;
            this.user_helper.user_cart.cart_data.destination_addresses[0].country_code =
              this.country_code;
            this.user_helper.user_cart.cart_data.destination_addresses[0].state =
              this.state;
            this.user_helper.user_cart.cart_data.destination_addresses[0].address =
              this.delivery_address;
            this.user_helper.user_cart.cart_data.destination_addresses[0].location =
              this.delivery_location;
            this.user_helper.user_cart.cart_data.destination_addresses[0].floor =
              this.floor;
            this.user_helper.user_cart.cart_data.destination_addresses[0].entry_code =
              this.entry_code;
            this.user_helper.user_cart.cart_data.destination_addresses[0].note =
              this.delivery_note;
            this.get_distnce_time();
            this.address_error = "";
          } else {
            this.delivery_location =
              this.user_helper.user_cart.cart_data.destination_addresses[0].location;
            this.delivery_address =
              this.user_helper.user_cart.cart_data.destination_addresses[0].address;
            this.postal_code =
              this.user_helper.user_cart.cart_data.destination_addresses[0].postal_code;
            this.country_code =
              this.user_helper.user_cart.cart_data.destination_addresses[0].country_code;
            this.city =
              this.user_helper.user_cart.cart_data.destination_addresses[0].city;
            this.country =
              this.user_helper.user_cart.cart_data.destination_addresses[0].country;
            this.state =
              this.user_helper.user_cart.cart_data.destination_addresses[0].state;
            $("#basket_address").val(this.delivery_address);
            this.floor = "";
            this.entry_code = "";
            this.delivery_note = "";
            this.selected_address_id = "";
            this.address_error =
              "Oups, vous avez entré une adresse que nous livrons pas encore, n’hésitez pas à nous contacter pour des renseignements";
          }
        }
      );
    }
  }

  check_promo(promo_response = null) {
    if (this.promocode !== "") {
      this.user_helper.myLoading = true;
      let json = {
        user_id: this.user_id,
        server_token: this.server_token,
        promo_code_name: this.promocode,
        order_payment_id: this.order_payment._id,
      };
      this.user_helper.http_post_method_requester(
        this.user_helper.POST_METHOD.APPLY_PROMO_CODE,
        json,
        (res_data) => {
          this.user_helper.myLoading = false;
          if (res_data.success) {
            this.promo_error = 0;
            this.promo_applied = true;
            this.user_helper.data.storage = {
              message: this.user_helper.MESSAGE_CODE[res_data.message],
              class: "alert-info",
            };
            this.order_payment = res_data.order_payment;
            this.promo_code_detail = res_data.promo_code_detail;
            if (this.order_payment.is_store_pay_delivery_fees) {
              this.order_payment.total =
                this.order_payment.total -
                this.order_payment.total_service_price -
                this.order_payment.total_admin_tax_price;
            }
          } else {
            this.user_helper.data.storage = {
              message: this.user_helper.ERROR_CODE[res_data.error_code],
              class: "alert-danger",
            };
            if (res_data.promo_code_apply_on_minimum_amount) {
              this.promo_error = 2;
              this.promo_code_apply_on_minimum_amount =
                res_data.promo_code_apply_on_minimum_amount;
            } else {
              this.promo_error = 1;
            }
          }
          if (promo_response) {
            promo_response({ success: true });
          }
          this.user_helper.message();
        }
      );
    } else {
      if (promo_response) {
        promo_response({ success: true });
      }
    }
  }

  get_order_invoice(google_distance, google_time) {
    if (this.is_invoice_response) {
      this.is_invoice_response = false;
      this.user_helper.myLoading = true;
      let totalItemsCount = 0;
      // this.google_distance = google_distance;
      // this.google_time = google_time;
      this.cart_data.cart.forEach((cart_product) => {
        cart_product.items.forEach((cart_item) => {
          totalItemsCount += cart_item.quantity;
        });
      });

      let get_order_cart_invoice_json = {
        user_id: this.user_id,
        cart_unique_token: "",
        total_distance: google_distance,
        total_time: google_time,
        store_id: this.cart_data.selectedStoreId,
        order_type: 7,
        total_cart_price: this.user_helper.user_cart.total_cart_amount,
        total_item_count: totalItemsCount,
        is_user_pick_up_order: this.user_helper.user_cart.ipickup_delivery,
        is_offer_apply: true,
        is_allow_minimum: true,
        is_add_price_in_menu: true,
      };

      if (this.user_id === "") {
        get_order_cart_invoice_json.cart_unique_token = this.cart_unique_token;
      }
      this.user_helper.http_post_method_requester(
        this.user_helper.POST_METHOD.GET_ORDER_CART_INVOICE,
        get_order_cart_invoice_json,
        (res_data) => {
          this.is_invoice_response = true;
          this.user_helper.myLoading = false;
          this.user_helper.ngZone.run(() => {
            if (res_data.success) {
              this.delivery_time = res_data.delivery_time;
              this.is_emergency = res_data.is_emergency;
              this.emergency_close_till = res_data.emergency_close_till;
              this.check_promo();
              this.user_helper.user_cart.server_date = res_data.server_time;
              this.user_helper.user_cart.timezone = res_data.timezone;

              this.order_payment = res_data.order_payment;
              if (this.order_payment.is_store_pay_delivery_fees) {
                this.order_payment.total =
                  this.order_payment.total -
                  this.order_payment.total_service_price -
                  this.order_payment.total_admin_tax_price;
              }
              this.user_helper.user_cart.order_payment_id =
                res_data.order_payment._id;
              this.store_detail = res_data.store;
              this.is_delay_order = res_data.is_delay_order;
              this.close_till = res_data.close_till;
              this.delay_distance = res_data.delay_distance;
              this.delay_time = res_data.delay_time;
              this.store_detail.is_pickup_possible = true;
              this.store_detail.is_delivery_possible = true;
              this.check_delivery_offer();

              if (this.user_helper.user_cart.is_schedule_order) {
                this.check_valid_time();
              } else {
                let date: any = res_data.server_time;
                date = new Date(date).toLocaleString("en-US", {
                  timeZone: res_data.timezone,
                });
                date = new Date(date);
                this.check_open(date, true);
              }
              this.disable_place_holder_button = false;

              if (this.delivery_currency.toLowerCase() == "chf") {
                var paymentRequest = stripe.paymentRequest({
                  country: "CH",
                  currency: this.delivery_currency.toLowerCase() || "chf",
                  total: {
                    label: "total",
                    amount: Math.round(this.order_payment.total * 100),
                  },
                  requestPayerName: true,
                  requestPayerEmail: true,
                  disableWallets: ["browserCard"],
                });

                var elements = stripe.elements();
                this.prButton = elements.create("paymentRequestButton", {
                  paymentRequest: paymentRequest,
                });

                // // Check the availability of the Payment Request API first.
                paymentRequest.canMakePayment().then(
                  (result) => {
                    if (result) {
                      // this.googlePay = result.googlePay;
                      // this.applePay = result.applePay;
                    } else {
                      // document.getElementById('payment-request-button').style.display = 'none';
                    }
                  },
                  (error) => {
                    console.log(error);
                  }
                );

                paymentRequest.on("paymentmethod", (ev) => {
                  this.pay_order_payment(false, ev);
                });
              }
              this.cart_offer_error = res_data.cart_offer_error;
              console.log(this.cart_offer_error);
            } else {
              this.delivery_time = [];
              this.cart_offer_error = "";
              if (res_data.error_code === 563) {
                this.user_helper.data.storage = {
                  message: this.user_helper.ERROR_CODE[res_data.error_code],
                  class: "alert-danger",
                };
                this.user_helper.message();
                this.address_error =
                  "Oups, vous avez entré une adresse que nous livrons pas encore, n’hésitez pas à nous contacter pour des renseignements";
              } else if (res_data.error_code === 557) {
                this.mimimum_amount = res_data.min_order_price;
                $("#order_amount_invalid").click();
              } else {
                this.user_helper.data.storage = {
                  message: this.user_helper.ERROR_CODE[res_data.error_code],
                  class: "alert-danger",
                };
                this.user_helper.message();
                this.disable_place_holder_button = true;
              }
            }
          });
        }
      );
    }
  }

  change_pickup(ipickup_delivery) {
    if (!ipickup_delivery) {
      this.user_helper.user_cart.ipickup_delivery = ipickup_delivery;
      if (!this.google_distance) {
        this.get_distnce_time();
      } else {
        this.get_order_invoice(this.google_distance, this.google_time);
      }
    } else {
      this.user_helper.user_cart.ipickup_delivery = ipickup_delivery;
      this.get_order_invoice(0, 0);
    }
    this.selected_payment_gateway =
      this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE;
  }

  redirect_to_checkout() {
    // this.order_amount_invalid.close();
    // this.location.back();
    $("#order_amount_invalid_close").click();
  }

  check_delivery_open(server_date, timezone) {
    let date: any = server_date;
    date = new Date(date).toLocaleString("en-US", { timeZone: timezone });
    date = new Date(date);
    let weekday = date.getDay();
    let current_time = date.getTime();
    this.is_delivery_available = false;
    this.store_detail.close = true;
    this.store_detail.nextopentime = "";

    let week_index = this.delivery_time.findIndex((x) => x.day == weekday);
    if (this.delivery_time[week_index].is_delivery_open) {
      let day_time = this.delivery_time[week_index].day_time;
      if (day_time.length == 0) {
        this.is_delivery_available = false;
        this.store_detail["is_delivery_possible"] = false;
        this.store_detail["is_pickup_possible"] = true;
      } else {
        day_time.forEach((store_time, index) => {
          let open_time = store_time.delivery_open_time;
          open_time = open_time.split(":");
          let open_date_time = date.setHours(open_time[0], open_time[1], 0, 0);
          open_date_time = new Date(open_date_time);
          open_date_time = open_date_time.getTime();

          let close_time = store_time.delivery_close_time;
          close_time = close_time.split(":");
          let close_date_time = date.setHours(
            close_time[0],
            close_time[1],
            0,
            0
          );
          close_date_time = new Date(close_date_time);
          close_date_time = close_date_time.getTime();

          if (current_time > open_date_time && current_time < close_date_time) {
            this.is_delivery_available = true;
            this.store_detail.close = false;
          }
          if (
            current_time < open_date_time &&
            this.store_detail.nextopentime == ""
          ) {
            this.store_detail.nextopentime = store_time.delivery_open_time;
            this.store_detail["is_delivery_possible"] = false;
            this.store_detail["is_pickup_possible"] = true;
          }
        });
      }
    } else {
      this.is_delivery_available = false;
      this.store_detail["is_delivery_possible"] = false;
      this.store_detail["is_pickup_possible"] = true;
    }
    if (!this.is_delivery_available) {
      // var date:any = new Date();
      this.user_helper.user_cart.is_schedule_order = true;
      this.user_helper.user_cart.schedule_date = date;
      var days = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
      var months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      var day = days[date.getDay()];
      var month = months[date.getMonth()];
      this.clicked_date = day + ", " + month + " " + date.getDate();
      this.user_helper.user_cart.clicked_date =
        this.clicked_date + " " + date.getHours() + ":" + date.getMinutes();
    }
  }

  check_open(selected_date, today) {
    var date: any = JSON.parse(JSON.stringify(selected_date));
    date = new Date(date);
    let weekday = date.getDay();
    let current_time = date.getTime();
    if (this.store_detail.is_business) {
      this.store_detail.close = true;
      this.store_detail.conges = false;
      this.store_detail.nextopentime = "";
      this.store_detail["is_delivery_possible"] = false;
      this.store_detail["is_pickup_possible"] = false;

      var current_date = new Date();
      var current_week_day = current_date.getDay();

      if (today) {
        this.store_open_day = this.title.today;
      } else {
        this.store_open_day = this.user_helper.WEEK_DAY[weekday];
      }

      let week_index = this.store_detail.store_time.findIndex(
        (x) => x.day == weekday
      );

      if (this.store_detail.store_time[week_index].is_store_open) {
        let day_time = this.store_detail.store_time[week_index].day_time;
        day_time = day_time.filter((x) => {
          if (x.is_on == true || x.is_on == undefined) {
            return x;
          }
        });
        if (day_time.length == 0) {
          this.store_detail.close = true;
          this.store_detail["is_delivery_possible"] = false;
          this.store_detail["is_pickup_possible"] = false;
          this.check_next_open_time(selected_date, week_index, 0, null, 0);
        } else {
          day_time.forEach((store_time, index) => {
            let open_time = store_time.store_open_time;
            open_time = open_time.split(":");
            let x = date.setHours(open_time[0], open_time[1], 0, 0);
            let x1 = new Date(x);

            if (this.is_schedule_order) {
              var preparation_time = 0;
              if (this.user_helper.user_cart.ipickup_delivery) {
                preparation_time =
                  this.store_detail["preparation_time_for_pickup"];
              } else {
                preparation_time =
                  this.store_detail["preparation_time_for_delivery"];
                if (this.google_time) {
                  preparation_time =
                    preparation_time + Math.ceil(this.google_time / 60);
                }
              }
              x1 = new Date(x1.setMinutes(x1.getMinutes() + preparation_time));
            }

            let x2 = x1.getTime();

            let close_time = store_time.store_close_time;
            close_time = close_time.split(":");
            let y = date.setHours(close_time[0], close_time[1], 0, 0);
            let y1 = new Date(y);
            let y2 = y1.getTime();

            if (current_time >= x2 && current_time <= y2) {
              this.store_detail.close = false;
              this.store_detail["is_delivery_possible"] = true;
              this.store_detail["is_pickup_possible"] = true;
            }

            if (current_time < x2 && this.store_detail.nextopentime == "") {
              this.store_detail.nextopentime = store_time.store_open_time;
            }
          });
          if (this.store_detail.close && !this.store_detail.nextopentime) {
            this.check_next_open_time(selected_date, week_index, 0, null, 0);
          }
        }
      } else {
        this.store_detail.close = true;
        this.store_detail["is_delivery_possible"] = false;
        this.store_detail["is_pickup_possible"] = false;
        this.check_next_open_time(selected_date, week_index, 0, null, 0);
      }

      if (!this.store_detail.close) {
        if (!this.store_detail.is_stop_new_order_all_day) {
          if (this.store_detail.close_till) {
            let close_till = this.store_detail.close_till;
            close_till = close_till.split(":");
            let open_date_time = date.setHours(
              close_till[0],
              close_till[1],
              0,
              0
            );
            open_date_time = new Date(open_date_time);

            if (this.is_schedule_order) {
              var preparation_time = 0;
              if (this.user_helper.user_cart.ipickup_delivery) {
                preparation_time =
                  this.store_detail["preparation_time_for_pickup"];
              } else {
                preparation_time =
                  this.store_detail["preparation_time_for_delivery"];
                if (this.google_time) {
                  preparation_time =
                    preparation_time + Math.ceil(this.google_time / 60);
                }
              }
              open_date_time = new Date(
                open_date_time.setMinutes(
                  open_date_time.getMinutes() + preparation_time
                )
              );
            }

            open_date_time = open_date_time.getTime();
            if (current_time < open_date_time) {
              this.store_detail.close = true;
              this.store_detail["is_delivery_possible"] = false;
              this.store_detail["is_pickup_possible"] = false;
              this.store_detail.nextopentime = this.store_detail.close_till;
              if (this.store_detail.store_order[0] == 1) {
                this.store_detail["is_delivery_possible"] = true;
                this.store_detail["is_pickup_possible"] = false;
              } else if (this.store_detail.store_order[0] == 2) {
                this.store_detail["is_delivery_possible"] = false;
                this.store_detail["is_pickup_possible"] = true;
              }
            }
          }
        } else {
          const today = new Date();
          if (
            date.getDate() == today.getDate() &&
            date.getMonth() == today.getMonth() &&
            date.getFullYear() == today.getFullYear()
          ) {
            this.store_detail.close = true;
            this.store_detail["is_delivery_possible"] = false;
            this.store_detail["is_pickup_possible"] = false;
            this.store_detail.nextopentime = "";
            if (this.store_detail.is_stop_new_order_all_day) {
              if (this.store_detail.store_order[0] == 1) {
                this.store_detail["is_delivery_possible"] = true;
                this.store_detail["is_pickup_possible"] = false;
              } else if (this.store_detail.store_order[0] == 2) {
                this.store_detail["is_delivery_possible"] = false;
                this.store_detail["is_pickup_possible"] = true;
              }
            }
          }
        }
      }

      if (
        !this.store_detail.close &&
        !this.user_helper.user_cart.ipickup_delivery &&
        this.is_emergency &&
        this.emergency_close_till
      ) {
        var close_till_time = 0;
        let close_till: any = new Date(this.emergency_close_till);
        close_till = new Date(close_till).toLocaleString("en-US", {
          timeZone: this.user_helper.user_cart.timezone,
        });
        close_till = new Date(close_till);
        if (this.user_helper.user_cart.schedule_date) {
          let selected_date: any = new Date(
            this.user_helper.user_cart.schedule_date
          );
          selected_date = new Date(selected_date).toLocaleString("en-US", {
            timeZone: this.user_helper.user_cart.timezone,
          });
          selected_date = new Date(selected_date);

          close_till_time = Math.round(
            close_till.getTime() - selected_date.getTime()
          );
        }

        if (close_till_time > 0 || !this.user_helper.user_cart.schedule_date) {
          this.store_detail.close = true;
          this.store_detail["is_delivery_possible"] = false;
          this.store_detail["is_pickup_possible"] = true;
          this.store_detail["is_delivery_stop_admin"] = true;
          this.store_detail.nextopentime =
            close_till.getHours() + ":" + close_till.getMinutes();
        }
      }
    } else {
      this.store_detail["is_delivery_possible"] = false;
      this.store_detail["is_pickup_possible"] = false;
      this.store_detail.close = true;
      this.store_detail.nextopentime = "";
    }

    if (
      this.store_detail.store_conges &&
      this.store_detail.store_conges.length > 0
    ) {
      this.check_conges(selected_date, this.user_helper.user_cart.timezone);
    }

    if (!this.store_detail.close) {
      this.check_delay_order();
    }

    if (
      this.store_detail.nextopentime &&
      !this.store_detail.is_delivery_stop_admin
    ) {
      if (this.is_schedule_order) {
        var preparation_time = 0;
        if (this.user_helper.user_cart.ipickup_delivery) {
          preparation_time = this.store_detail["preparation_time_for_pickup"];
        } else {
          preparation_time = this.store_detail["preparation_time_for_delivery"];
          if (this.google_time) {
            preparation_time =
              preparation_time + Math.ceil(this.google_time / 60);
          }
        }
        var nextopentime = this.store_detail.nextopentime.split(":");
        var date: any = new Date();
        date = new Date(
          date.setHours(
            Number(nextopentime[0].slice(-2)),
            Number(nextopentime[1]),
            0,
            0
          )
        );
        date = new Date(
          date.setMinutes(date.getMinutes() + preparation_time, 0, 0)
        );
        this.store_detail["next_order_time"] =
          ("0" + date.getHours()).slice(-2) +
          ":" +
          ("0" + date.getMinutes()).slice(-2);
      } else {
        this.store_detail["next_order_time"] = "";
      }
    }

    if (
      !this.store_detail.close &&
      !this.user_helper.user_cart.ipickup_delivery
    ) {
      console.log("check_delivery_open");
      this.check_delivery_open(
        selected_date,
        this.user_helper.user_cart.timezone
      );
    } else {
      this.is_delivery_available = true;
    }
  }

  check_conges(server_date, timezone) {
    let date: any = server_date;
    date = new Date(date).toLocaleString("en-US", { timeZone: timezone });
    date = new Date(date);
    var current_time = date.getTime();
    this.store_detail.store_conges.forEach((store_conges_detail) => {
      var start_time: any = new Date(
        store_conges_detail.start_time
      ).toLocaleString("en-US", { timeZone: timezone });
      start_time = new Date(start_time);
      var end_time: any = new Date(store_conges_detail.end_time).toLocaleString(
        "en-US",
        { timeZone: timezone }
      );
      end_time = new Date(end_time);
      if (
        start_time.getTime() <= current_time &&
        end_time.getTime() > current_time
      ) {
        this.store_detail.close = true;
        this.store_detail["is_delivery_possible"] = false;
        this.store_detail["is_pickup_possible"] = false;
        this.store_detail.conges = true;
        const diffTime = Math.abs(end_time.getTime() - date.getTime());
        const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
        // if(diffDays<=7){
        this.store_detail.conges = false;
        let weekday = end_time.getDay();
        this.store_detail.nextopentime = "";
        // console.log(end_time)
        this.check_next_open_time(
          server_date,
          weekday - 1,
          diffDays - 1,
          end_time.getTime(),
          0
        );
        // } else {
        //     this.store_detail.nextopentime = 'Partenaire en congé Précommandez, réouvre le '+moment(end_time).tz(timezone !== undefined ? timezone: 'Europe/Zurich').format('DD/MM à HH:mm');
        // }
      }
    });
  }

  check_delay_order() {
    if (
      !this.user_helper.user_cart.ipickup_delivery &&
      this.is_delay_order &&
      this.google_distance / 1000 >= this.delay_distance
    ) {
      var close_till_time = 0;
      if (this.user_helper.user_cart.schedule_date && this.close_till) {
        let selected_date: any = new Date(
          this.user_helper.user_cart.schedule_date
        );
        selected_date = new Date(selected_date).toLocaleString("en-US", {
          timeZone: this.user_helper.user_cart.timezone,
        });
        selected_date = new Date(selected_date);

        let close_till: any = new Date(this.close_till);
        close_till = new Date(close_till).toLocaleString("en-US", {
          timeZone: this.user_helper.user_cart.timezone,
        });
        close_till = new Date(close_till);

        close_till_time = Math.round(
          close_till.getTime() - selected_date.getTime()
        );
      }

      if (close_till_time > 0 || !this.user_helper.user_cart.schedule_date) {
        this.delay_order_error = true;
      } else {
        this.delay_order_error = false;
      }
    } else {
      this.delay_order_error = false;
    }
  }

  check_next_open_time(server_date, week_index, count, end_time, loop_count) {
    count++;
    var date: any = JSON.parse(JSON.stringify(server_date));
    date = new Date(date).toLocaleString("en-US", {
      timeZone: this.user_helper.user_cart.timezone,
    });
    date = new Date(date);

    var date_time = JSON.parse(JSON.stringify(server_date));

    var days = [
      "Dimanche",
      "Lundi",
      "Mardi",
      "Mercredi",
      "Jeudi",
      "Vendredi",
      "Samedi",
    ];
    if (week_index == 6) {
      week_index = 0;
    } else {
      week_index++;
    }
    date_time = new Date(date_time);
    date_time = date_time.setDate(date_time.getDate() + count);
    date_time = new Date(date_time);
    let current_time = date.getTime();

    if (loop_count !== 7) {
      loop_count++;
      let day_time = this.store_detail.store_time[week_index].day_time;
      day_time = day_time.filter((x) => {
        if (x.is_on == true || x.is_on == undefined) {
          return x;
        }
      });
      if (this.store_detail.store_time[week_index].is_store_open) {
        // if(this.store_detail.store_time[week_index].is_store_open_full_time){
        //     this.store_detail.nextopentime = days[week_index];
        // }
        // else {

        if (day_time.length == 0) {
          this.check_next_open_time(
            server_date,
            week_index,
            count,
            end_time,
            loop_count
          );
        } else {
          day_time.forEach((store_time, index) => {
            let open_time = store_time.store_open_time;
            open_time = open_time.split(":");
            let open_date_time = date_time.setHours(
              open_time[0],
              open_time[1],
              0,
              0
            );
            open_date_time = new Date(open_date_time);
            open_date_time = open_date_time.getTime();

            let close_time = store_time.store_close_time;
            close_time = close_time.split(":");
            let close_date_time = date_time.setHours(
              close_time[0],
              close_time[1],
              0,
              0
            );
            close_date_time = new Date(close_date_time);
            close_date_time = close_date_time.getTime();

            if (!end_time || close_date_time >= end_time) {
              if (
                current_time < open_date_time &&
                !this.store_detail.nextopentime
              ) {
                if (!end_time || open_date_time >= end_time) {
                  const diffTime = Math.abs(open_date_time - current_time);
                  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                  if (diffDays < 7) {
                    var date = new Date(
                      date_time.setHours(open_time[0], open_time[1], 0, 0)
                    );
                    this.store_detail.nextopentime =
                      days[date.getDay()] + " à " + store_time.store_open_time;
                  } else {
                    open_date_time = new Date(open_date_time);
                    this.store_detail.nextopentime =
                      open_date_time.getDate() +
                      "/" +
                      (open_date_time.getMonth() + 1) +
                      " à " +
                      store_time.store_open_time;
                  }
                  if (end_time) {
                    this.store_detail.conges = true;
                    this.store_detail.nextopentime =
                      "Partenaire en congé Précommandez, réouvre le " +
                      this.store_detail.nextopentime;
                  }
                  //this.store_detail.close_date_time = JSON.parse(JSON.stringify(new Date(date_time.setHours(open_time[0] , open_time[1], 0 ,0)).getTime()));
                } else {
                  const diffTime = Math.abs(end_time - current_time);
                  const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));
                  var end_time_date = new Date(end_time);
                  var hours = ("0" + end_time_date.getHours()).slice(-2);
                  var minutes = ("0" + end_time_date.getMinutes()).slice(-2);
                  if (diffDays < 7) {
                    var date = new Date(
                      date_time.setHours(
                        end_time_date.getHours(),
                        end_time_date.getMinutes(),
                        0,
                        0
                      )
                    );
                    this.store_detail.nextopentime =
                      days[date.getDay()] + " à " + hours + ":" + minutes;
                  } else {
                    this.store_detail.nextopentime =
                      end_time_date.getDate() +
                      "/" +
                      (end_time_date.getMonth() + 1) +
                      " à " +
                      hours +
                      ":" +
                      minutes;
                  }
                  this.store_detail.nextopentime =
                    "Partenaire en congé Précommandez, réouvre le " +
                    this.store_detail.nextopentime;
                  this.store_detail.conges = true;
                  // this.store_detail.close_date_time = JSON.parse(JSON.stringify(new Date(date_time.setHours(end_time_date.getHours() , end_time_date.getMinutes(), 0 ,0)).getTime()));
                }
              }
            }
          });
          if (!this.store_detail.nextopentime) {
            this.check_next_open_time(
              server_date,
              week_index,
              count,
              end_time,
              loop_count
            );
          }
        }

        // }
      } else {
        this.check_next_open_time(
          server_date,
          week_index,
          count,
          end_time,
          loop_count
        );
      }
    }
  }

  check_delivery_offer() {
    this.delivery_offers = [];
    var offer_list = this.store_detail.offers.filter(
      (offer) => offer.offer_type == 3
    );
    offer_list.forEach((offer_detail) => {
      var offer_message = "";
      if (offer_detail.discount_type == 0) {
        offer_message =
          offer_detail.discount_value + "% des frais de livraison sont offerts";
      } else if (offer_detail.discount_type == 1) {
        offer_message =
          offer_detail.discount_value +
          this.delivery_currency +
          " des frais de livraison sont offerts";
      } else if (offer_detail.discount_type == 2) {
        offer_message = "Frais de livraisons offerts";
      }
      if (offer_detail.is_min_order_price) {
        offer_message =
          offer_message +
          " dès " +
          offer_detail.min_order_price +
          this.delivery_currency;
      }
      this.delivery_offers.push(offer_message);
    });
  }

  set_current_location() {
    this.use_an_address = false;
    this.floor = "";
    this.entry_code = "";
    this.delivery_note = "";
    navigator.geolocation.getCurrentPosition((position) => {
      this.geocoder(position.coords.latitude, position.coords.longitude);
    });
  }
  go_to_order() {
    if (this.user_helper.user_cart.is_main_website) {
      this.user_helper.router.navigate(["/home"]);
    } else {
      this.user_helper.router.navigate(["/commander"]).then(() => {
        window.location.reload();
      });
    }
  }

  geocoder(latitude, longitude) {
    this.delivery_location = [latitude, longitude];
    var initialLocation = new google.maps.LatLng(latitude, longitude);
    var geocoder = new google.maps.Geocoder();

    let request = { latLng: initialLocation };
    geocoder.geocode(request, (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        this.user_helper.ngZone.run(() => {
          this.delivery_address = results[0].formatted_address;
          for (var i = 0; i < results[0].address_components.length; i++) {
            if (results[0].address_components[i].types[0] == "locality") {
              this.city = results[0].address_components[i].long_name;
            } else if (
              results[0].address_components[i].types[0] ==
              "administrative_area_level_1"
            ) {
              this.state = results[0].address_components[i].long_name;
            } else if (results[0].address_components[i].types[0] == "country") {
              this.country = results[0].address_components[i].long_name;
              this.country_code = results[0].address_components[i].short_name;
            } else if (
              results[0].address_components[i].types[0] == "postal_code"
            ) {
              this.postal_code = results[0].address_components[i].long_name;
            }
          }
          $("#basket_address").val(this.delivery_address);
          this.update_address();
        });
      }
    });
  }

  focus_out_address() {
    $("#basket_address").val(this.delivery_address);
  }

  checK_schedule_order_available() {
    if (
      (this.user_helper.user_cart.cart_data.destination_addresses[0]
        .location[0] &&
        this.user_helper.user_cart.cart_data.destination_addresses[0]
          .location[1] &&
        this.user_helper.user_cart.cart_data.destination_addresses[0]
          .address) ||
      this.user_helper.user_cart.ipickup_delivery
    ) {
      if (this.user_helper.user_cart.is_schedule_order) {
        if (this.store_detail.is_taking_schedule_order) {
          let server_date: any = new Date(
            this.user_helper.user_cart.server_date
          );
          server_date = new Date(server_date).toLocaleString("en-US", {
            timeZone: this.user_helper.user_cart.timezone,
          });
          server_date = new Date(server_date);

          let date = this.schedule_date.split("-");
          let time = this.schedule_time.split(":");

          let selected_date: any = this.user_helper.user_cart.schedule_date;
          // selected_date = new Date(selected_date).toLocaleString("en-US", {timeZone: this.user_helper.user_cart.timezone})
          // selected_date = new Date(selected_date);
          // selected_date.setDate(date[2])
          // selected_date.setMonth(date[1]-1)
          // selected_date.setFullYear(date[0])
          // selected_date.setHours(time[0], time[1], 0, 0);

          let timeDiff = Math.round(
            selected_date.getTime() - server_date.getTime()
          );

          if (
            timeDiff / 60000 >=
            this.store_detail.schedule_order_create_after_minute
          ) {
            this.schedule_time_error = false;
            this.create_order();
          } else {
            this.schedule_time_error = true;
          }
        } else {
        }
      } else {
        this.check_delay_order();
        this.create_order();
      }
    }
  }

  create_order() {
    let totalItemsCount = 0;
    this.cart_data.cart.forEach((cart_product) => {
      cart_product.items.forEach((cart_item) => {
        totalItemsCount += cart_item.quantity;
      });
    });

    let get_order_cart_invoice_json = {
      user_id: this.user_id,
      server_token: this.server_token,
      cart_unique_token: "",
      total_distance: this.google_distance,
      total_time: this.google_time,
      store_id: this.cart_data.selectedStoreId,
      order_type: 7,
      total_cart_price: this.user_helper.user_cart.total_cart_amount,
      total_item_count: totalItemsCount,
      is_user_pick_up_order: this.user_helper.user_cart.ipickup_delivery,
      is_offer_apply: true,
      is_add_price_in_menu: true,
    };

    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.GET_ORDER_CART_INVOICE,
      get_order_cart_invoice_json,
      (res_data) => {
        if (res_data.success) {
          this.delivery_time = res_data.delivery_time;
          this.is_emergency = res_data.is_emergency;
          this.emergency_close_till = res_data.emergency_close_till;
          this.check_promo((promo_response) => {
            if (this.user_helper.user_cart.is_schedule_order) {
              this.check_valid_time();
            } else {
              let date: any = res_data.server_time;
              date = new Date(date).toLocaleString("en-US", {
                timeZone: res_data.timezone,
              });
              date = new Date(date);
              this.check_open(date, true);
            }

            if (
              (!this.store_detail.is_pickup_possible &&
                this.user_helper.user_cart.ipickup_delivery) ||
              (!this.store_detail.is_delivery_possible &&
                !this.user_helper.user_cart.ipickup_delivery)
            ) {
            } else {
              this.user_helper.user_cart.delivery_note = this.delivery_note;
              this.user_helper.user_cart.delivery_user_name =
                this.delivery_user_name;
              this.user_helper.user_cart.delivery_user_phone =
                this.delivery_user_phone;
              this.user_helper.user_cart.is_user_pick_up_order =
                this.user_helper.user_cart.ipickup_delivery;
              if (
                this.selected_payment_gateway ==
                this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE
              ) {
                let index = this.card_list.findIndex(
                  (x) => x.is_default == true
                );
                if (index !== -1) {
                  this.pay_order_payment(false, null);
                } else {
                  this.error_message = this.title.please_add_card_first;
                }
                // this.pay_order_payment(true);
              } else if (
                this.selected_payment_gateway ==
                this.user_helper.PAYMENT_GATEWAY_CONSTANT.CASH
              ) {
                this.pay_order_payment(true, null);
              } else if (
                this.selected_payment_gateway ==
                  this.user_helper.PAYMENT_GATEWAY_CONSTANT.GOOGLE_PAY ||
                this.selected_payment_gateway ==
                  this.user_helper.PAYMENT_GATEWAY_CONSTANT.APPLE_PAY
              ) {
                this.modalReference = this.modalService.open(
                  this.apple_google_pay,
                  { size: "sm" }
                );

                this.prButton.mount(
                  document.getElementById("payment-request-button")
                );
              }
            }
          });
        } else {
          if (res_data.error_code === 557) {
            this.mimimum_amount = res_data.min_order_price;
            $("#order_amount_invalid").click();
          } else {
            this.user_helper.data.storage = {
              message: this.user_helper.ERROR_CODE[res_data.error_code],
              class: "alert-danger",
            };
            this.user_helper.message();
            this.disable_place_holder_button = true;
          }
        }
      }
    );
  }

  change_payment_mode(event, selected_payment_gateway) {
    if (event.target.checked) {
      this.selected_payment_gateway = selected_payment_gateway;
    }
  }

  pay_order_payment(payment_mode_cash, ev) {
    this.error_message = "";
    var payment_method = null;
    if (ev) {
      payment_method = ev.paymentMethod.id;
    }
    // this.user_helper.myLoading = true;
    if (payment_mode_cash) {
      let json = {
        user_id: this.user_id,
        server_token: this.server_token,
        order_payment_id: this.user_helper.user_cart.order_payment_id,
        payment_id: this.selected_payment_gateway,
        is_payment_mode_cash: payment_mode_cash,
      };
      this.user_helper.http_post_method_requester(
        this.user_helper.POST_METHOD.PAY_ORDER_PAYMENT,
        json,
        (res_data) => {
          if (res_data.success) {
            this.create_order_service();
          } else {
            this.user_helper.myLoading = false;
            this.user_helper.data.storage = {
              message: this.user_helper.ERROR_CODE[res_data.error_code],
              class: "alert-danger",
            };
            this.error_message =
              this.user_helper.ERROR_CODE[res_data.error_code];
            this.user_helper.message();
          }
        }
      );
    } else {
      let json = {
        user_id: this.user_id,
        server_token: this.server_token,
        order_payment_id: this.user_helper.user_cart.order_payment_id,
        payment_id: this.selected_payment_gateway,
        is_payment_mode_cash: payment_mode_cash,
        payment_method: payment_method,
      };
      this.user_helper.http_post_method_requester(
        this.user_helper.POST_METHOD.GET_STRIPE_PAYMENT_INTENT,
        json,
        (res_data) => {
          if (res_data.success) {
            if (res_data.is_payment_paid) {
              this.user_helper.http_post_method_requester(
                this.user_helper.POST_METHOD.PAY_ORDER_PAYMENT,
                json,
                (res_data) => {
                  if (res_data.success) {
                    this.create_order_service();
                  } else {
                    this.user_helper.user_cart.myLoading = false;
                    this.user_helper.data.storage = {
                      message: this.user_helper.ERROR_CODE[res_data.error_code],
                      class: "alert-danger",
                    };
                    this.error_message =
                      this.user_helper.ERROR_CODE[res_data.error_code];
                    this.user_helper.message();
                  }
                }
              );
            } else {
              this.user_helper.myLoading = false;

              if (
                this.selected_payment_gateway ==
                this.user_helper.PAYMENT_GATEWAY_CONSTANT.STRIPE
              ) {
                stripe
                  .confirmCardPayment(res_data.client_secret, {
                    payment_method: res_data.payment_method,
                  })
                  .then(
                    (result) => {
                      if (result.paymentIntent) {
                        this.user_helper.http_post_method_requester(
                          this.user_helper.POST_METHOD.PAY_ORDER_PAYMENT,
                          json,
                          (res_data) => {
                            if (res_data.success) {
                              this.create_order_service();
                            } else {
                              this.user_helper.user_cart.myLoading = false;
                              this.user_helper.data.storage = {
                                message:
                                  this.user_helper.ERROR_CODE[
                                    res_data.error_code
                                  ],
                                class: "alert-danger",
                              };
                              this.error_message =
                                this.user_helper.ERROR_CODE[
                                  res_data.error_code
                                ];
                              this.user_helper.message();
                            }
                          }
                        );
                      } else {
                        this.user_helper.user_cart.myLoading = false;
                        this.user_helper.data.storage = {
                          message: result.error.message,
                          class: "alert-danger",
                        };
                        this.error_message = result.error.message;
                        this.user_helper.message();
                      }
                    },
                    (error) => {
                      this.user_helper.data.storage = {
                        message: error.message,
                        class: "alert-danger",
                      };
                      this.error_message = error.message;
                      this.user_helper.message();
                      this.user_helper.user_cart.myLoading = false;
                    }
                  );
              } else {
                stripe
                  .confirmCardPayment(
                    res_data.client_secret,
                    {
                      payment_method: res_data.payment_method,
                    },
                    { handleActions: false }
                  )
                  .then(
                    (result) => {
                      if (result.error) {
                        this.user_helper.user_cart.myLoading = false;
                        this.user_helper.data.storage = {
                          message: result.error.message,
                          class: "alert-danger",
                        };
                        this.error_message = result.error.message;
                        this.user_helper.message();
                        ev.complete("fail");
                      } else {
                        ev.complete("success");
                        this.modalReference.close();
                        this.create_order_service();
                      }
                    },
                    (error) => {
                      this.user_helper.data.storage = {
                        message: error.message,
                        class: "alert-danger",
                      };
                      this.error_message = error.message;
                      this.user_helper.message();
                      this.user_helper.user_cart.myLoading = false;
                    }
                  );
              }
            }
          } else {
            this.user_helper.user_cart.myLoading = false;
            this.user_helper.data.storage = {
              message: res_data.error,
              class: "alert-danger",
            };
            this.error_message = res_data.error;
            this.user_helper.message();
          }
        }
      );
    }
  }

  increase_qty(product_index, item_index) {
    this.user_helper.increase_qty(product_index, item_index);
    // this.get_order_invoice(this.google_distance, this.google_time);
  }

  decrease_qty(product_index, item_index) {
    this.user_helper.decrease_qty(product_index, item_index);
    // this.get_order_invoice(this.google_distance, this.google_time);
  }

  remove_from_cart(product_index, item_index) {
    this.user_helper.remove_from_cart(product_index, item_index);
    if (this.user_helper.user_cart.cart_data.total_item == 0) {
      // this.user_helper.router.navigate(['']);
    } else {
      // this.get_order_invoice(this.google_distance, this.google_time);
    }
  }

  create_order_service() {
    let selected_date = this.user_helper.user_cart.schedule_date;
    let milisecond = 0;
    var date = new Date();

    if (
      this.delay_order_error &&
      !this.user_helper.user_cart.is_schedule_order
    ) {
      this.add_preparation_time_min = this.delay_time;
    } else {
      this.add_preparation_time_min = 0;
    }

    if (this.user_helper.user_cart.is_schedule_order) {
      milisecond = selected_date.getTime();
    }
    this.user_helper.user_cart.cart_data.destination_addresses[0].floor =
      this.floor;
    this.user_helper.user_cart.cart_data.destination_addresses[0].entry_code =
      this.entry_code;
    this.user_helper.user_cart.cart_data.destination_addresses[0].note =
      this.delivery_note;
    let user = JSON.parse(localStorage.getItem("user"));
    this.user_helper.user_cart.cart_data.destination_addresses[0].user_details =
      {
        name: user.first_name + " " + user.last_name,
        country_phone_code: user.country_phone_code,
        phone: user.phone,
        email: user.email,
      };
    let json = {
      user_id: this.user_id,
      server_token: this.server_token,
      cart_id: this.user_helper.user_cart.cart_data.cart_id,
      delivery_note: this.user_helper.user_cart.delivery_note,
      order_payment_id: this.user_helper.user_cart.order_payment_id,
      destination_addresses:
        this.user_helper.user_cart.cart_data.destination_addresses,
      delivery_user_name: this.user_helper.user_cart.delivery_user_name,
      delivery_user_phone: this.user_helper.user_cart.delivery_user_phone,
      is_user_pick_up_order: this.user_helper.user_cart.is_user_pick_up_order,
      is_schedule_order: this.user_helper.user_cart.is_schedule_order,
      order_start_at: milisecond,
      add_preparation_time_min: this.add_preparation_time_min,
    };
    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.CREATE_ORDER,
      json,
      (res_data) => {
        if (res_data.success) {
          this.user_helper.router_id.user.order_id = res_data.order_id;
          this.user_helper.router_id.user.is_busy = res_data.is_busy;
          this.user_helper.router_id.user.is_store_busy =
            res_data.is_store_busy;
          this.user_helper.user_cart.delivery_note = "";
          this.user_helper.user_cart.is_schedule_order = false;
          this.user_helper.user_cart.schedule_date = null;
          this.user_helper.user_cart.cart_data = {
            cart_id: null,
            city_id: null,
            pickup_addresses: [],
            destination_addresses: [],
            cart: [],
            selectedStoreId: null,
            total_item: 0,
          };
          this.user_helper.user_cart.total_cart_amount = 0;
          this.user_helper.user_cart.order_payment_id = null;

          this.user_helper.data.storage = {
            message: this.user_helper.MESSAGE_CODE[res_data.message],
            class: "alert-info",
          };
          let uuid = UUID.UUID();
          localStorage.setItem("cart_unique_token", uuid);
          this.user_helper.router.navigate(["thankyou"]);
        } else {
          this.user_helper.data.storage = {
            message: this.user_helper.ERROR_CODE[res_data.error_code],
            class: "alert-danger",
          };
          this.user_helper.message();
        }
      }
    );
  }

  open(content) {
    this.user_login = {
      cart_unique_token: localStorage.getItem("cart_unique_token"),
      email: "",
      password: "",
      login_by: this.title.manual,
      social_id: "",
    };
    this.user_forgot_password = {
      email: "",
    };
    this.error_message = "";
    this.user_register = {
      cart_unique_token: localStorage.getItem("cart_unique_token"),
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      social_id: "",
      login_by: this.title.manual,
      confirm_password: "",
      country_id: "",
      city: "",
      address: "",
      country_phone_code: "",
      phone: null,
      image_url: "./default.png",
      referral_code: "",
      is_phone_number_verified: false,
      is_email_verified: false,
      currency_code: "",
    };
    if (this.country_list.length > 0) {
      this.user_register.currency_code = this.country_list[0].currency_code;
      this.user_register.country_phone_code =
        this.country_list[0].country_phone_code;
      this.user_register.country_id = this.country_list[0].country_name;
    }
    this.modalService
      .open(content, {
        ariaLabelledBy: "modal-basic-title",
        windowClass: "product_pop with_img",
        backdrop: "static",
        keyboard: false,
      })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  changeradio(event, specification_group_index, specification_index) {
    if (
      !this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_close
    ) {
      var index = this.current_item.specifications[
        specification_group_index
      ].list.findIndex((x) => x.is_default_selected == true);
      if (index !== -1) {
        this.current_item.specifications[specification_group_index].list[
          index
        ].is_default_selected = false;
      }
      this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_default_selected = true;
      this.calculateTotalAmount();
    }
  }

  changecheckbox(event, specification_group_index, specification_index) {
    if (
      !this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_close
    ) {
      this.current_item.specifications[specification_group_index].list[
        specification_index
      ].is_default_selected = event.target.checked;
      this.calculateTotalAmount();
    }
  }

  calculateTotalAmount() {
    this.total = this.current_item.price;
    if (this.current_formule.formule_id && this.current_formule.price) {
      this.total = Number(this.current_formule.price);
    }
    this.required_temp_count = 0;
    this.current_item.specifications.forEach(
      (specification_group, specification_group_index) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let isAllowed = false;
          var default_selected_count = 0;
          specification_group.selected_specification = 0;
          specification_group.list.forEach(
            (specification, specification_index) => {
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                this.total = this.total + specification.price;
                default_selected_count++;
                specification_group.selected_specification++;
              }
              specification_group.default_selected_count =
                default_selected_count;
            }
          );

          if (
            specification_group.type == 1 &&
            specification_group.is_required
          ) {
            // if(specification_group.min_selection) {
            //     if (default_selected_count >= specification_group.range) {
            //         this.required_temp_count++;
            //     }
            // } else {
            if (default_selected_count >= 1) {
              this.required_temp_count++;
            }
            // }
          } else if (
            specification_group.type == 2 &&
            specification_group.is_required
          ) {
            // if (specification_group.range) {
            if (default_selected_count >= specification_group.min_selection) {
              this.required_temp_count++;
            }
            // } else {
            //     if (default_selected_count >= 1) {
            //         this.required_temp_count++;
            //     }
            // }
          }
        }
      }
    );

    this.total = this.total * this.qty;
    this.check_item_offer();
  }

  check_item_offer() {
    if (this.current_item.offer_tags) {
      var index = this.current_item.offer_tags.findIndex(
        (offer) => offer.offer_type == 2
      );
      if (index !== -1) {
        var offer_detail = this.current_item.offer_tags[index];
        var discount_type = offer_detail.discount_type;
        var discount_value = offer_detail.discount_value;
        var offer_price = 0;
        if (discount_type == 0) {
          offer_price = (this.total * discount_value) / 100;
        } else {
          offer_price = discount_value;
          if (offer_price > this.total) {
            offer_price = this.total;
          }
        }
        // this.total = this.total - offer_price;
        this.current_item.offer_price = offer_price;
      }
    }
  }

  select_formule(formule: any) {
    this.current_formule = JSON.parse(JSON.stringify(formule));
    this.calculate_is_required();
    this.calculateTotalAmount();
  }

  open_edit_item_modal(
    content2,
    item,
    product,
    selected_item_index,
    selected_product_index
  ) {
    this.current_formule = { formule_id: "" };
    if (item.formule_list && item.formule_list.length > 0) {
      this.current_formule = JSON.parse(JSON.stringify(item.formule_list[0]));
    }

    this.selected_item_index = selected_item_index;
    this.selected_product_index = selected_product_index;
    let item_index = this.user_helper.user_cart.cart_main_item.findIndex(
      (x) => x._id == item.item_id
    );
    let current_specification =
      this.user_helper.user_cart.cart_main_item[item_index].specifications;
    let order_specification = item.specifications;
    let new_specification = [];

    current_specification.forEach((x) => {
      var index = order_specification.findIndex(
        (order_sp) => order_sp.unique_id == x.unique_id
      );
      if (x.formule_id) {
        index = order_specification.findIndex(
          (order_sp: any) =>
            order_sp.unique_id == x.unique_id &&
            order_sp.formule_id == x.formule_id
        );
      }

      if (index == -1) {
        x.list.forEach((y: any) => {
          y.price = Number(y.price);
        });
        new_specification.push(x);
      } else {
        var new_specification_list = [];
        x.list.forEach((y) => {
          y.price = Number(y.price);
          var list_index = order_specification[index].list.findIndex(
            (order_sp_list) => order_sp_list.unique_id == y.unique_id
          );
          if (list_index == -1) {
            y.is_default_selected = false;
            new_specification_list.push(y);
          } else {
            order_specification[index].list[list_index].price = y.price;
            new_specification_list.push(
              order_specification[index].list[list_index]
            );
          }
        });
        let json = {
          list: new_specification_list,
          unique_id: x.unique_id,
          name: x.name,
          formule_id: x.formule_id,
          is_required: x.is_required,
          min_selection: x.min_selection,
          max_selection: x.max_selection,
          price: x.price,
          type: x.type,
        };
        new_specification.push(json);
      }
    });

    this.qty = item.quantity;
    this.required_count = 0;
    this.current_item = JSON.parse(JSON.stringify(item));
    this.current_item.price =
      this.user_helper.user_cart.cart_main_item[item_index].price;
    this.current_item.name =
      this.user_helper.user_cart.cart_main_item[item_index].name;
    // new_specification.sort(this.sortFormule);
    this.current_item.specifications = new_specification;
    this.current_item.formule_list =
      this.user_helper.user_cart.cart_main_item[item_index].formule_list;
    this.calculate_is_required();
    this.edit_item_calculateTotalAmount();
    this.note_for_item = item.note_for_item;
    if (
      this.user_helper.check_buy_one_get_one(this.current_item) &&
      this.current_item.is_buy_one_get_one
    ) {
      this.current_item.is_buy_one_get_one = true;
    } else {
      this.current_item.is_buy_one_get_one = false;
    }
    setTimeout(() => {
      this.open(content2);
    }, 100);
  }

  sortFormule(a: any, b: any) {
    if (a.formule_id) return -1;
    return 0;
  }

  change_buy_one_get_one(is_buy_one_get_one) {
    this.current_item.is_buy_one_get_one = is_buy_one_get_one;
  }

  calculate_is_required() {
    this.required_count = 0;
    this.current_item.specifications.forEach((specification_group: any) => {
      if (
        !specification_group.formule_id ||
        specification_group.formule_id == this.current_formule.formule_id
      ) {
        if (specification_group.is_required && !specification_group.is_close) {
          this.required_count++;
        }
        let list = specification_group.list.filter((x: any) => {
          if (!x.is_close) {
            return x;
          }
        });
        if (list.length < specification_group.min_selection) {
          specification_group.min_selection = list.length;
        }
        if (list.length < specification_group.max_selection) {
          specification_group.max_selection = list.length;
        }
      }
    });
  }

  edit_item_calculateTotalAmount() {
    this.total = this.current_item.price;
    if (this.current_formule.formule_id && this.current_formule.price) {
      this.total = Number(this.current_formule.price);
    }
    this.required_temp_count = 0;
    this.current_item.specifications.forEach(
      (specification_group, specification_group_index) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let isAllowed = false;

          if (
            specification_group.min_selection == undefined ||
            specification_group.max_selection == undefined
          ) {
            if (specification_group.type == 1) {
              specification_group.min_selection = 1;
              specification_group.max_selection = 1;
            } else {
              specification_group.max_selection =
                specification_group.list.length;
              if (specification_group.is_required) {
                specification_group.min_selection = 1;
              } else {
                specification_group.min_selection = 0;
              }
            }
          }
          if (specification_group.max_selection == 0) {
            specification_group.max_selection = 1;
          }

          var default_selected_count = 0;
          specification_group.selected_specification = 0;
          specification_group.list.forEach(
            (specification, specification_index) => {
              specification.price = Number(specification.price);
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                this.total = this.total + specification.price;
                default_selected_count++;
                specification_group.selected_specification++;
              }
              specification_group.default_selected_count =
                default_selected_count;
            }
          );

          if (
            specification_group.type == 1 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= 1) {
              this.required_temp_count++;
            }
          } else if (
            specification_group.type == 2 &&
            specification_group.is_required
          ) {
            if (default_selected_count >= specification_group.min_selection) {
              this.required_temp_count++;
            }
          }
        }
      }
    );

    this.total = this.total * this.qty;
  }

  updateCart() {
    let specificationPriceTotal = 0;
    let specificationPrice = 0;
    let specificationList = [];
    this.current_item.specifications.forEach(
      (specification_group, specification_group_index) => {
        if (
          !specification_group.formule_id ||
          specification_group.formule_id == this.current_formule.formule_id
        ) {
          let specificationItemCartList = [];
          specification_group.list.forEach(
            (specification, specification_index) => {
              if (
                specification.is_default_selected &&
                !specification.is_close &&
                !specification_group.is_close
              ) {
                specificationPrice = specificationPrice + specification.price;
                specificationPriceTotal =
                  specificationPriceTotal + specification.price;
                specificationItemCartList.push(specification);
              }
            }
          );

          if (specificationItemCartList.length > 0) {
            let specificationsItem_json = {
              list: specificationItemCartList,
              unique_id: specification_group.unique_id,
              name: specification_group.name,
              price: specificationPrice,
              type: specification_group.type,
              formule_id: specification_group.formule_id,
              is_required: specification_group.is_required,
              min_selection: specification_group.min_selection,
              max_selection: specification_group.max_selection,
            };
            specificationList.push(specificationsItem_json);
          }
          specificationPrice = 0;
        }
      }
    );

    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].item_price = this.current_item.price;
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].total_specification_price =
      specificationPriceTotal;
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].total_item_and_specification_price =
      this.total;
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].note_for_item = this.note_for_item;
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].specifications = specificationList;
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items[this.selected_item_index].is_buy_one_get_one =
      this.current_item.is_buy_one_get_one;

    if (this.current_formule.formule_id) {
      this.user_helper.user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].formule_list = [this.current_formule];
      this.user_helper.user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].item_price = Number(
        this.current_formule.price
      );
      this.user_helper.user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].item_name = this.current_formule.name;
    } else {
      this.user_helper.user_cart.cart_data.cart[
        this.selected_product_index
      ].items[this.selected_item_index].formule_list = [];
    }

    var total_item_price = 0;
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].items.forEach((item) => {
      total_item_price =
        total_item_price + item.total_item_and_specification_price;
    });
    this.user_helper.user_cart.cart_data.cart[
      this.selected_product_index
    ].total_item_price = total_item_price;

    $("#close_item_model1").click();
    this.user_helper.calculateTotalAmount();
    // this.get_order_invoice(this.google_distance, this.google_time);
  }

  userLogin(logindata) {
    this.user_login.social_id = "";
    this.user_login.login_by = this.title.manual;

    this.user_login.email = this.user_login.email.trim();
    logindata.email = logindata.email.trim();
    if (this.email_placeholder == 1) {
      var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (!isNaN(logindata.email) || reg.test(logindata.email)) {
        this.email_or_phone_error = false;
        this.Login();
      } else {
        this.email_or_phone_error = true;
      }
    } else {
      this.email_or_phone_error = false;
      this.Login();
    }
  }

  Login() {
    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.LOGIN,
      this.user_login,
      (res_data) => {
        if (res_data.success) {
          localStorage.setItem("user", JSON.stringify(res_data.user));
          this.user_helper.check_detail();
          $("#login_modal").click();
          this.ngOnInit();
          // this.update_address();
          this.get_fav_address();
          this.error_message = "";
          this.user_helper.user_cart.delivery_note = "";
          this.user_helper.user_cart.is_schedule_order = false;
          this.user_helper.user_cart.schedule_date = null;
        } else {
          this.user_helper.data.storage = {
            message: this.user_helper.ERROR_CODE[res_data.error_code],
            class: "alert-danger",
          };
          this.error_message = this.user_helper.ERROR_CODE[res_data.error_code];
          this.user_helper.message();
        }
      }
    );
  }

  open2(content2) {
    // this.NgbdModalBasic.open_login_modal();
    this.modalService
      .open(content2, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  userForgotPassword(forgotpassworddata) {
    this.user_helper.myLoading = true;
    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.FORGOT_PASSWORD,
      { email: forgotpassworddata.email.trim(), type: 7 },
      (res_data) => {
        this.user_helper.myLoading = false;
        if (res_data.success == false) {
          this.user_helper.data.storage = {
            code: res_data.error_code,
            message: this.user_helper.ERROR_CODE[res_data.error_code],
            class: "alert-danger",
          };
          this.user_helper.message();
          this.error_message = this.user_helper.ERROR_CODE[res_data.error_code];
        } else {
          this.user_helper.data.storage = {
            message: this.user_helper.MESSAGE_CODE[res_data.message],
            class: "alert-info",
          };
          $("#success_modal").click();
          $("#forgot_modal").click();
          this.error_message = "";
        }
      }
    );
  }

  public formData = new FormData();
  userRegister(userdata) {
    this.user_helper.myLoading = true;
    this.formData.append("phone", userdata.phone.trim());
    this.formData.append("password", this.user_register.password.trim());
    this.formData.append(
      "cart_unique_token",
      this.user_register.cart_unique_token
    );
    this.formData.append("currency_code", this.user_register.currency_code);
    this.formData.append("country_name", this.user_register.country_id);
    this.formData.append("city", "");
    this.formData.append("social_id", this.user_register.social_id);
    this.formData.append("login_by", this.user_register.login_by);
    this.formData.append(
      "country_phone_code",
      this.user_register.country_phone_code
    );
    this.formData.append("first_name", userdata.first_name.trim());
    this.formData.append("last_name", userdata.last_name.trim());
    this.formData.append("email", userdata.email.trim());
    this.formData.append("address", "");
    this.formData.append("referral_code", "");
    // this.formData.append('is_phone_number_verified',true);
    // this.formData.append('is_email_verified',true);

    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.REGISTER,
      this.formData,
      (res_data) => {
        this.user_helper.myLoading = false;
        if (res_data.success == false) {
          this.user_helper.data.storage = {
            message: this.user_helper.ERROR_CODE[res_data.error_code],
            class: "alert-danger",
          };
          this.user_helper.message();
          this.error_message = this.user_helper.ERROR_CODE[res_data.error_code];
          this.formData = new FormData();
          if (this.user_register.login_by == this.title.social) {
            this.user_register.password = "123456";
            this.user_register.confirm_password = "123456";
          }
        } else {
          this.error_message = "";
          this.user_helper.data.storage = {
            message: this.user_helper.MESSAGE_CODE[res_data.message],
            class: "alert-info",
          };
          localStorage.setItem("user", JSON.stringify(res_data.user));
          this.user_helper.check_detail();
          $("#register_modal").click();
          this.ngOnInit();
          // this.update_address();
          this.error_message = "";
        }
      }
    );
  }

  get_address() {
    // if(this.favourite_addresses.length>0){
    //   this.use_an_address = true;
    // }
    this.use_an_address = !this.use_an_address;
  }

  get_fav_address() {
    this.user_helper.http_post_method_requester(
      "api/user/get_favoutire_addresses",
      { user_id: this.user_id, server_token: this.server_token },
      (res_data) => {
        if (res_data.success) {
          this.favourite_addresses = res_data.favourite_addresses;
        } else {
          this.favourite_addresses = [];
        }
      }
    );
  }

  set_address(address) {
    this.delivery_location = [address.latitude, address.longitude];
    this.delivery_address = address.address;
    $("#basket_address").val(this.delivery_address);
    if (this.user_helper.user_cart.cart_data.destination_addresses[0]) {
      this.update_address();
    }
    this.floor = address.floor;
    this.entry_code = address.entry_code;
    this.delivery_note = address.comment;
    this.selected_address_id = address._id;
    this.use_an_address = false;
  }

  confirm_payment() {
    $(".close_payment_modal").click();
  }

  card_number_validation(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }

    document
      .getElementById("card_number")
      .addEventListener("input", (e: any) => {
        e.target.value = e.target.value
          .replace(/[^\dA-Z]/g, "")
          .replace(/(.{4})/g, "$1 ")
          .trim();

        if (e.target.value.length == 2) {
          let card_type = this.user_helper.GetCardType(e.target.value);
          this.add_card_data.card_type = card_type;
        }
      });

    return true;
  }

  add_card() {
    $(".close_payment_modal").click();
    this.user_helper.user_cart.go_to_basket = true;
    this.user_helper.router.navigate(["payments"]);
    // this.user_helper.user_cart.myLoading = true;

    // (<any>window).Stripe.card.createToken({
    //     number: this.add_card_data.card_number,
    //     exp_month: this.add_card_data.expiry_month,
    //     exp_year: this.add_card_data.expiry_year,
    //     cvc: this.add_card_data.cvv
    // }, (status: number, response: any) => {
    //     if (status === 200) {
    //         this.user_helper.ngZone.run(() => {
    //             this.card_error = '';
    //         });
    //         this.add_card_data.last_four = response.card.last4;
    //         this.add_card_data.payment_token = response.id;
    //         this.add_card_service(this.add_card_data)
    //     } else {
    //         this.user_helper.ngZone.run(() => {
    //             this.user_helper.user_cart.myLoading = false;
    //             this.card_error = response.error.message;
    //         });
    //     }
    // });
  }

  add_card_service(card_data) {
    card_data.user_id = this.user_id;
    card_data.server_token = this.server_token;
    card_data.payment_id = this.selected_payment_gateway;
    card_data.type = 7;
    card_data.card_expiry_date =
      card_data.expiry_month + "/" + card_data.expiry_year;
    // delete card_data.card_number;

    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.ADD_CARD,
      card_data,
      (res_data) => {
        this.user_helper.user_cart.myLoading = false;
        if (res_data.success) {
          this.modal_type = 1;
          this.user_helper.user_cart.myLoading = false;
          this.card_list.push(res_data.card);
          this.user_helper.data.storage = {
            message: this.user_helper.MESSAGE_CODE[res_data.message],
            class: "alert-info",
          };
          this.add_card_data = {
            card_holder_name: "",
            card_number: "",
            last_four: null,
            expiry_year: null,
            expiry_month: null,
            cvv: null,
            card_type: "",
            payment_token: "",
          };
        } else {
          this.user_helper.data.storage = {
            message: this.user_helper.ERROR_CODE[res_data.error_code],
            class: "alert-danger",
          };
        }
        this.user_helper.message();
      }
    );
  }

  movetoNext(event, nextfield) {
    if (event.target.value.length >= event.target.maxLength) {
      $("#" + nextfield).focus();
    }
  }

  change_user_wallet_status(event) {
    this.user_helper.user_cart.myLoading = true;
    this.user_helper.http_post_method_requester(
      this.user_helper.POST_METHOD.CHANGE_USER_WALLET_STATUS,
      {
        user_id: this.user_id,
        server_token: this.server_token,
        is_use_wallet: event,
      },
      (res_data) => {
        this.user_helper.user_cart.myLoading = false;
        if (res_data.success) {
          this.is_use_wallet = event;
        } else {
          this.is_use_wallet = !event;
          this.user_helper.data.storage = {
            message: this.user_helper.ERROR_CODE[res_data.error_code],
            class: "alert-danger",
          };
        }
      }
    );
  }

  promo_text() {
    this.promo_error = 0;
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      if (user) {
        if (this.login_type == 1) {
          this.user_login = {
            cart_unique_token: localStorage.getItem("cart_unique_token"),
            email: "",
            password: "",
            login_by: user.provider.toLowerCase(),
            social_id: user.id,
          };
          this.Login();
        } else {
          this.user_register.social_id = user.id;
          this.user_register.first_name = user.firstName;
          this.user_register.last_name = user.lastName;
          this.user_register.email = user.email;
          this.user_register.login_by = user.provider.toLowerCase();

          window.scroll(0, 0);
        }
      }
    });
  }

  signInWithFB(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID).then((user) => {
      if (user) {
        if (this.login_type == 1) {
          this.user_login = {
            cart_unique_token: localStorage.getItem("cart_unique_token"),
            email: "",
            password: "",
            login_by: user.provider.toLowerCase(),
            social_id: user.id,
          };
          this.Login();
        } else {
          this.user_register.social_id = user.id;
          this.user_register.first_name = user.firstName;
          this.user_register.last_name = user.lastName;
          this.user_register.email = user.email;
          this.user_register.login_by = user.provider.toLowerCase();
          window.scroll(0, 0);
        }
      }
    });
  }

  signInWithApple() {
    var YOUR_CLIENT_ID = "com.hop.orderingapp";
    var YOUR_REDIRECT_ID = "";

    window.open(
      "https://appleid.apple.com/auth/authorize?" +
        `client_id=${YOUR_CLIENT_ID}&` +
        `redirect_uri=${encodeURIComponent(YOUR_REDIRECT_ID)}&` +
        "response_type=code id_token&" +
        "scope=name email&" +
        "response_mode=form_post",
      "_blank",
      "popup"
    );
  }
}
